import React from 'react';
import PropTypes from 'prop-types';
import { ValidImageTypes } from '../../common/model/attachment/FileType';
import Dialog from '../../common/dialog/Dialog';
import Attachment from './Attachment';
import events from '../ImageSlider/events';
import AttachmentEventTypes from './eventTypes';
import { SyncMode } from '../../common/model/attachment/SyncMode';

const flexRow = { display: 'flex', flexDirection: 'row' };
const flexColumn = { display: 'flex', flexDirection: 'column' };
const arrowWrapperStyle = { ...flexColumn, justifyContent: 'center' };

class PreviewAttachment extends React.Component {
  constructor(props) {
    super(props);
    this.events = events();
    this.previewImages = [];
    this.CloseModal = this.CloseModal.bind(this);
    this.OnPrevClick = this.OnPrevClick.bind(this);
    this.OnNextClick = this.OnNextClick.bind(this);
    this.state = {
      isModalOpen: false,
      selectedId: null,
      attachedItemReference: this.props.attachedItemReference,
    };
  }

  componentDidMount() {
    this.events
      .on(AttachmentEventTypes.AddPreview, (component) => {
        this.previewImages.push(component);
      })
      .on(AttachmentEventTypes.RemovePreview, (id) => {
        const idx = this.previewImages.findIndex((preview) => {
          const { props } = preview;
          if (props.id === id) return true;
          return false;
        });
        if (idx >= 0) {
          this.previewImages.splice(idx, 1);
        }
      })
      .on(AttachmentEventTypes.SelectThumbnail, (id) => {
        this.setState({
          isModalOpen: true,
          selectedId: id,
        });
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.attachedItemReference !== nextProps.attachedItemReference) {
      this.setState(() => ({ attachedItemReference: nextProps.attachedItemReference }));
    }
  }

  OnPrevClick() {
    const currentIdx = this.GetIndexOfCurrentPreview();
    let prev = null;
    if (currentIdx) {
      prev = this.previewImages[(currentIdx - 1) % (this.previewImages.length - 1)];
    } else {
      prev = this.previewImages[this.previewImages.length - 1];
    }
    if (prev) this.setState({ selectedId: prev.props.id });
  }

  OnNextClick() {
    const currentIdx = this.GetIndexOfCurrentPreview();
    let next = null;
    if (currentIdx === this.previewImages.length - 1) {
      next = this.previewImages[0];
    } else {
      next = this.previewImages[(currentIdx + 1) % this.previewImages.length];
    }
    if (next) this.setState({ selectedId: next.props.id });
  }

  GetIndexOfCurrentPreview() {
    return this.previewImages.findIndex((preview) => {
      const { props } = preview;
      if (props.id === this.state.selectedId) return true;
      return false;
    });
  }

  CloseModal() {
    this.setState({
      isModalOpen: false,
    });
  }

  render() {
    const closeButton = [{
      text: 'Close', onClick: this.CloseModal,
    }];

    let component = null;
    let fileName = null;

    this.previewImages.forEach((preview) => {
      const { props } = preview;
      if (props.id === this.state.selectedId) {
        component = preview;
        fileName = props.alt;
      }
    });

    const content = (
      <div className="attachment-preview" style={{ ...flexColumn, height: '100%' }}>
        {this.props.description
          && (
            <div>
              {this.props.description}
            </div>
          )
        }
        <div style={{
          ...flexRow, width: '100%', height: '100%', minHeight: '0',
        }}
        >
          <div style={{
            ...flexColumn, width: '100%', height: '100%', justifyContent: 'center',
          }}
          >
            {component}
          </div>
        </div>
        <div style={{ ...flexRow, justifyContent: 'center' }}>
          <div onClick={this.OnPrevClick} style={arrowWrapperStyle}>
            {this.props.previousIcon}
          </div>
          <div style={{
            display: 'flex', justifyContent: 'center', lineHeight: '40px', padding: '0 25px',
          }}
          >
            {this.GetIndexOfCurrentPreview() + 1}
            {' of '}
            {this.previewImages.length}
          </div>
          <div onClick={this.OnNextClick} style={arrowWrapperStyle}>
            {this.props.nextIcon}
          </div>
        </div>
      </div>
    );
    return (
      <div>
        <Attachment
          attachmentWrapperClass={this.props.attachmentWrapperClass}
          StateChange={this.props.StateChange}
          showFileName={this.props.showFileName}
          events={this.events}
          attachmentEndpointIsCloud={this.props.attachmentEndpointIsCloud}
          accept={this.props.accept}
          tooltipOnReadOnlyTrashCan={this.props.tooltipOnReadOnlyTrashCan}
          readOnly={this.props.readOnly}
          attachedItemReference={this.state.attachedItemReference}
          trashIcon={this.props.trashIcon}
          nextIcon={this.props.nextIcon}
          previousIcon={this.props.previousIcon}
          uploadIcon={this.props.uploadIcon}
          minLength={this.props.minLength}
          attachedItemType={this.props.attachedItemType}
          syncMode={this.props.syncMode}
          deferredCommit={this.props.deferredCommit}
          localAttachments={this.props.localAttachments}
        />
        <Dialog
          modalContentWrapperStyle={{
            display: 'flex',
            flexDirection: 'column',
          }}
          contentStyle={{
            flex: '2 1 auto',
            minHeight: '0',
          }}
          modalStyle={{
            overlay: {
              zIndex: 99999,
            },
            content: {
              width: '90%',
              height: '100%',
              padding: '30px 0px 30px 0px',
            },
          }}
          title={`Filename - ${fileName}`}
          buttons={closeButton}
          buttonWrapperStyle={{ padding: '0px 16px 16px 0px' }}
          content={content}
          isOpen={this.state.isModalOpen}
        />
      </div>
    );
  }
}

PreviewAttachment.propTypes = {
  attachedItemReference: PropTypes.string,
  accept: PropTypes.string,
  readOnly: PropTypes.bool,
  showFileName: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  attachmentWrapperClass: PropTypes.string,
  tooltipOnReadOnlyTrashCan: PropTypes.string,
  nextIcon: PropTypes.node,
  StateChange: PropTypes.func,
  previousIcon: PropTypes.node,
  trashIcon: PropTypes.node,
  uploadIcon: PropTypes.node,
  attachmentEndpointIsCloud: PropTypes.bool,
  minLength: PropTypes.number,
  attachedItemType: PropTypes.string,
  syncMode: PropTypes.string,
  deferredCommit: PropTypes.bool,
  localAttachments: PropTypes.shape({}),
};

PreviewAttachment.defaultProps = {
  attachedItemReference: null,
  accept: ValidImageTypes.join(','),
  showFileName: false,
  description: null,
  tooltipOnReadOnlyTrashCan: null,
  readOnly: false,
  StateChange: null,
  attachmentWrapperClass: 'defaultAttachment',
  nextIcon: undefined,
  previousIcon: undefined,
  trashIcon: undefined,
  uploadIcon: undefined,
  attachmentEndpointIsCloud: true,
  minLength: -1,
  attachedItemType: 'unknown',
  syncMode: SyncMode.OnlineOnly,
  deferredCommit: false,
  localAttachments: new Set(),
};

export default PreviewAttachment;
