import Requestor from './requestor';

class Requestors {
  constructor(requestors = []) {
    this.requestors = Array.isArray(requestors)
      ? requestors.map(x => new Requestor(x))
      : [];
  }

  FindByName(value) {
    return this.requestors.find(item => item.name === value);
  }

  FindById(value) {
    return this.requestors.find(item => item.id === value);
  }
}

export default Requestors;
