import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NavigationButton from '../../components/navigationButton';
import CommonFetch from '../../../utils/commonFetch';
import { FieldName } from '../../../../modules/ScoutModels/models/field';
import FormLabel from './controls/formLabel';
import WizardField, { WizardFieldType } from './controls/WizardField';
import SpecialAccessSection from './controls/specialAccessSection';
import DateTime from '../../components/dateTime';
import Request from '../../../../modules/ScoutModels/models/request';
import Toaster, { TOAST_ERROR } from '../../../../modules/common/toaster/toaster';
import events from '../../../../modules/attachment/ImageSlider/events';
import UserDetails from '../../../utils/userDetails';
import TooltipIcon from '../../components/tooltipIcon';
import TooltipText from '../../components/tooltipText';
import WaitingButton from '../../components/waitingButton';
import RequestWizard, { Step, NavigationLabel, NavigationIcon } from '../../../models/requestWizard';
import FormValidationMessage from './controls/formValidationMessage';

const bottomLabelStyle = { width: '180px' };

const divInputStyle = {
  paddingTop: '10px',
  paddingBottom: '8px',
  display: 'flex',
  alignItems: 'center',
};

class Step3 extends Component {
  static fieldOptionValid(field, optionValid, value) {
    return !field.shown || ((!field.required || optionValid) && (optionValid || !value || value.length === 0) && field.IsValid(value));
  }

  static initialiseOption(options, text, tokenText, handleChange) {
    const searchText = text || tokenText;
    const selectedOption = options.find(option => searchText && searchText.toUpperCase() === option.text.toUpperCase());
    if (selectedOption) {
      handleChange({ ...selectedOption, isValid: true }, !!text, true);
    } else {
      handleChange({ text, isValid: false }, !!text, true);
    }
  }

  constructor(props) {
    super(props);

    this.componentIsMounted = true;
    // Used for lazy loading. Use the properties not these fields.
    this.internalDomainField = null;
    this.internalSiteField = null;
    this.internalBuildingField = null;
    this.internalFloorField = null;
    this.internalRoomField = null;
    this.internalAssetsField = null;
    this.internalSpecialAccessField = null;
    this.internalSpecialAccessContactDetailsField = null;
    this.internalPreferredStartDateField = null;

    this.event = events();

    this.state = {
      domainDisplay: false,
      domainOptions: null,
      domainTouched: false,
      domainOptionValid: false,
      departmentOptions: null,
      departmentTouched: false,
      departmentInitialised: !this.departmentField.shown,
      siteOptions: null,
      siteTouched: false,
      siteOptionValid: false,
      buildingOptions: null,
      buildingTouched: false,
      buildingOptionValid: false,
      floorOptions: null,
      floorTouched: false,
      floorOptionValid: false,
      roomOptions: null,
      roomTouched: false,
      roomOptionValid: false,
      assetsOptions: null,
      assetsTouched: false,
      assetsOptionValid: false,
      specialAccessData: null,
      specialAccessContactDetailsTouched: false,
      specialAccessContactNameTouched: false,
      preferredStartDateTouched: false,
      preferredStartDateValidValue: false,
      typeListShown: '',
      showTooltip: '',
      waiting: false,
      isFinalStep: false,
      defaultOnlyFieldInvalid: false,
    };

    this.handleDomainChange = this.handleDomainChange.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    this.handleSiteChange = this.handleSiteChange.bind(this);
    this.handleBuildingChange = this.handleBuildingChange.bind(this);
    this.handleFloorChange = this.handleFloorChange.bind(this);
    this.handleRoomChange = this.handleRoomChange.bind(this);
    this.handleAssetsChange = this.handleAssetsChange.bind(this);
    this.onSpecialAccessChange = this.onSpecialAccessChange.bind(this);
    this.onSpecialAccessContactNameChange = this.onSpecialAccessContactNameChange.bind(this);
    this.onSpecialAccessContactDetailsChange = this.onSpecialAccessContactDetailsChange.bind(this);
    this.handleDatePickerChange = this.handleDatePickerChange.bind(this);
    this.onDoneButtonClick = this.onDoneButtonClick.bind(this);
    this.divListHandler = this.divListHandler.bind(this);
    this.onTooltipClick = this.onTooltipClick.bind(this);
  }

  async componentDidMount() {
    this.componentIsMounted = true;

    if (this.props.request.specialAccess !== undefined) {
      this.setState({
        specialAccessData: this.props.request.specialAccess ? 'yes' : 'no',
      });
    }

    if (!this.props.isEdit) {
      this.getDomainOptions();
      this.retrieveDepartmentOptions();
    } else {
      this.setState({
        domainDisplay: false,
      });
      // if we're editing then we assume whatever is in the request is valid and added by the user.
      this.handleDomainChange({ text: this.props.request.domain, isValid: true }, true, true);
      this.handleDepartmentChange(this.props.request.department, true);
    }
    const isFinalStep = await this.props.requestWizard.isFinalStep(Step.three);
    this.setState({
      isFinalStep,
    });
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
    this.props.saveRequest(this.props.request);
  }

  onSpecialAccessChange(specialAccess) {
    this.props.request.specialAccess = specialAccess;
    this.setState({
      specialAccessData: this.props.request.specialAccess ? 'yes' : 'no',
    });
  }

  onSpecialAccessContactNameChange(value) {
    this.props.request.specialAccessContactName = value;
    this.setState({
      specialAccessContactNameTouched: true,
    });
  }

  onSpecialAccessContactDetailsChange(value) {
    this.props.request.specialAccessContactDetails = value;
    this.setState({
      specialAccessContactDetailsTouched: true,
    });
  }

  onTooltipClick(event) {
    event.stopPropagation();
    const target = event.target;
    this.setState(prevState => ({ showTooltip: prevState.showTooltip === target.getAttribute('data-value') ? '' : target.getAttribute('data-value') }));
  }

  onDoneButtonClick() {
    this.setState({ waiting: true });
    this.props.saveRequest(this.props.request, true);
  }

  async getDomainOptions() {
    try {
      const domainsList = await CommonFetch.getUserDomainOptions();
      if (!this.componentIsMounted) { return; } // If the component has been unmounted throw away the results.
      const domainOptions = domainsList.filter(domainOption => domainOption.text !== null);
      this.setState({
        domainOptions,
        domainDisplay: domainOptions.length > 1,
      }, () => {
        Step3.initialiseOption(
          this.state.domainOptions,
          this.props.request.domain,
          domainOptions.length === 1 ? domainOptions[0].text : this.props.userDetails.DomainName,
          this.handleDomainChange,
        );
      });
    } catch (error) {
      console.error(error);
      Toaster.pop(`Error on loading ${this.domainField.caption} details.`, TOAST_ERROR);
    }
  }

  async getSiteOptions() {
    if (this.props.request.domain) {
      try {
        const siteOptions = await CommonFetch.getSiteOptions(this.props.request.domain);

        if (!this.componentIsMounted) { return; } // If the component has been unmounted throw away the results.
        this.setState({
          siteOptions,
        }, () => {
          Step3.initialiseOption(
            this.state.siteOptions,
            this.props.request.site,
            this.props.userDetails.SiteName,
            this.handleSiteChange,
          );
        });
      } catch (error) {
        console.error(error);
        Toaster.pop(`Error on loading ${this.siteField.caption} details.`, TOAST_ERROR);
      }
    }
  }

  async getBuildingOptions() {
    if (this.props.request.domain && this.props.request.site) {
      try {
        const buildingOptions = await CommonFetch.getBuildingOptions(this.props.request.domain, this.props.request.site);

        if (!this.componentIsMounted) { return; } // If the component has been unmounted throw away the results.
        this.setState({
          buildingOptions,
        }, () => {
          Step3.initialiseOption(
            this.state.buildingOptions,
            this.props.request.building,
            this.props.userDetails.BuildingName,
            this.handleBuildingChange,
          );
        });
      } catch (error) {
        console.error(error);
        Toaster.pop(`Error on loading ${this.buildingField.caption} details.`, TOAST_ERROR);
      }
    }
  }

  async getFloorOptions() {
    if (this.props.request.domain && this.props.request.site && this.props.request.building) {
      try {
        const floorOptions = await CommonFetch.getFloorOptions(
          this.props.request.domain,
          this.props.request.site,
          this.props.request.building,
        );

        if (!this.componentIsMounted) { return; } // If the component has been unmounted throw away the results.
        this.setState({
          floorOptions,
        }, () => {
          Step3.initialiseOption(
            this.state.floorOptions,
            this.props.request.floor,
            this.props.userDetails.FloorName,
            this.handleFloorChange,
          );
        });
      } catch (error) {
        console.error(error);
        Toaster.pop(`Error on loading ${this.floorField.caption} details.`, TOAST_ERROR);
      }
    }
  }

  async getRoomOptions() {
    if (this.props.request.domain && this.props.request.site && this.props.request.building && this.props.request.floor) {
      try {
        const roomOptions = await CommonFetch.getRoomOptions(
          this.props.request.domain,
          this.props.request.site,
          this.props.request.building,
          this.props.request.floor,
        );

        if (!this.componentIsMounted) { return; } // If the component has been unmounted throw away the results.
        this.setState({
          roomOptions,
        }, () => {
          Step3.initialiseOption(
            this.state.roomOptions,
            this.props.request.room,
            this.props.userDetails.RoomName,
            this.handleRoomChange,
          );
        });
      } catch (error) {
        console.error(error);
        Toaster.pop(`Error on loading ${this.roomField.caption} details.`, TOAST_ERROR);
      }
    }
  }

  async getAssetsOptions() {
    if (this.props.request.domain && this.props.request.site && this.props.request.building && this.props.request.floor && this.props.request.room) {
      try {
        const assetsOptions = await CommonFetch.getAssetsOptions(
          this.props.request.domain,
          this.props.request.site,
          this.props.request.building,
          this.props.request.floor,
          this.props.request.room,
        );

        if (!this.componentIsMounted) { return; } // If the component has been unmounted throw away the results.
        this.setState({
          assetsOptions,
        }, () => {
          Step3.initialiseOption(
            this.state.assetsOptions,
            this.props.request.assetName,
            '',
            this.handleAssetsChange,
          );
        });
      } catch (error) {
        console.error(error);
        Toaster.pop(`Error on loading ${this.assetsField.caption} details.`, TOAST_ERROR);
      }
    }
  }

  get nextButtonEnable() {
    /* eslint-disable-next-line max-len */ // Going to leave this here, probably be useful in the future.
    // console.log(`[domainValid:${this.domainValid}][departmentValid:${this.state.departmentValid}][siteValid:${this.siteValid}][buildingValid:${this.buildingValid}][floorValid:${this.floorValid}][roomValid:${this.roomValid}][assetsValid:${this.assetsValid}][preferredStartDateValid:${this.preferredStartDateValid}][specialAccessContactNameValid:${this.specialAccessContactNameValid}][specialAccessContactDetailsValid:${this.specialAccessContactDetailsValid}]`);
    return this.domainValid && this.state.departmentValid && this.siteValid && this.buildingValid && this.floorValid && this.roomValid
      && this.assetsValid && this.preferredStartDateValid && this.specialAccessContactNameValid && this.specialAccessContactDetailsValid;
  }

  get domainField() {
    if (!this.internalDomainField) {
      this.internalDomainField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.Domain);
    }
    return this.internalDomainField;
  }

  get domainValid() {
    return Step3.fieldOptionValid(this.domainField, this.state.domainOptionValid, this.props.request.domain);
  }

  get departmentField() {
    return this.props.requestWizard.getField(FieldName.Department);
  }

  get displayDepartment() {
    return (this.state.departmentOptions && this.state.departmentOptions.length > 1 && !this.departmentField.defaultOnly)
      || (this.departmentField.defaultOnly && !this.state.departmentValid);
  }

  get departmentIsDefaultOnlyAndInvalid() {
    return this.departmentField.shown
      && this.departmentField.defaultOnly
      && this.state.departmentInitialised
      && !this.state.departmentValid;
  }

  get siteField() {
    if (!this.internalSiteField) {
      this.internalSiteField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.Site);
    }
    return this.internalSiteField;
  }

  get siteValid() {
    return Step3.fieldOptionValid(this.siteField, this.state.siteOptionValid, this.props.request.site);
  }

  get buildingField() {
    if (!this.internalBuildingField) {
      this.internalBuildingField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.Building);
    }
    return this.internalBuildingField;
  }

  get buildingValid() {
    return Step3.fieldOptionValid(this.buildingField, this.state.buildingOptionValid, this.props.request.building);
  }

  get floorField() {
    if (!this.internalFloorField) {
      this.internalFloorField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.Floor);
    }
    return this.internalFloorField;
  }

  get floorValid() {
    return Step3.fieldOptionValid(this.floorField, this.state.floorOptionValid, this.props.request.floor);
  }

  get roomField() {
    if (!this.internalRoomField) {
      this.internalRoomField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.Room);
    }
    return this.internalRoomField;
  }

  get roomValid() {
    return Step3.fieldOptionValid(this.roomField, this.state.roomOptionValid, this.props.request.room);
  }

  get assetsField() {
    if (!this.internalAssetsField) {
      this.internalAssetsField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.AssetName);
    }
    return this.internalAssetsField;
  }

  get assetsValid() {
    return Step3.fieldOptionValid(this.assetsField, this.state.assetsOptionValid, this.props.request.assetName);
  }

  get specialAccessField() {
    if (!this.internalSpecialAccessField) {
      this.internalSpecialAccessField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.SpecialAccess);
    }
    return this.internalSpecialAccessField;
  }

  get specialAccessContactNameField() {
    if (!this.internalSpecialAccessContactNameField) {
      this.internalSpecialAccessContactNameField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.SpecialAccessContactName);
    }
    return this.internalSpecialAccessContactNameField;
  }

  get specialAccessContactNameValid() {
    return !this.specialAccessContactNameField.shown
      || !this.props.request.specialAccess
      || this.specialAccessContactNameField.IsValid(this.props.request.specialAccessContactName);
  }

  get specialAccessContactDetailsField() {
    if (!this.internalSpecialAccessContactDetailsField) {
      this.internalSpecialAccessContactDetailsField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.SpecialAccessContactDetails);
    }
    return this.internalSpecialAccessContactDetailsField;
  }

  get specialAccessContactDetailsValid() {
    return !this.specialAccessContactDetailsField.shown
      || !this.props.request.specialAccess
      || this.specialAccessContactDetailsField.IsValid(this.props.request.specialAccessContactDetails);
  }

  get preferredStartDateField() {
    if (!this.internalPreferredStartDateField) {
      this.internalPreferredStartDateField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.PreferredStartDate);
    }
    return this.internalPreferredStartDateField;
  }

  get preferredStartDateValid() {
    return Step3.fieldOptionValid(this.preferredStartDateField, this.state.preferredStartDateValidValue, this.props.request.preferredStartDate);
  }

  async retrieveDepartmentOptions() {
    const departmentOptions = await this.departmentField.LookupData();
    if (!this.componentIsMounted) { return; } // If the component has been unmounted throw away the results.
    this.setState({
      departmentOptions,
    });
    this.handleDepartmentChange(this.props.request.department, !!this.props.request.department);
  }

  handleDomainChange(option, domainTouched = true, initialising = false) {
    if (this.props.request.domain === option.text) { // If the user selects the same value don't do anything...
      if (initialising && option.isValid) { // ...unless we're initialising a valid option.
        this.getSiteOptions();
      }
      this.setState({ domainOptionValid: option.isValid });
      return;
    }
    this.props.request.domain = option.text;
    // When an option is selected reset the child fields so that
    // the user has to select again
    this.props.request.site = '';
    this.props.request.building = '';
    this.props.request.floor = '';
    this.props.request.room = '';
    this.props.request.assetName = '';
    if (option.isValid) {
      this.getSiteOptions();
    }
    this.setState({
      domainTouched,
      domainOptionValid: option.isValid,
      siteOptions: null,
      siteTouched: false,
      siteOptionValid: false,
      buildingOptions: null,
      buildingTouched: false,
      buildingOptionValid: false,
      floorOptions: null,
      floorTouched: false,
      floorOptionValid: false,
      roomOptions: null,
      roomTouched: false,
      roomOptionValid: false,
      assetsOptions: null,
      assetsTouched: false,
      assetsOptionValid: false,
    });
  }

  handleDepartmentChange(option, departmentTouched = true) {
    this.props.request.department = option;
    this.setState({ departmentTouched });
    this.validateField(this.departmentField, 'departmentValid', 'departmentInitialised');
  }

  handleSiteChange(option, siteTouched = true, initialising = false) {
    if (this.props.request.site === option.text) { // If the user selects the same value don't do anything...
      if (initialising) { // ...unless we're initialising.
        if (option.isValid) {
          this.getBuildingOptions();
        }
        this.setState({ siteOptionValid: option.isValid });
      }
      return;
    }
    this.props.request.site = option.text;
    // When an option is selected reset the child fields so that
    // the user has to select again
    this.props.request.building = '';
    this.props.request.floor = '';
    this.props.request.room = '';
    this.props.request.assetName = '';
    if (option.isValid) {
      this.getBuildingOptions();
    }
    this.setState({
      siteTouched,
      siteOptionValid: option.isValid,
      buildingOptions: null,
      buildingTouched: false,
      buildingOptionValid: false,
      floorOptions: null,
      floorTouched: false,
      floorOptionValid: false,
      roomOptions: null,
      roomTouched: false,
      roomOptionValid: false,
      assetsOptions: null,
      assetsTouched: false,
      assetsOptionValid: false,
    });
  }

  handleBuildingChange(option, buildingTouched = true, initialising = false) {
    if (this.props.request.building === option.text) { // If the user selects the same value don't do anything...
      if (initialising) { // ...unless we're initialising.
        if (option.isValid) {
          this.getFloorOptions();
        }
        this.setState({ buildingOptionValid: option.isValid });
      }
      return;
    }
    this.props.request.building = option.text;
    // When an option is selected reset the child fields so that
    // the user has to select again
    this.props.request.floor = '';
    this.props.request.room = '';
    this.props.request.assetName = '';
    if (option.isValid) {
      this.getFloorOptions();
    }
    this.setState({
      buildingTouched,
      buildingOptionValid: option.isValid,
      floorOptions: null,
      floorTouched: false,
      floorOptionValid: false,
      roomOptions: null,
      roomTouched: false,
      roomOptionValid: false,
      assetsOptions: null,
      assetsTouched: false,
      assetsOptionValid: false,
    });
  }

  handleFloorChange(option, floorTouched = true, initialising = false) {
    if (this.props.request.floor === option.text) { // If the user selects the same value don't do anything...
      if (initialising) { // ...unless we're initialising.
        if (option.isValid) {
          this.getRoomOptions();
        }
        this.setState({ floorOptionValid: option.isValid });
      }
      return;
    }
    this.props.request.floor = option.text;
    // When an option is selected reset the child fields so that
    // the user has to select again
    this.props.request.room = '';
    this.props.request.assetName = '';
    if (option.isValid) {
      this.getRoomOptions();
    }
    this.setState({
      floorTouched,
      floorOptionValid: option.isValid,
      assetsOptions: null,
      assetsTouched: false,
      assetsOptionValid: false,
    });
  }

  handleRoomChange(option, roomTouched = true, initialising = false) {
    if (this.props.request.room === option.text) { // If the user selects the same value don't do anything...
      if (initialising) { // ...unless we're initialising.
        if (option.isValid) {
          this.getAssetsOptions();
        }
        this.setState({ roomOptionValid: option.isValid });
      }
      return;
    }
    this.props.request.room = option.text;
    // When an option is selected reset the child fields so that
    // the user has to select again
    this.props.request.assetName = '';
    if (option.isValid) {
      this.getAssetsOptions();
    }
    this.setState({
      roomTouched,
      roomOptionValid: option.isValid,
      assetsOptions: null,
      assetsTouched: false,
      assetsOptionValid: false,
    });
  }

  handleAssetsChange(option, assetsTouched = true, initialising = false) {
    if (this.props.request.assetName === option.text) { // If the user selects the same value don't do anything...
      if (initialising) { // ...unless we're initialising.
        this.setState({ assetsOptionValid: option.isValid });
      }
      return;
    }
    this.props.request.assetName = option.text;
    this.setState({
      assetsTouched,
      assetsOptionValid: option.isValid,
    });
  }

  handleDatePickerChange(date, isValid, initialising = false) {
    this.props.request.preferredStartDate = date === '' || date === null ? undefined : date;
    this.setState({
      preferredStartDateTouched: !initialising,
      preferredStartDateValidValue: isValid,
    });
  }

  async validateField(field, validStateKey, initialisedStateKey) {
    const valid = await this.props.requestWizard.validateField(field, this.props.request);
    this.setState({
      [validStateKey]: valid,
      [initialisedStateKey]: true,
    }, () => this.validateForm());
  }

  validateForm() {
    const defaultOnlyFieldInvalid = this.defaultOnlyFieldInvalid();
    this.setState({ defaultOnlyFieldInvalid });
  }

  defaultOnlyFieldInvalid() {
    return this.departmentIsDefaultOnlyAndInvalid;
  }

  divListHandler(type) {
    this.setState({
      typeListShown: type,
    });
  }

  // Lots of code patterns appearing which should be refactored into controls or components, unfortunately no capacity to do it now.
  // eslint-disable-next-line complexity
  render() {
    const backDestination = this.props.editRequestID ? `/requests/edit/2/${this.props.editRequestID}` : '/requests/new/2';
    const nextDestination = this.props.editRequestID ? `/requests/edit/4/${this.props.editRequestID}` : '/requests/new/4';
    return (
      <div className="stepform">
        <FormValidationMessage show={this.state.defaultOnlyFieldInvalid} />
        <h5>
          <b>Where is the problem?</b>
        </h5>
        <div className="fields">
          <div>
            <WizardField
              wizardFieldType={WizardFieldType.Autocomplete}
              show={this.state.domainDisplay}
              type={FieldName.Domain}
              field={this.domainField}
              optionsData={this.state.domainOptions}
              parentVal={{ text: this.props.request.domain }}
              valueChanged={option => this.handleDomainChange(option)}
              event={this.event}
              divShown={this.divListHandler}
              showOpt={this.state.typeListShown === FieldName.Domain}
              isValid={this.domainValid || !this.state.domainTouched}
              showTooltip={this.state.showTooltip === this.domainField.id}
              showError={!this.domainValid && this.state.domainTouched}
              onTooltipClick={this.onTooltipClick}
            />
            <WizardField
              wizardFieldType={WizardFieldType.Autocomplete}
              show={this.displayDepartment}
              type={FieldName.Department}
              field={this.departmentField}
              optionsData={this.state.departmentOptions}
              parentVal={{ text: this.props.request.department }}
              valueChanged={option => this.handleDepartmentChange(option.text)}
              event={this.event}
              divShown={this.divListHandler}
              showOpt={this.state.typeListShown === FieldName.Department}
              isValid={this.state.departmentValid || !this.state.departmentTouched}
              showTooltip={this.state.showTooltip === this.departmentField.id}
              onTooltipClick={this.onTooltipClick}
              isDisabled={this.departmentField.defaultOnly}
            />
            <WizardField
              wizardFieldType={WizardFieldType.Autocomplete}
              type={FieldName.Site}
              field={this.siteField}
              optionsData={this.state.siteOptions}
              parentVal={{ text: this.props.request.site }}
              valueChanged={option => this.handleSiteChange(option)}
              event={this.event}
              divShown={this.divListHandler}
              showOpt={this.state.typeListShown === FieldName.Site}
              isValid={this.siteValid || !this.state.siteTouched}
              showTooltip={this.state.showTooltip === this.siteField.id}
              showError={!this.siteValid && this.state.siteTouched}
              onTooltipClick={this.onTooltipClick}
            />
            <WizardField
              wizardFieldType={WizardFieldType.Autocomplete}
              type={FieldName.Building}
              field={this.buildingField}
              optionsData={this.state.buildingOptions}
              parentVal={{ text: this.props.request.building }}
              valueChanged={option => this.handleBuildingChange(option)}
              event={this.event}
              divShown={this.divListHandler}
              showOpt={this.state.typeListShown === FieldName.Building}
              isValid={this.buildingValid || !this.state.buildingTouched}
              showTooltip={this.state.showTooltip === this.buildingField.id}
              showError={!this.buildingValid && this.state.buildingTouched}
              onTooltipClick={this.onTooltipClick}
            />
            <WizardField
              wizardFieldType={WizardFieldType.Autocomplete}
              type={FieldName.Floor}
              field={this.floorField}
              optionsData={this.state.floorOptions}
              parentVal={{ text: this.props.request.floor }}
              valueChanged={option => this.handleFloorChange(option)}
              event={this.event}
              divShown={this.divListHandler}
              showOpt={this.state.typeListShown === FieldName.Floor}
              isValid={this.floorValid || !this.state.floorTouched}
              showTooltip={this.state.showTooltip === this.floorField.id}
              showError={!this.floorValid && this.state.floorTouched}
              onTooltipClick={this.onTooltipClick}
            />
            <WizardField
              wizardFieldType={WizardFieldType.Autocomplete}
              type={FieldName.Room}
              field={this.roomField}
              optionsData={this.state.roomOptions}
              parentVal={{ text: this.props.request.room }}
              valueChanged={option => this.handleRoomChange(option)}
              event={this.event}
              divShown={this.divListHandler}
              showOpt={this.state.typeListShown === FieldName.Room}
              isValid={this.assetsValid || !this.state.roomTouched}
              showTooltip={this.state.showTooltip === this.roomField.id}
              showError={!this.assetsValid && this.state.roomTouched}
              onTooltipClick={this.onTooltipClick}
            />
            <WizardField
              wizardFieldType={WizardFieldType.Autocomplete}
              type={FieldName.AssetName}
              field={this.assetsField}
              optionsData={this.state.assetsOptions}
              parentVal={{ text: this.props.request.assetName }}
              valueChanged={option => this.handleAssetsChange(option)}
              event={this.event}
              divShown={this.divListHandler}
              showOpt={this.state.typeListShown === FieldName.AssetName}
              isValid={this.assetsValid || !this.state.assetsTouched}
              showTooltip={this.state.showTooltip === this.assetsField.id}
              showError={!this.assetsValid && this.state.assetsTouched}
              onTooltipClick={this.onTooltipClick}
            />
            <SpecialAccessSection
              specialAccessField={this.specialAccessField}
              specialAccessData={this.state.specialAccessData}
              onSpecialAccessChange={this.onSpecialAccessChange}
              specialAccessContactName={this.props.request.specialAccessContactName}
              specialAccessContactNameField={this.specialAccessContactNameField}
              onSpecialAccessContactNameChange={this.onSpecialAccessContactNameChange}
              specialAccessContactNameValid={this.specialAccessContactNameValid || !this.state.specialAccessContactNameTouched}
              specialAccessContactDetails={this.props.request.specialAccessContactDetails}
              specialAccessContactDetailsField={this.specialAccessContactDetailsField}
              onSpecialAccessContactDetailsChange={this.onSpecialAccessContactDetailsChange}
              specialAccessContactDetailsValid={this.specialAccessContactDetailsValid || !this.state.specialAccessContactDetailsTouched}
              divInputStyle={divInputStyle}
              divShown={this.divListHandler}
            />
            {this.preferredStartDateField.shown && (
              <div>
                <div style={divInputStyle}>
                  <FormLabel
                    style={{ ...bottomLabelStyle, marginRight: '5px' }}
                    caption={this.preferredStartDateField.captionOrDefault}
                    required={this.preferredStartDateField.required}
                  />
                  <DateTime
                    value={this.props.request.preferredStartDate}
                    valueChanged={this.handleDatePickerChange}
                    allowPastDates={this.props.isEdit}
                    isValid={this.preferredStartDateValid || !this.state.preferredStartDateTouched}
                    touched={this.state.preferredStartDateTouched}
                  />
                  <TooltipIcon
                    tooltip={this.preferredStartDateField.tooltip}
                    id={this.preferredStartDateField.id}
                    onTooltipClick={this.onTooltipClick}
                  />
                </div>
                <TooltipText
                  show={this.state.showTooltip === this.preferredStartDateField.id}
                  id={this.preferredStartDateField.id}
                  tooltip={this.preferredStartDateField.tooltip}
                  onTooltipClick={this.onTooltipClick}
                />
                <TooltipText
                  show={!this.preferredStartDateValid && this.state.preferredStartDateTouched}
                  tooltip={this.preferredStartDateField.patternValidationFailedMessage
                    || (this.preferredStartDateField.required ? `*${this.preferredStartDateField.captionOrDefault} is required.` : undefined)}
                  customClass="tooltipWarning"
                />
              </div>
            )}
          </div>
        </div>
        <div className="button-group">
          <div className="w3-left back">
            <NavigationButton
              icon={NavigationIcon.Back}
              label={NavigationLabel.Back}
              destination={backDestination}
            />
          </div>
          <div className="w3-right next">
            <WaitingButton
              waiting={this.state.waiting}
              icon={NavigationIcon.Next}
              label={this.state.isFinalStep ? NavigationLabel.Done : NavigationLabel.Next}
              destination={nextDestination}
              iconOnLeft={false}
              enabled={this.nextButtonEnable}
              onClick={this.state.isFinalStep ? this.onDoneButtonClick : null}
            />
          </div>
        </div>
      </div>
    );
  }
}

Step3.propTypes = {
  request: PropTypes.instanceOf(Request).isRequired,
  userDetails: PropTypes.instanceOf(UserDetails).isRequired,
  requestWizard: PropTypes.instanceOf(RequestWizard).isRequired,
  saveRequest: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  editRequestID: PropTypes.string,
};

Step3.defaultProps = {
  editRequestID: '',
};

export default Step3;
