import React from 'react';
import PropTypes from 'prop-types';
import SortOptions from '../../menu/filters/sortOptions';
import ListCounter from './listCounter';
import FilterList from '../../menu/filters/filterList';
import FilterDropdownTitle from '../../menu/filters/filterDropdownTitle';
import Requests from '../../../modules/ScoutModels/models/requests';

const ListViewHeader = props => (
  <div className="list-header">
    <ListCounter ActiveRequestsCount={props.requests.requests.length} ItemsFilteredLength={props.requests.Requests.length} />
    <div style={{ width: '50%', textAlign: 'right' }}>
      <FilterDropdownTitle
        onClick={props.onClickTitle}
        icon="Sort"
        color="black"
        value={props.requests.sortOption.caption}
      />
      <FilterList
        wrapperStyle={{ width: '100%' }}
        wrapperClassName="filter-sort-list"
        show={props.showOptions}
      >
        { SortOptions.map(sortOption => (
          <li key={sortOption.value}>
            <button type="button" onClick={() => { props.onClickOptions(sortOption); }} className="optionListBtn">
              {sortOption.caption}
            </button>
          </li>
        ))}
      </FilterList>
    </div>
  </div>
);

ListViewHeader.propTypes = {
  requests: PropTypes.instanceOf(Requests).isRequired,
  showOptions: PropTypes.bool.isRequired,
  onClickTitle: PropTypes.func.isRequired,
  onClickOptions: PropTypes.func,
};

ListViewHeader.defaultProps = {
  onClickOptions: () => {},
};

export default ListViewHeader;
