
import Uuid from 'uuid/v4';
import { forEach, without } from 'lodash';
import moment from 'moment';

import Conversation from './conversation';
import RequestStatus from './requestStatus';
import { FieldName } from './field';

const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

export default class Request {
  constructor(request = {}) {
    this.assetId = request.assetId;
    this.assetName = request.assetName;
    this.building = request.building;
    this.costCentre = request.costCentre;
    this.createdDate = request.createdDate || new Date().toISOString();
    this.department = request.department;
    this.title = request.title;
    this.domain = request.domain;
    this.expense = request.expense;
    this.floor = request.floor;
    this.id = request.id || Uuid();
    this.description = request.description;
    this.jobSubType = request.jobSubType;
    this.jobTask = request.jobTask;
    this.jobType = request.jobType;
    this.ohsIssue = request.ohsIssue;
    this.preferredStartDate = request.preferredStartDate;
    this.referenceNumber = request.referenceNumber;
    this.chargeable = request.chargeable;
    this.estimatedCompletionDate = request.estimatedCompletionDate;
    this.requestNumber = request.requestNumber;
    this.requestor = request.requestor;
    this.requestorEmail = request.requestorEmail;
    this.requestorPhone = request.requestorPhone;
    this.additionalNotificationEmailAddresses = request.additionalNotificationEmailAddresses;
    this.room = request.room;
    this.serviceType = request.serviceType;
    this.site = request.site;
    this.specialAccess = request.specialAccess;
    this.specialAccessContactDetails = request.specialAccessContactDetails;
    this.specialAccessContactName = request.specialAccessContactName;
    this.status = new RequestStatus(request.status);
    this.thumbnail = request.thumbnail;
    this.conversation = new Conversation(this.id);
    this.requestType = null;
    this.fieldKeys = null;
    this.validationErrors = [];
    this.versionDate = request.versionDate;
    this.versionBy = request.versionBy;
    this.attachments = request.attachments;
    this.consent = request.consent;
    this.consentMessage = request.consentMessage;
  }

  SetLastConversationTime(conversation) {
    this.conversation.SetLastConversationTime(conversation);
  }

  SetConversationLoadingStatus(status) {
    this.conversation.SetConversationLoadingStatus(status);
  }

  get DisplayVersionDate() {
    return this.versionDate
      ? moment(this.versionDate).format('LLLL')
      : null;
  }

  get DisplayPreferredStartDate() {
    return this.preferredStartDate
      ? moment(this.preferredStartDate).format('LL')
      : null;
  }

  get DisplayCreatedDate() {
    return this.createdDate
      ? moment(this.createdDate).format('LLLL')
      : null;
  }

  get DisplayEstimatedCompletionDate() {
    return this.estimatedCompletionDate
      ? moment(this.estimatedCompletionDate).format('LLLL')
      : null;
  }

  get ShouldAllowEditRequest() {
    return !!(this.status.ShouldAllowEditRequest && this.serviceType);
  }

  Clone() {
    const clone = new Request();
    clone.assetId = this.assetId;
    clone.assetName = this.assetName;
    clone.building = this.building;
    clone.costCentre = this.costCentre;
    clone.createdDate = this.createdDate;
    clone.department = this.department;
    clone.description = this.description;
    clone.title = this.title;
    clone.domain = this.domain;
    clone.expense = this.expense;
    clone.floor = this.floor;
    clone.id = this.id;
    clone.jobSubType = this.jobSubType;
    clone.jobTask = this.jobTask;
    clone.jobType = this.jobType;
    clone.ohsIssue = this.ohsIssue;
    clone.preferredStartDate = this.preferredStartDate;
    clone.referenceNumber = this.referenceNumber;
    clone.chargeable = this.chargeable;
    clone.estimatedCompletionDate = this.estimatedCompletionDate;
    clone.requestNumber = this.requestNumber;
    clone.requestor = this.requestor;
    clone.requestorEmail = this.requestorEmail;
    clone.requestorPhone = this.requestorPhone;
    clone.additionalNotificationEmailAddresses = this.additionalNotificationEmailAddresses;
    clone.room = this.room;
    clone.serviceType = this.serviceType;
    clone.site = this.site;
    clone.specialAccess = this.specialAccess;
    clone.specialAccessContactDetails = this.specialAccessContactDetails;
    clone.specialAccessContactName = this.specialAccessContactName;
    clone.status = this.status.Clone();
    clone.thumbnail = this.thumbnail;
    clone.conversation = this.conversation.Clone();
    clone.requestType = this.requestType;
    clone.attachments = new Set();
    clone.consent = this.consent;
    clone.consentMessage = this.consentMessage;

    if (this.attachments) {
      this.attachments.forEach((item) => {
        clone.attachments.add(item.Clone());
      });
    }

    return clone;
  }

  // Not really complex just long. This is the safest way to check that the
  // requests are equal.
  // eslint-disable-next-line complexity
  IsEqual(request) {
    return this.assetId === request.assetId
      && this.assetName === request.assetName
      && this.building === request.building
      && this.costCentre === request.costCentre
      && this.createdDate === request.createdDate
      && this.department === request.department
      && this.description === request.description
      && this.title === request.title
      && this.domain === request.domain
      && this.expense === request.expense
      && this.floor === request.floor
      && this.id === request.id
      && this.jobSubType === request.jobSubType
      && this.jobTask === request.jobTask
      && this.jobType === request.jobType
      && this.ohsIssue === request.ohsIssue
      && this.preferredStartDate === request.preferredStartDate
      && this.referenceNumber === request.referenceNumber
      && this.chargeable === request.chargeable
      && this.estimatedCompletionDate === request.estimatedCompletionDate
      && this.requestNumber === request.requestNumber
      && this.requestor === request.requestor
      && this.requestorEmail === request.requestorEmail
      && this.additionalNotificationEmailAddresses === request.additionalNotificationEmailAddresses
      && this.requestorPhone === request.requestorPhone
      && this.room === request.room
      && this.serviceType === request.serviceType
      && this.site === request.site
      && this.specialAccess === request.specialAccess
      && this.specialAccessContactDetails === request.specialAccessContactDetails
      && this.specialAccessContactName === request.specialAccessContactName
      && this.status.IsEqual(request.status)
      && this.thumbnail === request.thumbnail
      && this.conversation.IsEqual(request.conversation)
      && this.consent === request.consent
      && this.consentMessage === request.consentMessage;
  }

  get FieldKeys() {
    if (!this.fieldKeys) {
      this.fieldKeys = without(Object.keys(FieldName),
        FieldName.JobType,
        FieldName.JobSubType,
        FieldName.SpecialAccessContactName,
        FieldName.SpecialAccessContactDetails,
        FieldName.Attachments);
    }
    return this.fieldKeys;
  }

  GetValue(fieldKey) {
    return this[Request.getPropertyName(fieldKey)];
  }

  get IsValid() {
    this.validationErrors.length = 0;
    if (!this.requestType) {
      this.validationErrors.push('Missing request type.');
      return false;
    }

    let valid = true;
    forEach(this.FieldKeys, (fieldKey) => {
      const field = this.requestType.fields.GetField(fieldKey);
      if (field) {
        const value = this.GetValue(fieldKey);
        if (!field.IsValid(value)) {
          valid = false;
          this.validationErrors.push({ fieldKey, value });
          return false;
        }
      }
      return true;
    });

    if (valid && this.specialAccess) {
      const field = this.requestType.fields.GetFieldOrEmpty(FieldName.SpecialAccessContactName);
      valid = field.IsValid(this.specialAccessContactName);
    }
    if (valid && this.specialAccess) {
      const field = this.requestType.fields.GetFieldOrEmpty(FieldName.SpecialAccessContactDetails);
      valid = field.IsValid(this.specialAccessContactDetails);
    }
    return valid;
  }

  toJSON() {
    return {
      assetId: this.assetId,
      assetName: this.assetName,
      building: this.building,
      costCentre: this.costCentre,
      createdDate: this.createdDate,
      department: this.department,
      title: this.title,
      domain: this.domain,
      expense: this.expense,
      floor: this.floor,
      id: this.id,
      description: this.description,
      jobSubType: this.jobSubType,
      jobTask: this.jobTask,
      jobType: this.jobType,
      ohsIssue: this.ohsIssue,
      preferredStartDate: this.preferredStartDate,
      referenceNumber: this.referenceNumber,
      chargeable: this.chargeable,
      estimatedCompletionDate: this.estimatedCompletionDate,
      requestNumber: this.requestNumber,
      requestor: this.requestor,
      requestorEmail: this.requestorEmail,
      requestorPhone: this.requestorPhone,
      additionalNotificationEmailAddresses: this.additionalNotificationEmailAddresses,
      room: this.room,
      serviceType: this.serviceType,
      site: this.site,
      specialAccess: this.specialAccess,
      specialAccessContactDetails: this.specialAccessContactDetails,
      specialAccessContactName: this.specialAccessContactName,
      status: this.status,
      thumbnail: this.thumbnail,
      conversation: this.conversation.toJSON(),
      consent: this.consent,
      consentMessage: this.consentMessage,
    };
  }

  populateDefaultValues(userDetails) {
    this.FieldKeys.forEach((fieldKey) => {
      const field = this.requestType.fields.GetField(fieldKey);
      if (field && field.shown) {
        const propertyName = Request.getPropertyName(fieldKey);
        if (field.HasUserDefault) {
          this[propertyName] = userDetails[field.UserDefaultKey];
        }
        if (!this[propertyName] && field.default !== null) {
          this[propertyName] = field.default;
        }
      }
    });
  }

  /** @private */
  static getPropertyName(fieldKey) {
    if (fieldKey === FieldName.OHSIssue) {
      // There's always one exception 😒. We could do something fancy but there is only one so we'll keep it simple.
      return 'ohsIssue';
    }
    return fieldKey[0].toLowerCase() + fieldKey.slice(1);
  }
}
