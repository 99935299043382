import Field from './field';

class LookupField extends Field {
  constructor(field, lookupDataFunction) {
    super(field);
    this.lookupDataFunction = lookupDataFunction || (() => []);
    this.lookupData = null;
  }

  async LookupData() {
    await this.initialize();
    return this.lookupData;
  }

  async IsValid(value) {
    if (!this.shown) { return true; }
    if (!super.IsValid(value)) { return false; }

    const lookupData = await this.LookupData();

    return lookupData.some(record => record.text === value);
  }

  Clone() {
    const clone = new LookupField();
    super.Clone(clone);
    clone.lookupDataFunction = this.lookupDataFunction;
    clone.lookupData = this.lookupData;
    return clone;
  }

  /** @private */
  async initialize() {
    super.initialize();
    if (!this.lookupData) {
      this.lookupData = await this.lookupDataFunction();
    }
  }
}

export default LookupField;
