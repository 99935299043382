class Caption {
  constructor(label, value) {
    this.label = label || null;
    this.localValue = value;
  }

  get value() {
    switch (typeof this.localValue) {
      case 'boolean': {
        if (this.localValue) return 'Yes';
        return 'No';
      }
      default: {
        return this.localValue || '-';
      }
    }
  }
}

export default Caption;
