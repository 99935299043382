import React from 'react';
import PropTypes from 'prop-types';

const getProgressClassName = (progressNumber, currentStep) => {
  let className = '';
  if (progressNumber < currentStep) {
    className = 'progressBar-done';
  }
  if (progressNumber === currentStep) {
    className = 'progressBar-doing';
  }
  return className;
};

const getList = (numberOfSteps, currentProgress) => {
  const listItems = [];
  for (let index = 1; index <= numberOfSteps; index++) {
    listItems.push(<li key={`step-${index}`} className={getProgressClassName(index, parseInt(currentProgress))}><span className="circle" /></li>);
  }
  return listItems;
};

const ProgressBar = props => (
  <div className="menu progress-menu">
    <ol className="progressBar">
      {getList(props.numberOfSteps, props.currentProgress)}
    </ol>
  </div>
);

ProgressBar.propTypes = {
  currentProgress: PropTypes.string.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
};

export default ProgressBar;
