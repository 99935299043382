import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sprite from '../../components/sprite';

const searchButtonStyle = { width: '50px' };
const searchDivStyle = { display: 'flex', marginRight: '12px', width: '100%' };
const searchInputStyle = { width: '100%' };

export default class FilterSearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: this.props.searchTerm,
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.searchTerm !== this.state.searchTerm) {
      this.setState({
        searchTerm: nextProps.searchTerm,
      });
    }
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.props.onSearchClick(e, this.state.searchTerm);
    }
  }

  handleOnChange(e) {
    this.setState({ searchTerm: e.target.value }, this.props.onSearchTermChanged(e.target.value));
  }

  render() {
    return (
      <div style={searchDivStyle}>
        <input
          type="text"
          style={searchInputStyle}
          onChange={this.handleOnChange}
          value={this.state.searchTerm}
          className="filterDropdownListItem-search-text"
          placeholder="Search Requests"
          name="search"
          onKeyPress={this.handleKeyPress}
        />
        <button type="submit" style={searchButtonStyle} onClick={e => this.props.onSearchClick(e, this.state.searchTerm)}>
          <Sprite icon="Search" />
        </button>
      </div>
    );
  }
}

FilterSearchBox.propTypes = {
  onSearchClick: PropTypes.func,
  onSearchTermChanged: PropTypes.func,
  searchTerm: PropTypes.string.isRequired,
};

FilterSearchBox.defaultProps = {
  onSearchClick: () => { },
  onSearchTermChanged: () => { },
};
