import React from 'react';
import PropTypes from 'prop-types';

import { icons } from '../../../../components/sprite';
import HiddenText from '../../../components/hiddenText';

const errorMessages = Object.freeze({
  DataInvalidContactAdmin: 'Data is invalid. Please contact your system administrator.',
});

const FormValidationMessage = props => (
  <HiddenText
    text={props.errorMessage}
    show={props.show}
    icon={icons.Error}
    className="error form-error"
  />
);

FormValidationMessage.propTypes = {
  show: PropTypes.bool.isRequired,
  errorMessage: PropTypes.oneOf(Object.values(errorMessages)),
};

FormValidationMessage.defaultProps = {
  errorMessage: errorMessages.DataInvalidContactAdmin,
};

export default FormValidationMessage;
