import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import CommonFetch from '../../utils/commonFetch';
import Header from '../../components/header';
import MenuBar from './details/menuBar';
import Status from './details/status';
import RequestSummary from './details/requestSummary';
import Sprite from '../../components/sprite';
import Conversation from './details/conversation';
import Panel from './details/panel';
import DetailsTable from './details/fieldsTable';
import FieldRow from './details/fieldRow';
import Request from '../../../modules/ScoutModels/models/request';
import { FieldName, ChargeableField, EstimatedCompletionDateField } from '../../../modules/ScoutModels/models/field';
import RequestTypes from '../../../modules/ScoutModels/models/requestTypes';
import { StatusCaption } from '../../../modules/ScoutModels/models/requestStatus';

const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

const conversationWrapperStyle = {
  marginTop: '10px',
  padding: '10px',
};

const spriteWrapperStyle = {
  marginBottom: '10px',
};

class RequestDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestData: null,
      requestType: null,
      loading: true,
      error: 'Loading...',
    };

    this.loadRequest = this.loadRequest.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.loadRequest();
  }

  async loadRequest() {
    try {
      const requestDetailsPromise = CommonFetch.getRequestDetails(this.props.match.params.requestID);
      const requestTypePromise = CommonFetch.getFieldsConfiguration();

      const requestData = new Request(await requestDetailsPromise);
      const requestTypes = new RequestTypes(await requestTypePromise);
      const requestType = requestTypes.GetRequestType(requestData.serviceType);

      this.setState({
        error: '',
        loading: false,
        requestData,
        requestType,
      });
    } catch (error) {
      this.setState({
        loading: false,
        error: `Error on loading Service Request details.
Request ID: ${this.props.match.params.requestID}
${error}`,
      });
    }
  }

  render() {
    const jobTypeField = this.state.requestType && this.state.requestType.GetField(FieldName.JobType);
    const attachmentField = this.state.requestType && this.state.requestType.GetField(FieldName.Attachments);

    return (
      <div style={{ height: '100%' }}>
        <div>
          <Header Title="Scout" />
          <MenuBar
            showEditButton={!!this.state.requestData && !!this.state.requestData.serviceType && !!this.state.requestType.id}
            enableEditButton={!!this.state.requestData && !!this.state.requestData.ShouldAllowEditRequest}
            requestID={this.props.match.params.requestID}
          />
        </div>
        <div className="container">
          {this.state.error !== ''
            ? (
              <div className="w3-center w3-padding" style={{ whiteSpace: 'pre' }}>
                {this.state.error}
              </div>
            )
            : this.state.requestData
            && (
              <div className="main-content">
                <Status requestData={this.state.requestData} />
                <RequestSummary
                  attachmentField={attachmentField}
                  requestData={this.state.requestData}
                  requestID={this.props.match.params.requestID}
                />
                <div className="requestor-wrapper">
                  <Panel style={{ marginRight: '10px' }}>
                    <div style={spriteWrapperStyle}>
                      <Sprite icon="Requestor" color="black" />
                      <b className="padLeft-6">Requestor</b>
                    </div>
                    <DetailsTable>
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.Requestor)}
                        value={this.state.requestData.requestor}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.RequestorPhone)}
                        value={this.state.requestData.requestorPhone}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.RequestorEmail)}
                        value={this.state.requestData.requestorEmail}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.AdditionalNotificationEmailAddresses)}
                        value={this.state.requestData.additionalNotificationEmailAddresses ? this.state.requestData.additionalNotificationEmailAddresses.replace(/;/g, '; ') : undefined} // Put a space after every email so that they wrap better
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.CostCentre)}
                        value={this.state.requestData.costCentre}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.Expense)}
                        value={this.state.requestData.expense}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.Department)}
                        value={this.state.requestData.department}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.JobTask)}
                        value={this.state.requestData.jobTask}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.JobType)}
                        value={this.state.requestData.jobType}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.JobSubType)}
                        value={this.state.requestData.jobSubType}
                        show={!this.state.requestData.serviceType || (!!jobTypeField && jobTypeField.shown)}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.OHSIssue)}
                        value={this.state.requestData.ohsIssue}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.ReferenceNumber)}
                        value={this.state.requestData.referenceNumber}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={ChargeableField}
                        value={this.state.requestData.chargeable}
                        show={
                          StatusCaption[this.state.requestData.status.Status] !== StatusCaption.Waiting
                          || StatusCaption[this.state.requestData.status.Status] !== StatusCaption.Rejected
                        }
                      />
                      <FieldRow
                        field={EstimatedCompletionDateField}
                        value={this.state.requestData.DisplayEstimatedCompletionDate}
                        show={this.state.requestData.estimatedCompletionDate != null}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.Consent)}
                        value={this.state.requestData.consent}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.ConsentMessage)}
                        value={this.state.requestData.consentMessage}
                        show={!this.state.requestData.serviceType}
                      />
                    </DetailsTable>
                  </Panel>
                  <Panel>
                    <div style={spriteWrapperStyle}>
                      <Sprite icon="Location" color="black" />
                      <b className="padLeft-6">Location</b>
                    </div>
                    <DetailsTable>
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.Domain)}
                        value={this.state.requestData.domain}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.Site)}
                        value={this.state.requestData.site}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.Building)}
                        value={this.state.requestData.building}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.Floor)}
                        value={this.state.requestData.floor}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.Room)}
                        value={this.state.requestData.room}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.AssetName)}
                        value={this.state.requestData.assetName}
                        show={!this.state.requestData.serviceType}
                      />
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.SpecialAccess)}
                        value={this.state.requestData.specialAccess}
                        show={!this.state.requestData.serviceType}
                      />
                      {
                        this.state.requestData.specialAccess
                        && (
                          <React.Fragment>
                            <FieldRow
                              field={this.state.requestType.fields.fields.find(field => field.name === FieldName.SpecialAccessContactName)}
                              value={this.state.requestData.specialAccessContactName}
                              show={!this.state.requestData.serviceType}
                            />
                            <FieldRow
                              field={this.state.requestType.fields.fields.find(field => field.name === FieldName.SpecialAccessContactDetails)}
                              value={this.state.requestData.specialAccessContactDetails}
                              show={!this.state.requestData.serviceType}
                            />
                          </React.Fragment>
                        )
                      }
                      <FieldRow
                        field={this.state.requestType.fields.fields.find(field => field.name === FieldName.PreferredStartDate)}
                        value={moment(this.state.requestData.preferredStartDate).format('LL')}
                        show={!this.state.requestData.serviceType}
                      />
                    </DetailsTable>
                  </Panel>
                </div>
                <div style={conversationWrapperStyle} className="panel-content">
                  <Conversation id={this.state.requestData.id} />
                </div>
              </div>
            )
          }
          {this.state.loading && (
            <div className="wrapper">
              <div className="cssload-loader" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

RequestDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      requestID: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
export default RequestDetails;
