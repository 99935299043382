import React from 'react';
import { toast, style } from 'react-toastify';
import PropTypes from 'prop-types';

import './toaster.scss';

export const TOAST_SUCCESS = 'SUCCESS';
export const TOAST_ERROR = 'ERROR';
export const TOAST_WARNING = 'WARNING';

// Shared component that adds Toast functionality to other React Components.
class Toaster {
  static pop(msg, type = TOAST_SUCCESS, additionalInfo, onClose = null) {
    let className = 'toast-success';
    switch (type.toUpperCase()) {
      case TOAST_SUCCESS:
        className = 'toast-success';
        break;
      case TOAST_WARNING:
        className = 'toast-warning';
        break;
      case TOAST_ERROR:
        className = 'toast-error';
        break;
      default:
        console.warn(`A toast was created with no type. ${type} was provided`);
    }

    const divStyle = {
      whiteSpace: 'pre-line',
      padding: '8px 8px 8px 8px',
    };

    const parsedAdditionalInfo = this.parseAdditionalInfo(additionalInfo);
    const message = `${msg}${parsedAdditionalInfo}`;

    if (type.toLowerCase() !== TOAST_SUCCESS) {
      console.trace(message);
    }

    const CloseButton = props => (
      <div onClick={props.closeToast} className="toast-close">✖</div>
    );

    CloseButton.propTypes = {
      closeToast: PropTypes.func,
    };

    CloseButton.defaultProps = {
      closeToast: null,
    };

    style({
      zIndex: 900,
    });

    toast(
      <div style={divStyle} className="toast-message">
        {message}
      </div>,
      {
        className,
        closeButton: <CloseButton />,
        onClose: () => {
          if (onClose) {
            onClose();
          }
        },
      },
    );
  }

  static parseAdditionalInfo(additionalInfo) {
    if (additionalInfo == null) {
      return '';
    }

    let message = ': ';

    try {
      const parsedJson = JSON.parse(additionalInfo);
      if (parsedJson.ModelState != null) {
        const firstKey = Object.keys(parsedJson.ModelState)[0];
        const firstValue = parsedJson.ModelState[firstKey];
        message += firstValue;
        return message;
      }
      if (parsedJson.Message != null) {
        return message + parsedJson.Message;
      }
    } catch (e) {
      // This was not a JSON file, time to fall through
    }

    message += additionalInfo;
    return message;
  }

  /**
   * This will pop the toast and return a promise that will be resolved only when the toast is closed.
   * This is handy when you have more than one API call and the first one fails,
   * resulting in the setState of the following call to close the error toast being shown (causing it to show and then hide quickly).
   * By getting a promise you can stack toaster promises in an array and only update the state after awaiting all toasts closure.
   * Check Register.jsx to see an example of how it can be used.
   */
  static popPromise(msg, type = TOAST_SUCCESS, additionalInfo) {
    return new Promise((resolve) => {
      this.pop(msg, type, additionalInfo, () => resolve());
    });
  }

  static hideAll() {
    toast.dismiss();
  }
}

export default Toaster;
