import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const divRadioButton = {
  minWidth: '40px',
  textAlign: 'center',
  cursor: 'pointer',
};

const divRadioButtonBorder = {
  minWidth: '24px',
  lineHeight: '24px',
  fontSize: '19px',
  borderRadius: '24px',
  display: 'inline-table',
  backgroundColor: 'black',
  pointerEvents: 'none',
};

const RadioButton = props => (
  <div id={props.valueData} className="radio-button" style={divRadioButton} onClick={props.disabled ? () => {} : props.onSelectHandler}>
    &#8203;
    <div className={classNames('sprite', { disabled: props.disabled })} style={divRadioButtonBorder}>
      {props.selectedData === props.valueData ? '\uf058' : '\uf111'}
    </div>
  </div>
);

RadioButton.propTypes = {
  onSelectHandler: PropTypes.func.isRequired,
  valueData: PropTypes.string.isRequired,
  selectedData: PropTypes.string,
  disabled: PropTypes.bool,
};

RadioButton.defaultProps = {
  selectedData: null,
  disabled: false,
};

export default RadioButton;
