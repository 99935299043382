import CryptoJS from 'crypto-js';

const sliceLength = 2;
const byte1 = 8;
const byte64 = 512;
const byte32 = 256;

module.exports = {
  getIvLength(data) {
    return Number(data.substring(0, sliceLength));
  },

  getIv(data, ivLength) {
    return data.substring(sliceLength, ivLength + sliceLength);
  },

  getEncryptionData(data, ivLength) {
    return data.substring(ivLength + sliceLength);
  },

  decrypt(data, encryptionKey) {
    const ivLength = this.getIvLength(data);
    const ivBase64 = this.getIv(data, ivLength);
    const fileData = this.getEncryptionData(data, ivLength);

    const iv = CryptoJS.enc.Base64.parse(ivBase64);
    const key = CryptoJS.enc.Base64.parse(encryptionKey);
    const aesOptions = {
      keySize: byte32 / byte1,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    };

    return CryptoJS.AES.decrypt(fileData, key, aesOptions);
    /*
                HERES SOME TEST CODE TO HELP WITH ENCRYPTION/DECRYPTION  WILL NEED TO MOVE TO AN ACTUAL TEST
                var aesTestOptions = {
                    keySize: 256 / 8,
                    iv: CryptoJS.enc.Utf8.parse("8080808080808080"),
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7
                };
                var keyTest =  CryptoJS.enc.Utf8.parse("8080808080808080");
                var filedatatest = "WngHZidG4tBTBJyokHICeg=="
                var decryptedTest = CryptoJS.AES.decrypt(filedatatest, keyTest, aesTestOptions);

                console.log("DECRYPT TEST: ", decryptedTest.toString(CryptoJS.enc.Utf8));
                */
  },

  b64toBlob(b64Data, contentType, sliceSize) {
    const newContentType = contentType || '';
    const newSliceSize = sliceSize || byte64;

    /* atob available globally */
    // eslint-disable-next-line no-undef
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += newSliceSize) {
      const slice = byteCharacters.slice(offset, offset + newSliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    /* Blob available globally */
    // eslint-disable-next-line no-undef
    const blob = new Blob(byteArrays, { type: newContentType });
    return blob;
  },
};
