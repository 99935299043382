import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import classNames from 'classnames';

import Sprite from '../../components/sprite';

class NavigationButton extends React.Component {
  constructor(props) {
    super(props);

    this.goToDestination = this.goToDestination.bind(this);
  }

  goToDestination(e) {
    e.preventDefault();
    if (this.props.onClick) {
      this.props.onClick(this.props.destination);
    } else {
      this.props.history.push(this.props.destination);
    }
  }

  render() {
    return (
      <button
        type="button"
        disabled={!this.props.enabled}
        className={classNames({ 'w3-hide': !this.props.visible })}
        onClick={this.goToDestination}
      >
        { this.props.iconOnLeft && <Sprite icon={this.props.icon} />}
        <span className={classNames('label', { 'padLeft-6': this.props.iconOnLeft, 'padRight-6': !this.props.iconOnLeft })}>{this.props.label}</span>
        { !this.props.iconOnLeft && <Sprite icon={this.props.icon} />}
      </button>
    );
  }
}

NavigationButton.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  destination: PropTypes.string,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconOnLeft: PropTypes.bool,
  enabled: PropTypes.bool,
  visible: PropTypes.bool,
  onClick: PropTypes.func,
};

NavigationButton.defaultProps = {
  destination: null,
  icon: '',
  iconOnLeft: true,
  enabled: true,
  visible: true,
  onClick: null,
};

export default withRouter(NavigationButton);
