import React from 'react';
import Header from '../components/header';
import Sprite, { icons } from '../components/sprite';

const centerStyle = {
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  flexDirection: 'column',
};

const wrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // Hack until I can think of a better way to do this 😟.
  // Using flex will put this in the center of the remaining space below
  // the header, but this looks odd.  Adding padding of the same height as
  // the header resolves this (luckily the height of header doesn't change).
  paddingBottom: '54px',
};

const paraStyle = {
  fontSize: '20px',
  fontWeight: '600',
  textAlign: 'center',
  maxWidth: '90%',
};

const Unauthorised = () => (
  <div className="page">
    <Header Title="Scout" />
    <div id="unauthorised-message" style={centerStyle}>
      <div style={wrapperStyle}>
        <Sprite icon={icons.Rejected} fontSize="40px" />
        <p style={paraStyle}>You are not authorised to use this application.</p>
      </div>
    </div>
  </div>
);

export default Unauthorised;
