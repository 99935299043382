import React from 'react';
import PropTypes from 'prop-types';

const defaultWrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  padding: '10px',
};

const Panel = (props) => {
  const wrapperStyle = { ...defaultWrapperStyle, ...props.style };
  return (
    <div className="panel-content" style={wrapperStyle}>
      {props.children}
    </div>
  );
};

Panel.propTypes = {
  style: PropTypes.shape({}),
  children: PropTypes.node,
};

Panel.defaultProps = {
  style: {},
  children: null,
};


export default Panel;
