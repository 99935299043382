import { FieldName } from '../../modules/ScoutModels/models/field';
import CommonFetch from '../utils/commonFetch';

/** A mapping between fields and how they access the resource data they need for validation. */
const FieldResourceAccess = Object.freeze({
  [FieldName.Department]: CommonFetch.getDepartmentsList,
  [FieldName.CostCentre]: CommonFetch.getCostCentres,
  [FieldName.Expense]: CommonFetch.getExpensesOrDefault,
});

export default FieldResourceAccess;
