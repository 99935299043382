import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { DragDropContext } from 'react-dnd';
import TouchBackend from 'react-dnd-touch-backend';
import Preview from 'react-dnd-preview';

import DropTarget, { ReadOnlyDropTarget } from '../TrashCan';
import Slider from './Slider';
import eventTypes from '../eventTypes';
import '../../style.scss';

const dropzoneDefaultStyle = {
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  flexDirection: 'column',
  outlineOffset: '-10px',
  textAlign: 'center',
};

const containerDefaultStyle = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
};

const SlideState = Object.freeze({
  Added: 'Added',
  Loaded: 'Loaded',
  Deleted: 'Deleted',
});

class SliderImageUpload extends Component {
  constructor(props) {
    super(props);
    this.OnDrop = this.OnDrop.bind(this);
    this.OnSlideDrop = this.OnSlideDrop.bind(this);
    this.OnDragOver = this.OnDragOver.bind(this);
    this.OnDragLeave = this.OnDragLeave.bind(this);
    this.StateChange = this.StateChange.bind(this);
    this.StoreSlides = this.StoreSlides.bind(this);
    this.DragPreviewGenerator = this.DragPreviewGenerator.bind(this);
    this.expectedSlidesPerAddition = 0;
    this.state = {
      hideDropzone: false,
      dragging: false,
      totalSlides: 0,
      initialized: false,
    };
  }

  componentDidMount() {
    if (this.props.events) {
      this.props.events
        .on(eventTypes.AddSlide, (slides, totalLen) => {
          ++this.expectedSlidesPerAddition;
          this.setState(prevState => ({
            totalSlides: prevState.totalSlides + 1,
            hideDropzone: false,
          }), () => { this.StateChange(SlideState.Added, totalLen); });
        })
        .on(eventTypes.InitSlider, (slides, initialRender = false) => {
          this.setState(() => ({
            totalSlides: slides.length,
            initialized: initialRender,
            hideDropzone: false,
          }), () => { this.StateChange(SlideState.Loaded); });
        })
        .on(eventTypes.RemoveSlide, () => {
          this.setState(prevState => ({
            totalSlides: prevState.totalSlides - 1,
          }), () => { this.StateChange(SlideState.Deleted); });
        });
    }
  }

  getDropzoneVisibility() {
    let dropzoneVisibility = 'flex';

    /* If slides are passed via Attachment or PreviewAttachment, state.initialized will be set to true */
    if (!this.state.initialized || this.state.hideDropzone) {
      dropzoneVisibility = 'none';
    }

    return dropzoneVisibility;
  }

  OnDragLeave() {
    if (!this.props.disableAddition && this.state.dragging) {
      this.setState({
        dragging: false,
      });
    }
  }

  OnDragOver() {
    if (!this.props.disableAddition && !this.state.dragging) {
      this.setState({
        dragging: true,
      });
    }
  }

  OnDrop(files, rejectedFiles = []) {
    if (!this.props.disableAddition) {
      this.OnDragLeave();

      if (this.state.totalSlides === 0 && !rejectedFiles.length) {
        this.setState({
          hideDropzone: true,
        });
      }

      if (this.props.OnFileUpload) {
        this.props.OnFileUpload(files);
      }

      if (this.props.OnRejectedFileUpload && rejectedFiles.length) {
        this.props.OnRejectedFileUpload(rejectedFiles);
      }
    }
  }

  OnSlideDrop(targetId) {
    if (!this.props.disableDelete && targetId && this.props.OnFileDelete) this.props.OnFileDelete(targetId);
  }

  StateChange(eventType, expectedSlidesLen) {
    if (this.props.StateChange) {
      if (eventType === SlideState.Added && this.expectedSlidesPerAddition !== expectedSlidesLen) {
        return;
      }
      this.expectedSlidesPerAddition = 0;
      this.props.StateChange({
        state: eventType,
        totalAttachments: this.state.totalSlides,
      });
    }
  }

  // We stores the slides here so we can create drag previews from them. This is the same
  // data that is stored in the `Slider` components `this.children` property, so it would
  // be better if these were stored once and shared. One way would be to use reacts context
  // API, but this would require the `Attachment` component to store them which will take
  // a fair bit of refactoring. Something to consider when working in this area.
  StoreSlides(slides) {
    this.slides = slides;
  }

  DragPreviewGenerator(_itemType, item, previewStyle) {
    let preview = null;
    React.Children.forEach(this.slides, (slide) => {
      if (slide.props.id === item.id) {
        preview = React.cloneElement(slide, {
          altStyle: {
            ...previewStyle, width: '75px', height: '75px', opacity: '0.6',
          },
        });
      }
    });
    return preview;
  }

  render() {
    const dropzoneHeight = this.state.totalSlides > 0
      ? '50px'
      : '100%';

    const dropzoneBorder = this.state.dragging
      ? 'dropzoneHighlighOutline'
      : 'dropzoneOutline';

    return (
      <div style={containerDefaultStyle}>
        <Slider
          events={this.props.events}
          showFileName={this.props.showFileName}
          totalUnits={this.state.totalSlides}
          slideContainerStyle={this.props.slideContainerStyle}
          disableArrows={this.props.disableArrows}
          nextIcon={this.props.nextIcon}
          previousIcon={this.props.previousIcon}
          storeSlides={this.StoreSlides}
        />
        <Preview generator={this.DragPreviewGenerator} />
        <div className="flexRow" style={{ display: this.getDropzoneVisibility(), height: dropzoneHeight }}>
          <div style={{ width: '100%', height: dropzoneHeight }}>
            <Dropzone
              accept={this.props.accept}
              disableClick={this.props.disableAddition}
              disabled={this.props.disableAddition}
              onDragOver={this.OnDragOver}
              onDragLeave={this.OnDragLeave}
              style={dropzoneDefaultStyle}
              onDrop={this.OnDrop}
              className={dropzoneBorder}
            >
              <div>
                <div>{this.props.uploadIcon}</div>
                {(this.state.totalSlides === 0 && !this.state.hideDropzone) && <div style={{ marginTop: '10px' }}><span>Drop or choose files to upload</span></div>}
              </div>
            </Dropzone>
          </div>
          {
            this.state.totalSlides > 0
            && (
              <div className="dropTargetWrapper" style={{ width: '100%' }}>
                {
                  this.state.totalSlides > this.props.minLength
                    ? (<DropTarget OnSlideDrop={this.OnSlideDrop} trashIcon={this.props.trashIcon} />)
                    : (<ReadOnlyDropTarget tooltipText={this.props.tooltipOnReadOnlyTrashCan} className="dropzoneOutline readOnly" trashIcon={this.props.trashIcon} />)
                }
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

SliderImageUpload.propTypes = {
  OnFileUpload: PropTypes.func,
  OnFileDelete: PropTypes.func,
  OnRejectedFileUpload: PropTypes.func,
  StateChange: PropTypes.func,
  disableAddition: PropTypes.bool,
  disableDelete: PropTypes.bool,
  accept: PropTypes.string,
  slideContainerStyle: PropTypes.shape({}),
  showFileName: PropTypes.bool,
  disableArrows: PropTypes.bool,
  events: PropTypes.shape({
    on: PropTypes.func,
    emit: PropTypes.func,
    off: PropTypes.func,
  }),
  tooltipOnReadOnlyTrashCan: PropTypes.string,
  trashIcon: PropTypes.node,
  previousIcon: PropTypes.node,
  nextIcon: PropTypes.node,
  uploadIcon: PropTypes.node,
  minLength: PropTypes.number,
};

SliderImageUpload.defaultProps = {
  disableAddition: false,
  disableDelete: false,
  OnFileUpload: null,
  OnFileDelete: null,
  StateChange: null,
  tooltipOnReadOnlyTrashCan: null,
  showFileName: false,
  slideContainerStyle: {},
  disableArrows: false,
  OnRejectedFileUpload: null,
  accept: '',
  events: null,
  trashIcon: undefined,
  nextIcon: undefined,
  previousIcon: undefined,
  uploadIcon: <i className="fa fa-upload" aria-hidden="true" />,
  minLength: -1,
};

export { SliderImageUpload };
export default DragDropContext(TouchBackend({ enableMouseEvents: true }))(SliderImageUpload);
