const DefaultPath = '/';
const ForbiddenPaths = [
  new RegExp(`^/${ENV_ENDPOINTS.signinRedirectPath}.*$`), // Don't allow redirects back to the redirect page, or it's children
  /^(?!\/).*$/, // paths must start with a forward slash
];

function uriState() {
  const pathname = window.location.pathname;
  const search = window.location.search;
  const hash = window.location.hash;
  const wholePath = pathname + search + hash;

  if (ForbiddenPaths.some(rx => rx.test(wholePath))) {
    return DefaultPath;
  }

  return wholePath;
}

export default {
  uriState,
};
