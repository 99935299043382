// Not putting this through eslint, this is auto generated
// TODO - need to work out how to remove/replace the typescript stuff or getting it working with webpack (maybe thorugh an enforce: "pre" step?) maintaining 2 different techs is unmaintainable.
/* eslint-disable */

const __extends =
  (this && this.__extends) ||
  function(d, b) {
    for (const p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : ((__.prototype = b.prototype), new __());
  };
const React = require('react');
const message_1 = require('../model/conversation/message');
const messageType_1 = require('../model/conversation/messageType');
const auth_1 = require('../auth/auth').default;

/**
 * Component that allows a user to contribute to a conversation.
 */
const Contribution = (function(_super) {
  __extends(Contribution, _super);
  function Contribution(props) {
    const _this = _super.call(this, props) || this;
    _this.state = {
      input: '',
    };
    _this.addComment = _this.addComment.bind(_this);
    return _this;
  }
  Contribution.prototype.render = function() {
    const _this = this;
    const textAreaStyle = {
      fontFamily: 'Roboto',
      color: '#333333',
      resize: 'none',
      minHeight: 10,
      boxShadow: 'none',
      userSelect: 'auto',
      tabSize: 4,
      width: '100%',
      border: 'solid 0px #aaaaaa',
      outline: 'none',
    };
    const buttonStyle = {
      height: 30,
      width: 30,
      border: 0,
      color: '#aaaaaa',
      backgroundColor: '#ffffff',
    };
    const form = {
      marginBottom: '0px',
    };
    const table = {
      display: 'table',
      border: 'solid 0px blue',
      boxSizing: 'border-box',
      width: '100%',
    };
    const textCell = {
      display: 'table-cell',
      border: 'solid 1px silver',
      borderRadius: '10px 0px 0px 10px',
      padding: '5px 10px 5px 5px',
      borderRightWidth: '0px',
    };
    const buttonCell = {
      display: 'table-cell',
      border: 'solid 1px silver',
      borderRadius: '0px 10px 10px 0px',
      width: 40,
      textAlign: 'center',
      verticalAlign: 'middle',
    };
    return (
      <div className="Contribution">
        <form style={form}>
          <div style={table}>
            <div style={textCell}>
              <textarea
                ref={function(a) {
                  return (_this.inputElement = a);
                }}
                placeholder="add to conversation"
                style={textAreaStyle}
                onKeyDown={function(a) {
                  return _this.onKeyDown(a);
                }}
              />
            </div>
            <div style={buttonCell}>
              <button onClick={this.addComment} type="submit" style={buttonStyle}>
                {this.props.submitIcon}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };
  Contribution.prototype.onKeyDown = function(e) {
    if (e.keyCode == 13 && !e.shiftKey) {
      this.addComment(e); // Submit your form here
      return false;
    }
  };
  Contribution.prototype.addComment = function(e) {
    e.preventDefault();
    let comment = this.inputElement.value;
    comment = comment.trim();
    if (comment.length == 0) {
      return;
    }
    const newMessage = new message_1.Message();
    newMessage.Comment = comment;
    newMessage.CreatedDate = new Date();
    const userName = auth_1.usersName();
    const userEmail = auth_1.username();
    newMessage.Email = userEmail;
    newMessage.Name = userName;
    newMessage.MessageType = messageType_1.MessageType.Comment;
    this.props.newMessageEvent(newMessage);
    this.inputElement.value = '';
  };
  return Contribution;
})(React.Component);

Contribution.defaultProps = {
  submitIcon: <i className='fa fa-comment-o' aria-hidden='true'></i>,
};

Object.defineProperty(exports, '__esModule', { value: true });
exports.default = Contribution;
