// Not putting this through eslint, this is auto generated
// TODO - need to work out how to remove/replace the typescript stuff or getting it working with webpack (maybe thorugh an enforce: "pre" step?) maintaining 2 different techs is unmaintainable.
/* eslint-disable */

const __extends = (this && this.__extends) || function (d, b) {
  for (const p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
  function __() { this.constructor = d; }
  d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
const React = require('react');
const comment_1 = require('./comment');
const status_1 = require('./status');
const messageType_1 = require('../model/conversation/messageType');

/**
 * Component that maps an e-mail address to a Timeline image.
 */
const Timeline = (function (_super) {
  __extends(Timeline, _super);
  function Timeline(props) {
    const _this = _super.call(this, props) || this;
    _this.state = {
      isHidden: props.compactMode,
      timelineHeight: 0,
      lastAutoScrollPosition: -1,
    };
    _this.expandClicked = _this
      .expandClicked
      .bind(_this);
    return _this;
  }

  Timeline.prototype.render = function () {
    const _this = this;
    const count = this.props.messages.length + this.props.unsentMessages.length;
    let comments;
    let unsentComments;
    const timelineDivStyle = {};
    let chevronType = this.props.expandIconDown;
    if (this.state.isHidden) {
      timelineDivStyle.display = 'none';
      chevronType = this.props.expandIconUp;
    }
    if ((this.props.compactMode && !this.state.isHidden) || !this.props.compactMode) {
      comments = this.props.messages.map((e, index) => _this._messageToTimelineElement(e, false, index));
      unsentComments = this.props.unsentMessages.map((e, index) => _this._messageToTimelineElement(e, true, index));
    }
    if (this.props.compactMode && !this.state.isHidden) {
      if (this.props.maxHeight > 0) {
        timelineDivStyle.maxHeight = `${this.props.maxHeight}px`;
      }
      timelineDivStyle.position = 'relative';
      timelineDivStyle.zIndex = 1;
      timelineDivStyle.float = 'left';
      timelineDivStyle.marginTop = `-${this.state.timelineHeight + 1}px`;
      timelineDivStyle.width = '100%';
      timelineDivStyle.backgroundColor = 'white';
      timelineDivStyle.overflowX = 'hidden';
      timelineDivStyle.border = '1px silver solid';
      timelineDivStyle.borderBottom = '0px';
    }
    const newConversationDivStyle = {
      fontSize: '18px',
      color: 'silver',
      paddingTop: '20px',
      paddingBottom: '10px',
      paddingLeft: 30,
    };
    const hideTimelineButtonStyle = {
      float: 'right',
      border: '0px',
      padding: '5px 12px 0px 10px',
      textAlign: 'center',
      verticalAlign: 'middle',
      backgroundColor: 'white',
      color: '#323232',
      width: '120px',
    };
    const timelineParentDivStyle = {
        position: 'relative',
    };
  
    return (<div style={timelineParentDivStyle}>
      <div className="Timeline" id={"chatWindowPopup"} style={timelineDivStyle} ref={function (a) { return _this.timelineElement = a; }}>
        {count == 0 ?
            (<div style={newConversationDivStyle}>This is a new conversation, get typing to start this conversation.</div>)
            :
                (<div>
                  {comments}
                  {unsentComments}
                </div>)}
      </div>
      {this.props.compactMode ?
        <button onClick={this.expandClicked} type="submit" style={hideTimelineButtonStyle}> <b class="padRight-6">Comments</b>{chevronType}</button>
            :
        <div />}
            </div>);
  };

  Timeline.prototype._messageToTimelineElement = function (message, unsent, index) {
    if (message.MessageType == messageType_1.MessageType.Action) {
      return <status_1.default key={index} name={message.Name} created={message.CreatedDate} text={message.Comment} unsent={unsent} />;
    }

    return <comment_1.default key={index} email={message.Email} name={message.Name} created={message.CreatedDate} text={message.Comment} unsent={unsent} />;
  };

  Timeline.prototype.expandClicked = function (e) {
    e.preventDefault();
    const newHiddenState = !this.state.isHidden;
    this.setState({
      isHidden: newHiddenState,
      lastAutoScrollPosition: -1,
    });
  };

  Timeline.prototype.componentDidMount = function () {
    this.scrollToBottom();
  };

  Timeline.prototype.componentDidUpdate = function () {
    const newTimelineHeight = this.timelineElement.clientHeight;
    if (newTimelineHeight != this.state.timelineHeight) {
      this.setState({
        timelineHeight: newTimelineHeight,
      });
    }
    this.scrollToBottom();
  };

  Timeline.prototype.scrollToBottom = function () {
    if (this.state.isHidden && this.props.compactMode) {
      return;
    }
    if (this.timelineElement) {
      // test for divs until we find one with a vertical scroll bar
      const id = this.props.compactMode ? "#chatWindowPopup" : "#chatWindow";
      let divThatCanScroll = this.timelineElement.closest(id);

      if (divThatCanScroll) {
        this.scrollDiv(divThatCanScroll);
      }
    }
  };

  Timeline.prototype.scrollDiv = function (divThatCanScroll) {
    if (divThatCanScroll.scrollTop >= this.state.lastAutoScrollPosition) {
      divThatCanScroll.scrollTop = divThatCanScroll.scrollHeight - divThatCanScroll.clientHeight; // Set the scroll position for the div
      if (divThatCanScroll.scrollTop != this.state.lastAutoScrollPosition) {
        this.setState({ lastAutoScrollPosition: divThatCanScroll.scrollTop });
      }
    }
  };

  return Timeline;
}(React.Component));

Timeline.defaultProps = {
    expandIconUp: <i className='fa fa-chevron-up' aria-hidden="true" />,
    expandIconDown: <i className='fa fa-chevron-down' aria-hidden="true" />
};

Object.defineProperty(exports, '__esModule', { value: true });
exports.default = Timeline;
