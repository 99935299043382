import React from 'react';
import PropTypes from 'prop-types';

const filterLabel = 'Filter: ';

const ListCounter = props => (
  <div>
    {filterLabel}
    <b>{props.ActiveRequestsCount === 0 ? 'none' : `${props.ItemsFilteredLength} of ${props.ActiveRequestsCount}`}</b>
  </div>
);

ListCounter.propTypes = {
  ActiveRequestsCount: PropTypes.number.isRequired,
  ItemsFilteredLength: PropTypes.number,
};

ListCounter.defaultProps = {
  ItemsFilteredLength: 0,
};

export default ListCounter;
