import Attachment from './Attachment';
import PreviewAttachment from './PreviewAttachment';

/**
 *  How to use it
 *  1. If you need an attachment without preview modal,
 *
 *  import Attachment from './'
 *
 *  2. If you need an attachment with preview modal,
 *
 *  import { PreviewAttachment as Attachment } from './'
 *
 *  <Attachment
 *      attachedItemReference={guid}
 *      accept={acceptable file types, must be string of comma separated mime types, eg: 'text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
 *              default value is 'image/png', 'image/gif', 'image/jpeg', 'image/bmp', 'image/webp'
 *      readOnly={optional prop for add/remove attachment feature, default is false} if true, drag and drop , file uploading , delete are disabled. icons for uploading and delete are not visible.
 *      description={optional when you import PreviewAttachment, this will be rendered on preview dialog}
 *  />
 */

export { PreviewAttachment };
export default Attachment;
