import React from 'react';
import PropTypes from 'prop-types';
import './contextMenu.scss';

const ContextMenuItem = props => (
  <div className={`contextMenuItem${props.enabled ? '' : ' contextMenuItem-disabled'}`}>
    <div className="contextMenuItemIcon">{props.icon}</div>
    <div className="contextMenuItemContent">
      {props.title
        && <p className="contextMenuItemContentTitle">{props.title}</p>
      }
      {props.descriptions.map(description => <p key={description}>{description}</p>)}
    </div>
  </div>
);

ContextMenuItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string,
  descriptions: PropTypes.arrayOf(PropTypes.string),
  enabled: PropTypes.bool,
};

ContextMenuItem.defaultProps = {
  title: '',
  descriptions: [],
  enabled: true,
};

export default ContextMenuItem;
