class Requestor {
  constructor(requestor = {}) {
    this.id = requestor.id;
    this.name = requestor.name;
    this.email = requestor.email;
    this.phone = requestor.phone;
  }
}

export default Requestor;
