import React from 'react';
import PropTypes from 'prop-types';
import TimeDisplayRule from '../../../modules/common/conversation/models/TimeDisplayRule';
import Conversation from '../../../modules/ScoutModels/models/conversation';
import Sprite from '../../components/sprite';

const ConversationTime = (props) => {
  let caption = '';
  if (!props.conversation.IsLoading) {
    caption = props.conversation.VersionDate
      ? new TimeDisplayRule(props.conversation.VersionDate).Caption
      : 'No Messages';
  }
  return (
    <div>
      <Sprite icon="Conversation" color="#bebebe" />
      <span className="padLeft-8">
        <b>{caption}</b>
      </span>
    </div>
  );
};

ConversationTime.propTypes = {
  conversation: PropTypes.instanceOf(Conversation).isRequired,
};

export default ConversationTime;
