import _ from 'lodash';
import Fields from './fields';

export const RequestCategoryType = Object.freeze({
  BPDCategory: 'BPDCategory',
  FaultCategory: 'FaultCategory',
  QuoteCategory: 'QuoteCategory',
});

class RequestType {
  constructor(type = {}, fieldResourceAccess = {}) {
    this.id = type.id;
    this.fields = new Fields(type.fields, fieldResourceAccess);
    this.identifier = type.identifier || null;
    this.jobTaskCategories = type.jobTaskCategories || [];
    this.roles = type.roles || [];
    this.title = type.title || null;
    this.tooltip = type.tooltip || null;
    this.useRolesInTitle = false;
    this.requestCategory = type.requestCategory || RequestCategoryType.FaultCategory;
  }

  SetAsDefault() {
    this.fields.SetAsDefault();
  }

  get DisplayRoles() {
    return ` (${this.roles.sort().join(', ')})`;
  }

  /* This is for populating options in react-select */
  get SelectOption() {
    return {
      Value: this.id,
      Description: this.title,
    };
  }

  SetRequestCategory(value) {
    this.requestCategory = value;
  }

  SetFields(fields) {
    this.fields.SetFields(fields);
  }

  AddFields(fields) {
    this.fields.AddFields(fields);
  }

  GetField(fieldName) {
    return this.fields.GetField(fieldName);
  }

  Clone() {
    return new RequestType({
      id: this.id,
      fields: this.fields.Clone(),
      identifier: this.identifier,
      jobTaskCategories: this.jobTaskCategories,
      roles: this.roles,
      title: this.title,
      tooltip: this.tooltip,
      useRolesInTitle: this.useRolesInTitle,
      requestCategory: this.requestCategory,
    });
  }

  CompareTo(requestType) {
    return this.fields.CompareTo(requestType.fields.fields)
      && this.identifier === requestType.identifier
      && _.isEqual(this.roles, requestType.roles)
      && this.title === requestType.title
      && this.tooltip === requestType.tooltip
      && this.requestCategory === requestType.requestCategory;
  }
}

export default RequestType;
