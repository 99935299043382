import React from 'react';
import CryptoJS from 'crypto-js';
import Encryption from '../../common/auth/encryption';
import DownloadFile from '../../common/file/downloadFile';
import Auth from '../../common/auth/auth';

const blankFile = require('../images/BlankFile.png');

const onOpenFile = (file, item, attachmentEndpointIsCloud, IsFromMemory) => {
  if (IsFromMemory) {
    /* FileReader is from native browser API */
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.onload = () => {
      DownloadFile(file, item.Display, item.MimeType);
    };
    reader.readAsDataURL(file);
  } else {
    const itemToDownload = item;
    const getData = {
      method: 'GET',
    };

    if (!attachmentEndpointIsCloud) {
      getData.headers = {
        Authorization: `Bearer ${Auth.token()}`,
      };
    }

    fetch(item.File.Uri, getData)
      .then(response => response.text())
      .then((rawData) => {
        let data;
        if (item.File.EncryptionType === 'Static') {
          const decrypted = Encryption.decrypt(rawData, item.File.EncryptionKey);
          data = decrypted.toString(CryptoJS.enc.Base64);
        } else {
          data = rawData.toString(CryptoJS.enc.Base64);
        }

        DownloadFile(Encryption.b64toBlob(data, item.MimeType), itemToDownload.Display, itemToDownload.MimeType);
      })
      .catch(e => console.log(`Error opening File. ${e}`));
  }
};

function getFileExt(fileName) {
  const startOfString = 0;
  const fileNameExtLength = 4;
  const fileNameSplitOnDot = fileName.split('.');
  const extension = fileNameSplitOnDot.pop();
  return extension.substring(startOfString, fileNameExtLength).toUpperCase();
}

const DownloadableFile = (file, newAttachment, attachmentEndpointIsCloud, IsFromMemory = true) => {
  const ext = getFileExt((file && file.name) || newAttachment.Display);

  const thisStyle = {
    position: 'absolute', top: '35px', left: '19px', color: 'white',
  };

  // This is useful for testing defferred attachments
  // if (newAttachment.VersionDate === '') {
  //   thisStyle.color = 'Yellow';
  // }

  return (
    <div onClick={() => onOpenFile(file, newAttachment, attachmentEndpointIsCloud, IsFromMemory)} style={{ display: 'flex', position: 'relative' }}>
      <img style={{ width: '100%', height: '100%' }} src={blankFile} alt="preview" />
      <span style={thisStyle}>
        {ext}
      </span>
    </div>
  );
};

export default DownloadableFile;
