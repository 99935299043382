// Not putting this through eslint, this is auto generated
// TODO - need to work out how to remove/replace the typescript stuff or getting it working with webpack (maybe thorugh an enforce: "pre" step?) maintaining 2 different techs is unmaintainable.
/* eslint-disable */

const __extends = (this && this.__extends) || function (d, b) {
  for (const p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
  function __() { this.constructor = d; }
  d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
const React = require('react');
const gravatar_1 = require('../gravatar/gravatar');
const age_1 = require('./age');
const auth_1 = require('../auth/auth').default;

/**
 * Component that maps an e-mail address to a Comment image.
 */
const Comment = (function (_super) {
  __extends(Comment, _super);
  function Comment() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Comment.prototype.render = function () {
    const email = this.props.email.trim().toLowerCase();
    const style = {
      overflow: 'hidden',
      position: 'relative',
      padding: '5px 5px 5px 10px',
    };
    const gravatarWidth = this.props.gravatarSize + 10;
    const gravatarDivStyle = {
      width: gravatarWidth,
      float: 'left',
    };
    const nameDivStyle = {
      float: 'left',
    };
    const ageDivStyle = {
      float: 'right',
    };
    const textDivStyle = {
      marginLeft: gravatarWidth,
      marginRight: 0,
      marginTop: 20,
    };
    const commentTextClass = auth_1.usersName() === this.props.name ? 'currentUser' : 'otherUser';
    return (<div className={`Comment ${commentTextClass}`} style={style}>
      <div style={gravatarDivStyle}>
        <gravatar_1.default email={this.props.email} size={this.props.gravatarSize} radius={this.props.gravatarSize / 2} />
      </div>
      <div className="CommentName" style={nameDivStyle}>
        {this.props.name}
      </div>
      <div className="CommentAge" style={ageDivStyle}>
        <age_1.default created={this.props.created} unsent={this.props.unsent} />
      </div>
      <div className="CommentText" style={textDivStyle}>
        {this.props.text}
      </div>
    </div>);
  };
  return Comment;
}(React.Component));

Comment.defaultProps = {
  email: '', text: 'Lorem ipsum', created: new Date(Date.now()), gravatarSize: 60, unsent: false,
};

Object.defineProperty(exports, '__esModule', { value: true });
exports.default = Comment;
