import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Requests from '../../../modules/ScoutModels/models/requests';
import ListViewHeader from './listViewHeader';
import ListViewBody from './listViewBody';

class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCard: 'empty',
      showSortOptions: false,
    };
    this.onClickOptions = this.onClickOptions.bind(this);
    this.onClickShowDDL = this.onClickShowDDL.bind(this);
  }

  onClickShowDDL() {
    this.setState(prevState => ({ showSortOptions: !prevState.showSortOptions }));
  }

  onClickOptions(option) {
    this.props.sortRequestsBy(option);
    this.setState({
      showSortOptions: false,
    });
  }

  render() {
    const error = this.props.error;
    if (Object.getOwnPropertyNames(error).length !== 0) {
      return (
        <p className="w3-center">{error.message}</p>
      );
    }

    return (
      <div className="list-view">
        <ListViewHeader
          onClickOptions={this.onClickOptions}
          requests={this.props.requests}
          onClickTitle={this.onClickShowDDL}
          showOptions={this.state.showSortOptions}
        />
        <ListViewBody
          requests={this.props.requests}
          searchTerm={this.props.searchTerm}
          selectedCard={this.state.selectedCard}
        />
      </div>
    );
  }
}
export default ListView;

ListView.propTypes = {
  requests: PropTypes.instanceOf(Requests).isRequired,
  error: PropTypes.objectOf(PropTypes.object),
  searchTerm: PropTypes.string.isRequired,
  sortRequestsBy: PropTypes.func.isRequired,
};

ListView.defaultProps = {
  error: {},
};
