const _ = require('lodash');

const PropertyCaseConverter = (function PropertyCaseConverterInner() {
  function PropertyCaseConverterConstructor() {}
  const caseType = {
    camelCase: 'camelCase',
    PascalCase: 'PascalCase',
    kebabcase: 'kebab-case',
    snake_Case: 'snake_case',
  };

  PropertyCaseConverterConstructor.camelCaseJson = function camelCaseJson(arrayOfObject) {
    return this.jsonCaseConverter(arrayOfObject, caseType.camelCase);
  };

  PropertyCaseConverterConstructor.pascalCaseJson = function pascalCaseJson(arrayOfObject) {
    return this.jsonCaseConverter(arrayOfObject, caseType.PascalCase);
  };

  PropertyCaseConverterConstructor.jsonCaseConverter = function objectCaseConverter(arrayOfObject, type = caseType.camelCase) {
    const arrayOfObjectCamelCased = [];
    if (_.isArray(arrayOfObject)) {
      arrayOfObject.forEach((object) => {
        const objectCamelCased = this.objectCaseConverter(object, type);
        arrayOfObjectCamelCased.push(objectCamelCased);
      });
    }
    return arrayOfObjectCamelCased;
  };

  PropertyCaseConverterConstructor.objectCaseConverter = function objectCaseConverter(object, type = caseType.camelCase) {
    const objectResult = {};
    _.forEach(object, (value, key) => {
      let valueResult = value;
      if (_.isPlainObject(value) || _.isArray(value)) {
        valueResult = objectCaseConverter(value, type); // recursively update nested objects
      }
      switch (type) {
        case caseType.camelCase:
          objectResult[_.camelCase(key)] = valueResult;
          break;
        case caseType.PascalCase:
          objectResult[_.upperFirst(key)] = valueResult; // Lodash doesn't have .pascalCase yet, it may added in the future
          break;
        case caseType.kebabcase:
          objectResult[_.kebabCase(key)] = valueResult;
          break;
        case caseType.snake_Case:
          objectResult[_.snakeCase(key)] = valueResult;
          break;
        default:
          objectResult[key] = valueResult;
          break;
      }
    });
    return objectResult;
  };

  return PropertyCaseConverterConstructor;
}());

exports.PropertyCaseConverter = PropertyCaseConverter;
