import Auth from '../../modules/common/auth/auth';
import { isAuthorised } from '../../modules/common/auth/authorisedArea';
import Roles from '../../modules/common/auth/roles';

const authorisedRoles = [
  Roles.ScoutWebAdministrator,
  Roles.ScoutWebManager,
  Roles.ScoutWebUser,
];

class PulseAuth extends Auth {
  static get IsAuthorized() {
    return isAuthorised(authorisedRoles);
  }

  static isAdmin() {
    return super.isAdmin(Roles.ScoutWebAdministrator);
  }
}

export { authorisedRoles as AuthorisedRoles };
export default PulseAuth;
