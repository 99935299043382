import React from 'react';
import PropTypes from 'prop-types';
import Auth from './auth';
import NotAuthorisedIcon from './images/NotAuthorised.png';
import { UserManager } from './authConstants';

const isAuthorised = (roles) => {
  if (!Auth.loggedIn()) {
    return false;
  }

  const hasRole = Auth.hasAtLeastOneRole(roles);
  return hasRole;
};

const notAuthorisedImageStyle = {
  width: 128,
  height: 128,
};

const errorArea = {
  alignContent: 'center',
  width: '40%',
  marginTop: '10%',
  textAlign: 'center',
};

const imageContainer = {
  backgroundColor: '#54545C80',
  padding: 16,
  borderRadius: '50%',
  width: 'fit-content',
};

const fillArea = {
  height: '100%',
  width: '100%',
};

export default class AuthorisedArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authorised: false,
    };

    this.updateAuthorised = this.updateAuthorised.bind(this);
  }

  componentDidMount() {
    UserManager.getUser().then(() => this.updateAuthorised());

    UserManager.events.addUserLoaded(() => this.updateAuthorised());
  }

  componentWillUnmount() {
    UserManager.events.removeUserLoaded(() => this.updateAuthorised());
  }

  updateAuthorised() {
    this.setState({
      authorised: isAuthorised(this.props.authorisedRoles),
    });
  }

  render() {
    if (!this.props.authorisedRoles) {
    // There are no authorised roles - nobody can see this.
      console.warn('Unreachable component detected');
    }

    if (this.state.authorised) {
      return this.props.children;
    }
    if (!this.props.showError) {
      return null;
    }

    return (
      <div style={fillArea}>
        <div className="centered" style={errorArea}>
          <div className="centered" style={imageContainer}>
            <img src={NotAuthorisedIcon} alt="Not Authorised" style={notAuthorisedImageStyle} />
          </div>
          <h2>You are not authorised to see this page</h2>
        </div>
      </div>
    );
  }
}

AuthorisedArea.propTypes = {
  children: PropTypes.node, // What to show if you are authorised
  authorisedRoles: PropTypes.arrayOf(PropTypes.string), // A list of roles that are allowed to see this page. Matching a single one will show the page
  showError: PropTypes.bool, // A value indicating whether an unauthorised person should see the error message. This should be for full pages only.
};

AuthorisedArea.defaultProps = {
  children: null,
  authorisedRoles: [],
  showError: false,
};

export { isAuthorised };
