import React from 'react';
import PropTypes from 'prop-types';

const tableStyle = {
  width: '100%',
};

const divStyle = {
  overflow: 'hidden',
};

const FieldsTable = props => (
  <div style={divStyle}>
    <table style={tableStyle}>
      <tbody>
        {props.children}
      </tbody>
    </table>
  </div>
);

FieldsTable.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FieldsTable;
