import JobType from './jobType';

class JobTypes {
  constructor(jobtypes = []) {
    this.jobTypes = Array.isArray(jobtypes)
      ? jobtypes.map(jobType => new JobType(jobType))
      : [];
  }

  FindJobSubType(jobTypeDescription) {
    const jobSubType = this.jobTypes.find(jobType => jobType.jobTypeDescription === jobTypeDescription);
    return (jobSubType && jobSubType.jobSubTypes) || null;
  }

  get JobTypeOptions() {
    return this.jobTypes.map(jobType => ({ text: jobType.jobTypeDescription, id: jobType.jobTypeDescription, value: jobType.jobTypeDescription }))
      .sort((a, b) => a.text.localeCompare(b.text, 'en', { sensitivity: 'base' }));
  }

  JobSubTypeOptions(jobType) {
    const jobSubTypes = this.FindJobSubType(jobType);
    if (jobSubTypes) {
      return jobSubTypes.map(subType => ({ text: subType, id: subType, value: subType }))
        .sort((a, b) => a.text.localeCompare(b.text, 'en', { sensitivity: 'base' }));
    }
    return [];
  }
}

export default JobTypes;
