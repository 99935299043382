import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RadioButton from '../../components/radioButton';
import NavigationButton from '../../components/navigationButton';
import CommonFetch from '../../../utils/commonFetch';
import WizardField, { WizardFieldType } from './controls/WizardField';
import { FieldName } from '../../../../modules/ScoutModels/models/field';
import Request from '../../../../modules/ScoutModels/models/request';
import UserDetails from '../../../utils/userDetails';
import WaitingButton from '../../components/waitingButton';
import RequestWizard from '../../../models/requestWizard';
import FormValidationMessage from './controls/formValidationMessage';

const radioStyle = { display: 'flex', paddingTop: '10px', paddingBottom: '10px' };
const fieldStyleHide = {
  display: 'none',
};
const fieldStyleShow = {
  display: 'inherit',
  animation: 'opac .5s',
  paddingLeft: '10px',
};
const fieldStyleAlwaysShow = {
  display: 'inherit',
  paddingLeft: '10px',
};

class Step4 extends Component {
  constructor(props) {
    super(props);

    const newReportByMe = (!this.props.request.requestor || this.props.userDetails.FullName === this.props.request.requestor) && !this.props.isEdit;

    const reporter = this.initialiseReporter(newReportByMe);

    this.state = {
      reporter: newReportByMe ? 'me' : 'someoneelse',
      requestor: reporter.requestor,
      requestorTouched: false,
      requestorInitialised: !this.requestorField.shown,
      requestorValid: !this.requestorField.shown,
      requestorPhone: reporter.requestorPhone,
      requestorPhoneTouched: false,
      requestorPhoneInitialised: !this.requestorPhoneField.shown,
      requestorPhoneValid: !this.requestorPhoneField.shown,
      requestorEmail: reporter.requestorEmail,
      requestorEmailTouched: false,
      requestorEmailInitialised: !this.requestorEmailField.shown,
      requestorEmailValid: !this.requestorEmailField.shown,
      additionalNotificationEmailAddresses: reporter.additionalNotificationEmailAddresses,
      additionalNotificationEmailAddressesTouched: false,
      additionalNotificationEmailAddressesInitialised: !this.additionalNotificationEmailAddressesField.shown,
      additionalNotificationEmailAddressesValid: !this.additionalNotificationEmailAddressesField.shown,
      costCentreOptions: null,
      costCentreTouched: false,
      costCentreInitialised: !this.costCentreField.shown,
      costCentreValid: !this.costCentreField.shown,
      expenseTouched: false,
      expenseInitialised: !this.expenseField.shown,
      expenseValid: !this.expenseField.shown,
      loading: false,
      showTooltip: '',
      formValid: false,
      defaultOnlyFieldInvalid: false,
    };

    this.bindFunctions();
  }

  async componentDidMount() {
    if (this.requestorField.shown) {
      this.onRequestorChange(this.props.request.requestor, !!this.props.request.requestor, true);
    }

    if (this.requestorPhoneField.shown) {
      this.onRequestorPhoneChange(this.props.request.requestorPhone, !!this.props.request.requestorPhone, true);
    }

    if (this.requestorEmailField.shown) {
      this.onRequestorEmailChange(this.props.request.requestorEmail, !!this.props.request.requestorEmail, true);
    }

    if (this.additionalNotificationEmailAddressesField.shown) {
      this.onAdditionalNotificationEmailAddressesChange(
        this.props.request.additionalNotificationEmailAddresses,
        !!this.props.request.additionalNotificationEmailAddresses,
        true,
      );
    }

    if (this.costCentreField.shown) {
      this.getCostCentreOptions();
    }

    if (this.expenseField.shown) {
      this.onExpenseChange(this.props.request.expense, !!this.props.request.expense, true);
    }
  }

  componentWillUnmount() {
    this.props.saveRequest(this.props.request);
  }

  onTooltipClick(event) {
    event.stopPropagation();
    const target = event.target;
    this.setState(prevState => ({ showTooltip: prevState.showTooltip === target.getAttribute('data-value') ? '' : target.getAttribute('data-value') }));
  }

  onReporterChange(event) {
    const reporter = event.target.id;
    const hasChanged = this.state.reporter !== reporter;
    this.setState(() => ({
      reporter,
    }), () => this.toggleReporter(hasChanged));
  }

  onRequestorChange(value, requestorTouched = true) {
    this.onChange(value, 'requestor', this.requestorField, 'requestorValid', 'requestorInitialised', { requestor: value, requestorTouched });
  }

  onRequestorPhoneChange(value, requestorPhoneTouched = true) {
    this.onChange(value, 'requestorPhone', this.requestorPhoneField, 'requestorPhoneValid', 'requestorPhoneInitialised', { requestorPhone: value, requestorPhoneTouched });
  }

  onRequestorEmailChange(value, requestorEmailTouched = true) {
    this.onChange(value, 'requestorEmail', this.requestorEmailField, 'requestorEmailValid', 'requestorEmailInitialised', { requestorEmail: value, requestorEmailTouched });
  }

  onAdditionalNotificationEmailAddressesChange(value, additionalNotificationEmailAddressesTouched = true) {
    this.onChange(value, 'additionalNotificationEmailAddresses', this.additionalNotificationEmailAddressesField, 'additionalNotificationEmailAddressesValid', 'additionalNotificationEmailAddressesInitialised', { additionalNotificationEmailAddresses: value, additionalNotificationEmailAddressesTouched });
  }

  onCostCentreChangeEvent(option) {
    this.onCostCentreChange(option.text);
  }

  onCostCentreChange(costCentre, costCentreTouched = true) {
    this.onChange(costCentre, 'costCentre', this.costCentreField, 'costCentreValid', 'costCentreInitialised', { costCentreTouched });
  }

  onExpenseChangeEvent(option) {
    this.onExpenseChange(option.text);
  }

  onExpenseChange(expense, expenseTouched = true) {
    this.onChange(expense, 'expense', this.expenseField, 'expenseValid', 'expenseInitialised', { expenseTouched });
  }

  onChange(value, requestPropertyName, field, validStateKey, initialisedStateKey, state) {
    this.props.request[requestPropertyName] = value;

    this.setState(state);
    this.validateField(field, validStateKey, initialisedStateKey);
  }

  onDoneButtonClick() {
    this.setState({ loading: true });
    this.props.saveRequest(this.props.request, true);
  }

  async getCostCentreOptions() {
    const costCentreOptions = await this.costCentreField.LookupData();
    this.setState({
      costCentreOptions,
    });
    this.onCostCentreChange(this.props.request.costCentre, !!this.props.request.costCentre, true);
  }

  get requestorField() {
    return this.props.requestWizard.getField(FieldName.Requestor);
  }

  get requestorPhoneField() {
    return this.props.requestWizard.getField(FieldName.RequestorPhone);
  }

  get requestorEmailField() {
    return this.props.requestWizard.getField(FieldName.RequestorEmail);
  }

  get disableRequestor() {
    return (!this.requestorField.shown || this.requestorField.defaultOnly)
      && (!this.requestorPhoneField.shown || this.requestorPhoneField.defaultOnly)
      && (!this.requestorEmailField.shown || this.requestorEmailField.defaultOnly)
      && (!this.additionalNotificationEmailAddressesField.shown || this.additionalNotificationEmailAddressesField.defaultOnly);
  }

  get costCentreField() {
    return this.props.requestWizard.getField(FieldName.CostCentre);
  }

  get expenseField() {
    return this.props.requestWizard.getField(FieldName.Expense);
  }

  get additionalNotificationEmailAddressesField() {
    return this.props.requestWizard.getField(FieldName.AdditionalNotificationEmailAddresses);
  }

  get requestorIsDefaultOnlyAndInvalid() {
    return this.requestorField.shown
      && this.requestorField.defaultOnly
      && this.state.requestorInitialised
      && !this.state.requestorValid;
  }

  get requestorPhoneIsDefaultOnlyAndInvalid() {
    return this.requestorPhoneField.shown
      && this.requestorPhoneField.defaultOnly
      && this.state.requestorPhoneInitialised
      && !this.state.requestorPhoneValid;
  }

  get requestorEmailIsDefaultOnlyAndInvalid() {
    return this.requestorEmailField.shown
      && this.requestorEmailField.defaultOnly
      && this.state.requestorEmailInitialised
      && !this.state.requestorEmailValid;
  }

  get additionalNotificationEmailAddressIsDefaultOnlyAndInvalid() {
    return this.additionalNotificationEmailAddressField.shown
      && this.additionalNotificationEmailAddressField.defaultOnly
      && this.state.additionalNotificationEmailAddressInitialised
      && !this.state.additionalNotificationEmailAddressValid;
  }

  get costCentreIsDefaultOnlyAndInvalid() {
    return this.costCentreField.shown
      && this.costCentreField.defaultOnly
      && this.state.costCentreInitialised
      && !this.state.costCentreValid;
  }

  get expenseIsDefaultOnlyAndInvalid() {
    return this.expenseField.shown
      && this.expenseField.defaultOnly
      && this.state.expenseInitialised
      && !this.state.expenseValid;
  }

  async validateField(field, validStateKey, initialisedStateKey) {
    const valid = await this.props.requestWizard.validateField(field, this.props.request);
    this.setState({
      [validStateKey]: valid,
      [initialisedStateKey]: true,
    }, () => this.validateForm());
  }

  validateForm() {
    this.setState((prevState) => {
      const formInitialised = prevState.requestorInitialised && prevState.requestorPhoneInitialised && prevState.requestorEmailInitialised
        && prevState.additionalNotificationEmailAddressesInitialised && prevState.costCentreInitialised && prevState.expenseInitialised;
      const formValid = prevState.requestorValid && prevState.requestorPhoneValid && prevState.requestorEmailValid
        && prevState.additionalNotificationEmailAddressesValid && prevState.costCentreValid && prevState.expenseValid;
      const defaultOnlyFieldInvalid = this.defaultOnlyFieldInvalid();
      // Going to leave this here, probably be useful in the future.
      /* eslint-disable max-len */
      // console.group('Form valid');
      // console.log(`Requestor - Initialised:${prevState.requestorInitialised} Valid:${prevState.requestorValid}`);
      // console.log(`Requestor Phone - Initialised:${prevState.requestorPhoneInitialised} Valid:${prevState.requestorPhoneValid}`);
      // console.log(`Requestor Email - Initialised:${prevState.requestorEmailInitialised} Valid:${prevState.requestorEmailValid}`);
      // console.log(`Additional Notification Email Addresses - Initialised:${prevState.additionalNotificationEmailAddressesInitialised} Valid:${prevState.additionalNotificationEmailAddressesValid}`);
      // console.log(`Cost Centre - Initialised:${prevState.costCentreInitialised} Valid:${prevState.costCentreValid}`);
      // console.log(`Expense - Initialised:${prevState.expenseInitialised} Valid:${prevState.expenseValid}`);
      // console.log(`Form - Initialised:${formInitialised} Valid:${formValid} Default Only Invalid:${defaultOnlyFieldInvalid}`);
      // console.groupEnd();
      /* eslint-enable max-len */
      return { formValid, formInitialised, defaultOnlyFieldInvalid };
    });
  }

  defaultOnlyFieldInvalid() {
    if (this.requestorIsDefaultOnlyAndInvalid
      || this.requestorPhoneIsDefaultOnlyAndInvalid
      || this.requestorEmailIsDefaultOnlyAndInvalid
      || this.additionalNotificationEmailAddressesIsDefaultOnlyAndInvalid
      || this.costCentreIsDefaultOnlyAndInvalid
      || this.expenseIsDefaultOnlyAndInvalid) {
      return true;
    }
    return false;
  }

  initialiseReporter(newReportByMe) {
    this.differentRequestor = {
      requestor: newReportByMe ? '' : this.props.request.requestor || '',
      requestorEmail: newReportByMe ? '' : this.props.request.requestorEmail || '',
      requestorPhone: newReportByMe ? '' : this.props.request.requestorPhone || '',
      additionalNotificationEmailAddresses: this.props.request.additionalNotificationEmailAddresses || '',
      costCentre: newReportByMe ? '' : this.props.request.costCentre || '',
      expense: newReportByMe ? '' : this.props.request.expense || '',
    };

    this.requestor = {
      requestor: this.props.userDetails.FullName,
      requestorEmail: this.props.userDetails.Email,
      requestorPhone: this.props.userDetails.Phone,
      costCentre: this.props.userDetails.CostCentreName,
      expense: this.props.userDetails.ExpenseName,
    };

    const reporter = newReportByMe
      ? this.requestor
      : this.differentRequestor;
    return reporter;
  }

  toggleReporter(hasChanged) {
    const reportByMe = this.state.reporter === 'me';
    const requestor = reportByMe ? this.requestor.requestor : this.differentRequestor.requestor;
    const requestorEmail = reportByMe ? this.requestor.requestorEmail : this.differentRequestor.requestorEmail;
    const requestorPhone = reportByMe ? this.requestor.requestorPhone : this.differentRequestor.requestorPhone;
    const additionalNotificationEmailAddresses = reportByMe
      ? this.requestor.additionalNotificationEmailAddresses : this.differentRequestor.additionalNotificationEmailAddresses;
    const costCentre = reportByMe ? this.requestor.costCentre : this.differentRequestor.costCentre;
    const expense = reportByMe ? this.requestor.expense : this.differentRequestor.expense;

    if (reportByMe && hasChanged) {
      this.updateDifferentRequestor();
    }

    if (!reportByMe && hasChanged) {
      this.updateRequestor();
    }

    this.updateForm(costCentre, expense, requestor, requestorEmail, requestorPhone, additionalNotificationEmailAddresses);

    // Going to leave this here, probably be useful in the future.
    // this.debugLogging(requestor, requestorEmail, requestorPhone, additionalNotificationEmailAddresses, costCentre, expense);
  }

  // debugLogging(requestor, additionalNotificationEmailAddresses, costCentre, expense) {
  //   console.group('%cAll requestor', 'background-color: #e0005a ; color: #ffffff ; font-weight: bold ; padding: 4px ;');
  //   console.log('Requestor being set', requestor);
  //   console.log('this.requestor.requestor', this.requestor.requestor);
  //   console.log('this.differentRequestor.requestor', this.differentRequestor.requestor);
  //   console.groupEnd();
  //   console.group('%cAll additional notification email addresses', 'background-color: #e0005a ; color: #ffffff ; font-weight: bold ; padding: 4px ;');
  //   console.log('Email being set', additionalNotificationEmailAddresses);
  //   console.log('this.requestor.additionalNotificationEmailAddresses', this.requestor.additionalNotificationEmailAddresses);
  //   console.log('this.differentRequestor.additionalNotificationEmailAddresses', this.differentRequestor.additionalNotificationEmailAddresses);
  //   console.groupEnd();
  //   console.group('%cAll cost centres', 'background-color: #e0005a ; color: #ffffff ; font-weight: bold ; padding: 4px ;');
  //   console.log('Cost centre being set', costCentre);
  //   console.log('this.requestor.costCentre', this.requestor.costCentre);
  //   console.log('this.differentRequestor.costCentre', this.differentRequestor.costCentre);
  //   console.groupEnd();
  //   console.group('%cAll expenses', 'background-color: #e0005a ; color: #ffffff ; font-weight: bold ; padding: 4px ;');
  //   console.log('Expense being set', expense);
  //   console.log('this.requestor.expense', this.requestor.expense);
  //   console.log('this.differentRequestor.expense', this.differentRequestor.expense);
  //   console.groupEnd();
  // }

  updateForm(costCentre, expense, requestor, requestorEmail, requestorPhone, additionalNotificationEmailAddresses) {
    this.onCostCentreChange(costCentre, this.state.costCentreTouched || false);
    this.onExpenseChange(expense, this.state.expenseTouched || false);
    this.onRequestorChange(requestor, this.state.requestorTouched || false);
    this.onRequestorEmailChange(requestorEmail, this.state.requestorEmailTouched || false);
    this.onRequestorPhoneChange(requestorPhone, this.state.requestorPhoneTouched || false);
    this.onAdditionalNotificationEmailAddressesChange(additionalNotificationEmailAddresses,
      this.state.additionalNotificationEmailAddressesTouched || false);
  }

  updateRequestor() {
    this.requestor.costCentre = this.props.request.costCentre;
    this.requestor.expense = this.props.request.expense;
  }

  updateDifferentRequestor() {
    this.differentRequestor.requestor = this.props.request.requestor;
    this.differentRequestor.requestorEmail = this.props.request.requestorEmail;
    this.differentRequestor.requestorPhone = this.props.request.requestorPhone;
    this.differentRequestor.additionalNotificationEmailAddresses = this.props.request.additionalNotificationEmailAddresses;
    this.differentRequestor.costCentre = this.props.request.costCentre;
    this.differentRequestor.expense = this.props.request.expense;
  }

  bindFunctions() {
    this.onAdditionalNotificationEmailAddressesChange = this.onAdditionalNotificationEmailAddressesChange.bind(this);
    this.onReporterChange = this.onReporterChange.bind(this);
    this.onRequestorChange = this.onRequestorChange.bind(this);
    this.onRequestorPhoneChange = this.onRequestorPhoneChange.bind(this);
    this.onRequestorEmailChange = this.onRequestorEmailChange.bind(this);
    this.onCostCentreChange = this.onCostCentreChange.bind(this);
    this.onCostCentreChangeEvent = this.onCostCentreChangeEvent.bind(this);
    this.onExpenseChange = this.onExpenseChange.bind(this);
    this.onExpenseChangeEvent = this.onExpenseChangeEvent.bind(this);
    this.onDoneButtonClick = this.onDoneButtonClick.bind(this);
    this.divListHandler = this.divListHandler.bind(this);
    this.onTooltipClick = this.onTooltipClick.bind(this);
  }

  divListHandler(type) {
    this.setState({
      typeListShown: type,
    });
  }

  // Lots of code patterns appearing which should be refactored into controls or components, unfortunately no capacity to do it now.
  // eslint-disable-next-line complexity
  render() {
    const backDestination = this.props.editRequestID ? `/requests/edit/3/${this.props.editRequestID}` : '/requests/new/3';
    const backButton = { icon: 'Back', label: 'Back', destination: backDestination };
    return (
      <div className="stepform">
        <FormValidationMessage show={this.state.defaultOnlyFieldInvalid} />
        <h5>
          <b>Who is reporting the problem?</b>
        </h5>
        <div className={`${this.state.loading ? 'w3-disabled ' : ''}fields`}>
          <div>
            {!this.props.isEdit && (
              <div style={radioStyle}>
                <div style={{ display: 'flex' }}>
                  <label htmlFor="me" style={{ display: 'flex' }}>
                    <RadioButton onSelectHandler={this.onReporterChange} valueData="me" selectedData={this.state.reporter} disabled={this.disableRequestor} />
                    Me
                  </label>
                  <label htmlFor="someoneelse" style={{ display: 'flex', paddingLeft: '20px' }}>
                    <RadioButton onSelectHandler={this.onReporterChange} valueData="someoneelse" selectedData={this.state.reporter} disabled={this.disableRequestor} />
                    Someone Else
                  </label>
                </div>
              </div>
            )}
            <div style={this.state.reporter === 'someoneelse' ? fieldStyleShow : fieldStyleHide}>
              <WizardField
                wizardFieldType={WizardFieldType.Input}
                type={FieldName.Requestor}
                field={this.requestorField}
                parentVal={{ text: this.state.requestor }}
                valueChanged={this.onRequestorChange}
                divShown={this.divListHandler}
                showOpt={this.state.typeListShown === FieldName.Requestor}
                isValid={
                  !this.state.requestorInitialised
                  || this.state.requestorValid
                  || !this.state.requestorTouched
                }
                showTooltip={this.state.showTooltip === this.requestorField.id}
                onTooltipClick={this.onTooltipClick}
                className={this.state.requestorValid || !this.state.requestorTouched ? 'validInput' : 'invalidInput'}
                maxLength={40}
                isDisabled={this.requestorField.defaultOnly}
              />
              <WizardField
                wizardFieldType={WizardFieldType.Input}
                type={FieldName.RequestorPhone}
                field={this.requestorPhoneField}
                parentVal={{ text: this.state.requestorPhone }}
                valueChanged={this.onRequestorPhoneChange}
                divShown={this.divListHandler}
                showOpt={this.state.typeListShown === FieldName.RequestorPhone}
                isValid={
                  !this.state.requestorPhoneInitialised
                  || this.state.requestorPhoneValid
                  || !this.state.requestorPhoneTouched
                }
                showTooltip={this.state.showTooltip === this.requestorPhoneField.id}
                onTooltipClick={this.onTooltipClick}
                className={this.state.requestorPhoneValid || !this.state.requestorPhoneTouched ? 'validInput' : 'invalidInput'}
                maxLength={40}
                isDisabled={this.requestorPhoneField.defaultOnly}
              />
              <WizardField
                wizardFieldType={WizardFieldType.Input}
                type={FieldName.RequestorEmail}
                field={this.requestorEmailField}
                parentVal={{ text: this.state.requestorEmail }}
                valueChanged={this.onRequestorEmailChange}
                divShown={this.divListHandler}
                showOpt={this.state.typeListShown === FieldName.RequestorEmail}
                isValid={
                  !this.state.requestorEmailInitialised
                  || this.state.requestorEmailValid
                  || !this.state.requestorEmailTouched
                }
                showTooltip={this.state.showTooltip === this.requestorEmailField.id}
                onTooltipClick={this.onTooltipClick}
                className={this.state.requestorEmailValid || !this.state.requestorEmailTouched ? 'validInput' : 'invalidInput'}
                maxLength={100}
                isDisabled={this.requestorEmailField.defaultOnly}
              />
              <WizardField
                wizardFieldType={WizardFieldType.Input}
                type={FieldName.AdditionalNotificationEmailAddresses}
                field={this.additionalNotificationEmailAddressesField}
                parentVal={{ text: this.state.additionalNotificationEmailAddresses }}
                valueChanged={this.onAdditionalNotificationEmailAddressesChange}
                divShown={this.divListHandler}
                showOpt={this.state.typeListShown === FieldName.AdditionalNotificationEmailAddresses}
                isValid={
                  !this.state.additionalNotificationEmailAddressesInitialised
                  || this.state.additionalNotificationEmailAddressesValid
                  || !this.state.additionalNotificationEmailAddressesTouched
                }
                showTooltip={this.state.showTooltip === this.additionalNotificationEmailAddressesField.id}
                onTooltipClick={this.onTooltipClick}
                className={this.state.additionalNotificationEmailAddressesValid || !this.state.additionalNotificationEmailAddressesTouched ? 'validInput' : 'invalidInput'}
                maxLength={255}
                isDisabled={this.additionalNotificationEmailAddressesField.defaultOnly}
              />
            </div>
            <div style={fieldStyleAlwaysShow}>
              <WizardField
                wizardFieldType={WizardFieldType.Autocomplete}
                type={FieldName.CostCentre}
                field={this.costCentreField}
                optionsData={this.state.costCentreOptions}
                parentVal={{ text: this.props.request.costCentre }}
                valueChanged={this.onCostCentreChangeEvent}
                event={this.event}
                divShown={this.divListHandler}
                showOpt={this.state.typeListShown === FieldName.CostCentre}
                isValid={
                  !this.state.costCentreInitialised
                  || this.state.costCentreValid
                  || !this.state.costCentreTouched
                }
                showTooltip={this.state.showTooltip === this.costCentreField.id}
                onTooltipClick={this.onTooltipClick}
                isDisabled={this.costCentreField.defaultOnly}
              />
              <WizardField
                wizardFieldType={WizardFieldType.AutocompleteTypeAhead}
                type={FieldName.Expense}
                field={this.expenseField}
                fetchOptions={CommonFetch.searchExpenses}
                parentVal={{ text: this.props.request.expense }}
                valueChanged={this.onExpenseChangeEvent}
                event={this.event}
                divShown={this.divListHandler}
                showOpt={this.state.typeListShown === FieldName.Expense}
                isValid={
                  !this.state.expenseInitialised
                  || this.state.expenseValid
                  || !this.state.expenseTouched
                }
                showTooltip={this.state.showTooltip === this.expenseField.id}
                onTooltipClick={this.onTooltipClick}
                isDisabled={this.expenseField.defaultOnly}
              />
            </div>
          </div>
        </div>
        <div className="button-group">
          <div className="w3-left back">
            <NavigationButton
              icon={backButton.icon}
              label={backButton.label}
              destination={backButton.destination}
            />
          </div>
          <div className="w3-right next">
            <WaitingButton
              waiting={this.state.loading}
              icon="Next"
              label="Done"
              iconOnLeft={false}
              enabled={this.state.formInitialised && this.state.formValid}
              onClick={this.onDoneButtonClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

Step4.propTypes = {
  request: PropTypes.instanceOf(Request).isRequired,
  userDetails: PropTypes.instanceOf(UserDetails).isRequired,
  saveRequest: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  editRequestID: PropTypes.string,
  requestWizard: PropTypes.instanceOf(RequestWizard).isRequired,
};

Step4.defaultProps = {
  editRequestID: '',
};

export default Step4;
