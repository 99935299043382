// Not putting this through eslint, this is auto generated
// TODO - need to work out how to remove/replace the typescript stuff or getting it working with webpack (maybe thorugh an enforce: "pre" step?) maintaining 2 different techs is unmaintainable.
/* eslint-disable */

const __extends = (this && this.__extends) || function (d, b) {
  for (const p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
  function __() { this.constructor = d; }
  d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
const React = require('react');
const MD5 = require('md5');
const placeholder = require('./images/avatar.svg');

const emailPattern = /(.+)@(.+){2,}\.(.+){2,}/;

/**
 * Component that maps an e-mail address to a Gravatar image.
 */
const Gravatar = (function (_super) {
  __extends(Gravatar, _super);
  function Gravatar() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Gravatar.prototype.render = function () {
    const regexp = new RegExp('[+][a-zA-Z0-9]*');
    const email = this.props.email
      .trim()
      .toLowerCase()
      .replace(regexp, '');
    const style = {
      borderRadius: this.props.radius,
      width: this.props.size,
      height: this.props.size,
      background: `#ffffff url(${placeholder})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    };

    if (emailPattern.test(email)) {
      const hash = MD5(email);
      const source = `https://www.gravatar.com/avatar/${hash}?d=mm&s=${this.props.size}&r=r`;
      style.background = `#ffffff url(${source})`;
    }

    const combinedStyle = {
      ...style,
      ...this.props.customStyle,
    }

    return <div className="Gravatar" style={combinedStyle} />;
  };
  return Gravatar;
}(React.Component));
Gravatar.defaultProps = { email: '', size: 40, radius: 0, customStyle: null };
Object.defineProperty(exports, '__esModule', { value: true });
exports.default = Gravatar;
