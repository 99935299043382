import Auth from '../auth/auth';
import eventNotifier from '../eventHandling/eventNotifier';
import eventType from '../eventHandling/eventType';

export const keepUndefined = (key, value) => { if (value === undefined) { return null; } return value; };

// This includes the keepUndefined logic
export const emptyAsNull = (key, value) => { if (value === undefined || value === '') { return null; } return value; };

export const isResponseCodeOK = num => num && num.toString()[0] === '2';

/* Custom error class extends Error to differentiate a promise error (eg:server) and a promise cancel */
function PromiseCancelError(...params) {
  const instance = new Error(...params);
  Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
  return instance;
}

// Make a promise cancelable
// See: https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
/* Check an error object's instance in catch block
 * const cancellablePromise = makeCancelable(promise object);
 *       cancellablePromise.promise.then(){  }
 *       .catch((err)=>{
 *            if(err instanceof cancellablePromise.PromiseCancelError){
 *                reject from a promise cancel
 *            } else {
 *                reject from a promise error (eg:server)
 *            }})
 */
export const makeCancelable = (promise) => {
  let hasCancelled = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    const operationCancelled = new PromiseCancelError('Operation has been cancelled');
    promise.then(
      val => (hasCancelled ? reject(operationCancelled) : resolve(val)),
      error => (hasCancelled ? reject(operationCancelled) : reject(error)),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCancelled = true;
    },
    PromiseCancelError,
  };
};


const fetchData = ({
  method = 'GET',
  URL,
  data = null,
  contentType = 'application/json',
  accept = '',
  noHeader = false,
  eventData = {},
}) => {
  let headers = {};

  if (!noHeader) {
    headers = {
      ...contentType && { 'Content-Type': contentType },
      ...accept && { Accept: accept },
      Authorization: `Bearer ${Auth.token()}`,
    };
  }
  let config = {
    method,
    headers,
  };

  if (method !== 'GET' && method !== 'DELETE' && data) config = Object.assign({}, config, { body: data });

  eventNotifier.emit(eventType.ApiCallStarted, eventData);
  return fetch(URL, config)
    .then((response) => {
      eventNotifier.emit(eventType.ApiCallEnded, eventData);
      return response;
    })
    .catch((e) => {
      eventNotifier.emit(eventType.ApiCallEnded, eventData);
      console.log(`An error has occurred while calling the API. ${e}`);
    });
};

export default fetchData;
