import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sprite from '../../components/sprite';

const btnStyle = { height: '40px' };

const eventName = 'OnClicked';

class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autocompleteStyle: {
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        display: 'block',
        maxHeight: '0px',
        overflowY: 'scroll',
        borderBottom: '2px groove',
        borderLeft: '2px groove',
        borderRight: '2px groove',
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px',
        top: '25px',
        transition: 'all .3s ease',
        animation: 'opac .3s',
        marginBottom: '30px',
      },
      textVal: this.props.parentVal.text || '',
      optionsData: this.props.optionsData,
      optionsFiltered: this.props.optionsData,
      type: this.props.type,
      config: this.props.config,
      isValid: true,
      loading: !this.props.optionsData,
    };
    this.handleCLick = this.handleCLick.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.handleTextOnchange = this.handleTextOnchange.bind(this);
    this.showOptionsDiv = this.showOptionsDiv.bind(this);
    this.hideOptionsDiv = this.hideOptionsDiv.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  componentWillUnmount() {
    if (this.props.event) this.props.event.off(eventName);
  }

  /*
  * set textbox value based on selected option, hide the dropdown div and send the selected option to parent component
  */
  onSelect(e, option) {
    e.preventDefault();
    this.setState({ textVal: option.text });
    this.validate(option.text);
    this.handleCLick();
    this.props.selectedValue({ ...option, isValid: true });
  }

  getOptions() {
    if (this.state.optionsFiltered && this.state.optionsFiltered.length) {
      return this.state.optionsFiltered.map(option => (
        <li key={option.id}>
          <button className="optionListBtn" type="button" style={btnStyle} onClick={e => this.onSelect(e, option)}>
            {option.text === '' ? 'No Selections Available' : option.text}
          </button>
        </li>
      ));
    }

    if (this.state.optionsFiltered && this.state.optionsFiltered.length === 0) {
      return <li style={{ paddingTop: '5px', paddingLeft: '5px' }}><span>No options found</span></li>;
    }

    return null;
  }

  UNSAFE_componentWillMount() {
    this.hideOptionsDiv();
    if (this.props.event) {
      this.props.event.on(eventName, (type) => {
        if (type !== this.props.type) {
          this.hideOptionsDiv();
        }
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.parentVal !== nextProps.parentVal) {
      this.setState({ textVal: nextProps.parentVal.text || '' });
    }

    if (nextProps.optionsData !== this.props.optionsData) {
      this.setState({
        optionsData: nextProps.optionsData,
        optionsFiltered: nextProps.optionsData,
        loading: false,
      });
    }

    this.setState({
      type: nextProps.type,
      config: nextProps.config,
    });
  }

  handleCLick(e, type) {
    if (this.props.event) {
      this.props.event.emit(eventName, type);
    }
    if (this.state.autocompleteStyle.maxHeight === '0px') {
      if (!this.state.textVal) this.setState(prevState => ({ optionsFiltered: prevState.optionsData }));
      this.showOptionsDiv();
      this.props.divShown(type);
    } else {
      this.hideOptionsDiv();
    }
  }

  handleOnBlur() {
    if (!this.state.isValid && !this.state.config.required) {
      this.hideOptionsDiv();
      this.setState({
        isValid: true,
        textVal: '',
      });
    }
  }

  /*
  * this handler is to handle when user enter some words then options will be filtered based on those words.
  */
  handleTextOnchange(e) {
    const options = this.state.optionsData.filter(u => u.text.toUpperCase().includes(e.target.value.toUpperCase()));
    this.populateOptions(e.target.value, options);
    this.validate(e.target.value, options.length);
    this.props.selectedValue({
      id: null,
      text: e.target.value,
      value: null,
      isValid: false,
    });
    this.setState({ textVal: e.target.value });
  }

  populateOptions(value, options) {
    if (value) this.showOptionsDiv();
    else this.hideOptionsDiv();
    const localOptions = value && options && options.length
      ? options
      : [];
    this.setState({
      optionsFiltered: localOptions,
    });
  }

  /*
  * this handler is to handle field validation based on configuration
  */
  validate(val, hasOptions = this.state.optionsFiltered.length) {
    let isValid = true;
    if ((this.state.config.required && val === '') || (val && !hasOptions)) {
      isValid = false;
    }

    this.setState((prevState) => {
      if (prevState.isValid === isValid) return null;
      return {
        isValid,
      };
    }, () => {
      this.props.isAutocompleteValid({ type: this.state.type, valid: this.state.isValid });
    });
  }

  showOptionsDiv() {
    this.setState((prevState) => {
      const autocompleteStyleTemp = { ...prevState.autocompleteStyle };
      autocompleteStyleTemp.maxHeight = '120px';
      autocompleteStyleTemp.zIndex = '2';
      autocompleteStyleTemp.borderBottom = '1px groove';
      return { autocompleteStyle: autocompleteStyleTemp };
    });
  }

  hideOptionsDiv() {
    this.setState((prevState) => {
      const autocompleteStyleTemp = { ...prevState.autocompleteStyle };
      autocompleteStyleTemp.maxHeight = '0px';
      autocompleteStyleTemp.zIndex = '1';
      autocompleteStyleTemp.borderBottom = '0px';
      return { autocompleteStyle: autocompleteStyleTemp };
    });
  }

  render() {
    const error = this.props.errorMessage;
    const inputStyle = { width: '100%' };
    const inputDivStyle = { position: 'relative', display: 'inline-block' };
    if (this.props.width) {
      inputDivStyle.width = this.props.width;
    }
    if (this.props.width) {
      inputDivStyle.height = this.props.height;
    }
    const iconStyle = {
      position: 'absolute',
      right: '7px',
      cursor: 'pointer',
      background: 'white',
      padding: '1px',
      top: '3px',
    };
    let ulStyle = {
      paddingRight: '0',
      paddingLeft: '0',
      marginTop: '0',
      paddingInlineStart: '1px',
      minHeight: '35px',
    };

    if (this.state.optionsFiltered && this.state.optionsFiltered.length === 0) {
      ulStyle = {
        ...ulStyle,
        marginBottom: 0,
      };
    }

    const spinnerStyle = {
      paddingTop: '10px',
    };

    let inputClass = 'validInput';

    if (!this.props.isDisabled && !this.props.isValid) {
      inputClass = 'invalidInput';
    }
    return (
      <div style={inputDivStyle}>
        <input
          className={inputClass}
          style={inputStyle}
          value={this.state.textVal}
          onChange={e => this.handleTextOnchange(e)}
          onBlur={this.handleOnBlur}
          type="text"
          placeholder={this.state.config.default !== '' && this.state.config.default !== null ? this.state.config.default : 'Enter text...'}
          disabled={this.props.isDisabled}
        />
        {!this.props.isDisabled && (
          <span onClick={e => this.handleCLick(e, this.state.type)} style={iconStyle}>
            <Sprite icon="Pencil" color="black" />
          </span>
        )}
        <div className="w3-white" style={this.state.autocompleteStyle}>
          {this.state.loading && !error && (
            <div className="wrapper" style={spinnerStyle}>
              <div className="cssload-loader" />
            </div>
          )}
          {error ? (
            <p className="w3-center w3-text-black">{error.toString()}</p>
          ) : (
            <ul className="filterDropdownListItem" style={ulStyle}>
              {this.getOptions()}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

Autocomplete.propTypes = {
  optionsData: PropTypes.arrayOf(PropTypes.object),
  width: PropTypes.string,
  height: PropTypes.string,
  errorMessage: PropTypes.objectOf(PropTypes.object),
  selectedValue: PropTypes.func,
  divShown: PropTypes.func,
  parentVal: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  config: PropTypes.shape({
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    default: PropTypes.string,
    caption: PropTypes.string,
    pattern: PropTypes.string,
  }),
  isAutocompleteValid: PropTypes.func,
  isValid: PropTypes.bool,
  event: PropTypes.shape({
    on: PropTypes.func,
    emit: PropTypes.func,
    off: PropTypes.func,
  }),
};

Autocomplete.defaultProps = {
  width: '340px',
  height: '30px',
  errorMessage: {},
  parentVal: {},
  event: null,
  config: {
    default: '',
    caption: '',
    pattern: '',
    name: '',
    required: false,
  },
  selectedValue: () => { },
  divShown: () => { },
  isAutocompleteValid: () => { },
  isValid: true,
  optionsData: [],
};

export default Autocomplete;
