import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FilterList = (props) => {
  if (props.error) {
    return <p className="w3-center w3-text-black">{props.error}</p>;
  }

  const infoBarSelect = {
    position: 'absolute',
    zIndex: 1,
    display: props.show ? 'block' : 'none',
  };

  return (
    <div className={classNames('w3-white w3-col m3 l2', { [props.wrapperClassName]: props.wrapperClassName })} style={{ ...infoBarSelect, ...props.wrapperStyle }}>
      <ul style={props.listStyle} className="filterDropdownListItem">
        {props.children}
      </ul>
    </div>
  );
};

FilterList.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  show: PropTypes.bool.isRequired,
  wrapperStyle: PropTypes.shape({}),
  wrapperClassName: PropTypes.string,
  listStyle: PropTypes.shape({}),
};

FilterList.defaultProps = {
  error: '',
  wrapperStyle: {},
  wrapperClassName: '',
  listStyle: {},
};

export default FilterList;
