import React from 'react';
import PropTypes from 'prop-types';
import NavigationButton from '../../components/navigationButton';

const MenuBar = (props) => {
  const search = window.sessionStorage.getItem('search');
  const url = search
    ? `/requests/list?search=${search}`
    : '/requests/list';
  return (
    <div className="menu">
      <div>
        <NavigationButton
          icon="Back"
          label="List"
          destination={url}
        />
      </div>
      <div>
        {
        props.showEditButton && (
          <NavigationButton
            icon="Edit"
            label="Edit"
            enabled={props.enableEditButton}
            destination={`/requests/edit/2/${props.requestID}`}
          />
        )
      }
      </div>
    </div>
  );
};

MenuBar.propTypes = {
  showEditButton: PropTypes.bool.isRequired,
  enableEditButton: PropTypes.bool.isRequired,
  requestID: PropTypes.string.isRequired,
};

export default MenuBar;
