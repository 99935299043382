import _ from 'lodash';
import { UserManager } from './authConstants';
import BuiltInRoles from './roles';

class Auth {
  static updateUser(user) {
    if (Auth.userAuth && Auth.userAuth.access_token === user.access_token) {
      return Auth.currentGetUserPromise; // The token has not changed, so no need to continue.
    }

    Auth.userAuth = user;
    return Auth.currentGetUserPromise;
  }

  static token() { return Auth.userAuth.access_token; }

  static sub() { return Auth.userAuth.profile.sub; }

  static username() { return Auth.userAuth.profile.email; }

  static usersName() { return Auth.userAuth.profile.name; }

  static email() { return Auth.userAuth.profile.email; }

  static accountId() { return Auth.sub(); }

  static clientProjectId() { return Auth.userAuth.profile.clientProjectId; }

  static clientShortName() { return Auth.userAuth.profile.clientShortName; }

  static clientProjectName() { return Auth.userAuth.profile.clientProjectName; }

  static roles() {
    let roles = Auth.userAuth.profile.role || [];
    if (typeof roles === 'string') { // identity will use a string, not an array for a single role, so ensure this role is into an array to not break logic.
      roles = [roles];
    }
    return roles;
  }

  static userType() {
    // Use the default claim type of "scoutUserType" if nothing is in the configuration
    const claimType = ENV_ENDPOINTS.userTypeClaim === undefined ? 'scoutUserType' : ENV_ENDPOINTS.userTypeClaim;
    return Auth.userAuth.profile[claimType];
  }

  static hasAtLeastOneRole(roles) {
    const upperCaseRoles = roles.map(x => x.toUpperCase());
    const upperCaseUserRoles = Auth.roles().map(x => x.toUpperCase());
    const emptyList = 0;
    const intersection = _.intersection(upperCaseRoles, upperCaseUserRoles);
    const hasRole = intersection.length > emptyList;
    return hasRole;
  }

  static hasRole(role) {
    const toMatch = role.toUpperCase();
    const userRoles = Auth.roles();
    const hasRole = userRoles.map(x => x.toUpperCase()).includes(toMatch);
    return hasRole;
  }

  static isAdmin(admin) {
    if (!Auth.isValidToken()) {
      return false;
    }
    if (!Auth.hasRole(admin)) {
      return false;
    }
    return true;
  }

  static isValidToken() {
    if (!Auth.userAuth) {
      return false;
    }

    const hasAgentRole = Auth.hasRole(BuiltInRoles.Agent);
    if (hasAgentRole) {
      // Accounts with agent roles should not be able to access UIs
      console.warn('Invalid agent token');
      return false;
    }

    return true;
  }

  static loggedIn() {
    if (!Auth.isValidToken()) {
      return false;
    }
    return true;
  }
}

UserManager.events.addUserLoaded(user => Auth.updateUser(user));

export default Auth;
