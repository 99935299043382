import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import ProtectedRoute from '../modules/common/auth/protectedRoute';
import Layout from './layout';

// Global styles
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/w3.css';
import '../styles/base.scss';

// Base Html page amd favicons are added as an import so webpack finds it on crawling the dependency tree
import '../static/favicon.ico';
import '../static/android-chrome-192x192.png';
import '../static/android-chrome-512x512.png';
import '../static/apple-touch-icon.png';
import '../static/browserconfig.xml';
import '../static/favicon-16x16.png';
import '../static/favicon-32x32.png';
import '../static/mstile-150x150.png';
import '../static/safari-pinned-tab.svg';
import '../static/site.webmanifest';

import Index from './index';
import RequestList from './pulseRequests/requestList';
import RequestDetails from './pulseRequests/requestDetails/requestDetails';
import Test from './pages/test';
import NewRequestWizard from './pulseRequests/newRequestWizard/newRequestWizard';
import ChatWindow from './pages/conversationPage';
import SignInCallback from '../modules/common/auth/signInCallback';
import Signout from '../modules/common/auth/signout';
import { AuthorisedRoles } from './auth/pulseAuth';
import Unauthorised from './pages/unauthorised';

ReactDOM.render(
  <BrowserRouter>
    <Layout>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route exact path="/index" component={Index} />
        <Route exact path="/signout" component={Signout} />
        <Route exact path={`/${ENV_ENDPOINTS.signinRedirectPath}`} component={SignInCallback} />
        <ProtectedRoute exact path="/requests/list" component={RequestList} authorisedRoles={AuthorisedRoles} />
        <ProtectedRoute exact path="/requests/list/:requestID" component={RequestList} authorisedRoles={AuthorisedRoles} />
        <ProtectedRoute exact path="/requests/details/:requestID" component={RequestDetails} authorisedRoles={AuthorisedRoles} />
        <ProtectedRoute exact path="/requests/new/:step" component={NewRequestWizard} authorisedRoles={AuthorisedRoles} />
        <ProtectedRoute exact path="/requests/edit/:step/:editRequestID" component={NewRequestWizard} authorisedRoles={AuthorisedRoles} />
        <ProtectedRoute exact path="/conversation/:conversationId" component={ChatWindow} />
        <Route exact path="/test" component={Test} />
        <Route exact path="/unauthorised" component={Unauthorised} />
        <Route path="*" component={Index} />
      </Switch>
    </Layout>
  </BrowserRouter>,
  document.getElementById('root'),
);
