import Auth from '../../common/auth/auth';
import fetchData from '../../common/api/fetchData';

class AttachmentDataService {
  static getAppBaseUrl() {
    return ENV_ENDPOINTS.attachmentEndpoint + Auth.clientShortName();
  }

  static GetAttachmentData(attachedItem) {
    return new Promise((resolve, reject) => {
      fetchData({
        URL: `${AttachmentDataService.getAppBaseUrl()}/attachments/attachedto/${attachedItem}`,
      })
        .then((response) => {
          resolve(response.json());
        }).catch((err) => {
          reject(err);
        });
    });
  }

  static PutAttachmentMeta(attachmentDto) {
    return new Promise((resolve, reject) => {
      fetchData({
        method: 'PUT',
        URL: `${AttachmentDataService.getAppBaseUrl()}/attachments/${attachmentDto.Id}`,
        data: JSON.stringify(attachmentDto),
      })
        .then((response) => {
          const date = new Date();
          const attachment = attachmentDto;
          attachment.VersionDate = date.toUTCString();
          attachment.CreatedDate = date.toUTCString();
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  static PostAttachmentMeta(attachmentDto) {
    return new Promise((resolve, reject) => {
      fetchData({
        method: 'POST',
        URL: `${AttachmentDataService.getAppBaseUrl()}/attachments/${attachmentDto.Id}`,
        data: JSON.stringify(attachmentDto),
      })
        .then((response) => {
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  static PostAttachmentFile(id, fileRequestData) {
    return new Promise((resolve, reject) => {
      fetchData({
        method: 'POST',
        URL: `${AttachmentDataService.getAppBaseUrl()}/files/${id}`,
        data: fileRequestData,
        contentType: null,
      })
        .then((response) => {
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
    });
  }
}

export default AttachmentDataService;
