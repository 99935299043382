import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import CommonFetch from '../../utils/commonFetch';
import Auth from '../../auth/pulseAuth';
import Header from '../../components/header';
import ProgressBar from './progressBar';
import Step1 from './steps/step1';
import Step2 from './steps/step2';
import Step3 from './steps/step3';
import Step4 from './steps/step4';
import Step5 from './steps/step5';
import RequestTypes from '../../../modules/ScoutModels/models/requestTypes';
import Request from '../../../modules/ScoutModels/models/request';
import Toaster, { TOAST_ERROR } from '../../../modules/common/toaster/toaster';

import AttachmentDataService from '../../../modules/attachment/Attachment/attachmentDataService';
import RequestWizard from '../../models/requestWizard';
import UserDetails from '../../utils/userDetails';
import FieldResourceAccess from '../../models/fieldResourceAccess';

class NewRequestWizard extends Component {
  static showErrorToast(isEdit, apiResponse) {
    let message = `${isEdit ? 'Update' : 'Create'} Request failed. ${apiResponse}`;
    if (apiResponse.message.startsWith('400')) {
      message = 'There was a problem with your request. Please fix any errors and try again. ';
    }
    Toaster.pop(message, TOAST_ERROR);
  }

  constructor(props) {
    super(props);

    this.requestWizard = null;
    this.userDetails = null;

    this.state = {
      request: null,
      requestTypes: null,
      loadingEditScreen: false,
      numberOfSteps: 4, // The usual number of steps.
    };

    this.getStep = this.getStep.bind(this);
    this.setRequestType = this.setRequestType.bind(this);
    this.saveStepTwo = this.saveStepTwo.bind(this);
    this.saveStepThree = this.saveStepThree.bind(this);
    this.saveStepFour = this.saveStepFour.bind(this);
    this.loadRequest = this.loadRequest.bind(this);

    this.originalRequestData = null;
  }

  async componentDidMount() {
    try {
      this.requestWizard = new RequestWizard(!!this.props.match.params.editRequestID);

      const [requestTypesData, userDetailsData, userDefaultsData] = await Promise.all([
        CommonFetch.getFieldsConfiguration(),
        CommonFetch.getUserDetails(),
        CommonFetch.getUserDefaults(),
      ]);

      const requestTypes = new RequestTypes(requestTypesData, FieldResourceAccess);
      this.userDetails = new UserDetails(userDetailsData, userDefaultsData);

      // Request types are not filtered when editing to allow user to edit any request types.
      // This is a temporary solution until a better approach is determined.  See PBI 24690.
      if (!this.IsEdit) {
        // filter Configuration Data by the User's Roles

        // Roles from the user token. Legacy
        let userRoles = Auth.roles() || [];

        // Get any values in the scoutUserType claim.
        let scoutUserType = Auth.userType() || [];

        // Get the scoutUserType claim, this should be a string or an array

        // force the claims to be arrays - it's what the code expects
        if (typeof userRoles === 'string') {
          userRoles = [userRoles];
        }

        if (typeof scoutUserType === 'string') {
          scoutUserType = [scoutUserType];
        }

        const unionOfClaims = [...userRoles, ...scoutUserType];

        requestTypes.ApplyRoleFilter(unionOfClaims);
      }

      this.setState({
        requestTypes,
      });

      // if the page refreshed while on step 2-5 then it will redirect to step 1 (or step 2 if it is Edit)
      if (!this.IsEdit) {
        this.props.history.replace('/requests/new/1');
        const request = new Request();
        this.setState({ request });
      } else {
        this.props.history.replace(`/requests/edit/2/${this.props.match.params.editRequestID}`);
        this.loadRequest();
      }
    } catch (e) {
      console.error(e);
      Toaster.pop('There was an error getting fields configuration.', TOAST_ERROR);
    }
  }

  get IsEdit() {
    return !!this.props.match.params.editRequestID;
  }

  get EditRequestID() {
    return this.props.match.params.editRequestID;
  }

  getStep1() {
    if (this.state.requestTypes && this.state.request) {
      return (
        <Step1
          setRequestType={this.setRequestType}
          selectedRequestType={this.state.request.serviceType}
          requestTypes={this.state.requestTypes.filteredRequestTypes}
        />
      );
    }
    return '';
  }

  getStep2() {
    if (this.state.request && this.state.request.serviceType) {
      return (
        <Step2
          request={this.state.request.Clone()}
          isEdit={this.IsEdit}
          saveRequest={this.saveStepTwo}
          editRequestID={this.props.match.params.editRequestID}
        />
      );
    }
    return '';
  }

  getStep3() {
    if (this.state.request && this.state.request.serviceType && this.userDetails) {
      return (
        <Step3
          request={this.state.request.Clone()}
          saveRequest={this.saveStepThree}
          isEdit={this.IsEdit}
          editRequestID={this.props.match.params.editRequestID}
          requestWizard={this.requestWizard}
          userDetails={this.userDetails}
        />
      );
    }
    return '';
  }

  getStep4() {
    if (this.state.request && this.state.request.serviceType && this.userDetails) {
      return (
        <Step4
          request={this.state.request.Clone()}
          saveRequest={this.saveStepFour}
          isEdit={this.IsEdit}
          editRequestID={this.props.match.params.editRequestID}
          userDetails={this.userDetails}
          requestWizard={this.requestWizard}
        />
      );
    }
    return '';
  }

  getStep5() {
    if (this.state.request && this.state.request.serviceType) {
      return (
        <Step5
          request={this.state.request}
          isEdit={this.IsEdit}
          hasBeenEdited={this.IsEdit && !this.originalRequestData.IsEqual(this.state.request)}
          requestID={this.state.request.id}
        />
      );
    }
    return '';
  }

  getStep() {
    switch (this.props.match.params.step) {
      case '1':
        return this.getStep1();
      case '2':
        return this.getStep2();
      case '3':
        return this.getStep3();
      case '4':
        return this.getStep4();
      case '5':
        return this.getStep5();
      default:
        return '';
    }
  }

  setRequestType(selectedRequestType) {
    if (selectedRequestType) {
      const request = new Request();
      request.serviceType = selectedRequestType;

      request.requestType = this.state.requestTypes.GetRequestType(selectedRequestType);

      request.populateDefaultValues(this.userDetails);
      this.requestWizard = new RequestWizard(request, this.IsEdit, this.EditRequestID);
      this.requestWizard.RequestType = request.requestType;
      this.updateNumberOfSteps();

      this.setState({ request });
    }
  }

  async updateNumberOfSteps() {
    const numberOfSteps = await this.requestWizard.numberOfSteps();
    this.setState({ numberOfSteps });
  }

  saveStepTwo(editedRequest) {
    this.setState({ request: editedRequest });
  }

  saveStepThree(editedRequest, submit = false) {
    this.setState({ request: editedRequest }, () => {
      if (submit) {
        if (this.state.request.IsValid) {
          // Execute the list of deferred File Commit Promises
          this.saveRequest();
        } else {
          // WIP: Need to do something about redirecting to the step with errors.
          //      this should be an edge case.
          console.warn('Request contains validation errors:');
          console.log(this.state.request.validationErrors);
        }
      }
    });
  }

  saveStepFour(editedRequest, submit = false) {
    this.setState({ request: editedRequest }, () => {
      if (submit) {
        if (this.state.request.IsValid) {
          // Execute the list of deferred File Commit Promises
          this.saveRequest();
        } else {
          // WIP: Need to do something about redirecting to the step with errors.
          //      this should be an edge case.
          console.warn('Request contains validation errors:');
          console.log(this.state.request.validationErrors);
        }
      }
    });
  }

  async saveRequest() {
    if (this.IsEdit) {
      if (!this.state.request.IsEqual(this.originalRequestData)) {
        try {
          await CommonFetch.updateRequest(this.state.request);
          this.props.history.replace(`/requests/edit/5/${this.props.match.params.editRequestID}`);
        } catch (error) {
          // WIP: Need to do something about redirecting to the step with errors.
          //      this should be an edge case.
          console.error(error);
          NewRequestWizard.showErrorToast(this.IsEdit, error);
        }
      } else {
        this.props.history.replace(`/requests/edit/5/${this.props.match.params.editRequestID}`);
      }
    } else {
      try {
        this.state.request.createdDate = new Date().toISOString();
        const requestNumber = await CommonFetch.createRequest(this.state.request);
        this.originalRequestData = this.state.request;
        this.originalRequestData.requestNumber = requestNumber;
        this.setState({
          request: this.originalRequestData.Clone(),
        }, () => {
          this.props.history.replace('/requests/new/5');
        });
      } catch (error) {
        // WIP: Need to do something about redirecting to the step with errors.
        //      this should be an edge case.
        console.error(error);
        NewRequestWizard.showErrorToast(this.IsEdit, error);
      }
    }
    // Save the attachments
    this.state.request.attachments.forEach((item) => {
      if (item.VersionDate === '') {
        if (item.CreatedDate === '') {
          AttachmentDataService.PutAttachmentMeta(item);
        } else {
          AttachmentDataService.PostAttachmentMeta(item);
        }
      }
    });
  }

  async loadRequest() {
    // if it comes from Edit Button in Request Details
    this.setState({ loadingEditScreen: true });
    try {
      const data = await CommonFetch.getRequestDetails(this.props.match.params.editRequestID);
      this.setState({ loadingEditScreen: false });

      this.originalRequestData = new Request(data);
      if (this.props.match.params.editRequestID && !this.originalRequestData.ShouldAllowEditRequest) {
        this.props.history.replace(`/requests/details/${this.props.match.params.editRequestID}`);
        return;
      }

      this.originalRequestData.requestType = this.state.requestTypes.GetRequestType(this.originalRequestData.serviceType);
      const request = this.originalRequestData.Clone();
      this.requestWizard = new RequestWizard(request, this.IsEdit, this.EditRequestID);
      this.requestWizard.RequestType = request.requestType;
      this.updateNumberOfSteps();

      // auto fill step2-5 form
      this.setState({
        request,
      });
    } catch (error) {
      console.error(error);
      Toaster.pop(`Error on loading Service Request details. Request ID: ${this.props.match.params.editRequestID}`, TOAST_ERROR);
    }
  }

  render() {
    return (
      <div className="NewRequestWizard w3-center">
        <Header Title="Scout" />
        <ProgressBar currentProgress={this.props.match.params.step} numberOfSteps={this.state.numberOfSteps} />
        <div className="stepcontent w3-content w3-left-align">
          {this.getStep()}
          {this.state.loadingEditScreen && (
            <div className="wrapper">
              <div className="cssload-loader" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

NewRequestWizard.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      editRequestID: PropTypes.string,
      step: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    replace: PropTypes.func,
  }).isRequired,
};

NewRequestWizard.defaultProps = {
  match: {
    params: {
      step: '1',
      editRequestID: null,
    },
  },
};

export default withRouter(NewRequestWizard);
