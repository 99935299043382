import React from 'react';

/**
 *  @param {String} searchterm
 *  @param {String} text
 *  @param {Object} fontClass { default,over }
 *  @param {Boolean} mouseOver
 *  @return {String || React elements}
 *
 *  If search term is found in given text, it creates span element with the given text , background highlighted and return it eg( <span style="somestyle">text1</span> )
 *  otherwise it simply return the text
 *
 *  sample usage:
 *  value = highlightText(searchterm,text);
 *  <div> { value } </div>
 *
 *  we might have to support for input text in the futrue. in that case, the target input's wrapper needs to be posiitoned with relative , background transparent and
 *  elements returned from this function needs to be wrapped with some element positioned absolute and sitting at the same position as the target input field
 */

const highlightText = (searchTerm, text, fontClass = { default: '', over: '' }, mouseOver = false) => {
  let stringArray = [];

  let found = false;

  let results = null;

  if (searchTerm) {
    try {
      const reg = new RegExp(searchTerm, 'gi');

      results = reg.exec(text);
      while (results !== null) {
        stringArray.push({ start: results.index, end: results.index + results[0].length });
        found = true;
        results = reg.exec(text);
      }
    } catch (e) {
      console.log(e);
    }
  }

  if (!found) {
    stringArray = text;
  }

  if (found) {
    let tempArray = [];

    const temp = stringArray;

    const chars = text.split('');

    tempArray = chars.map((char, idx) => {
      let className = '';
      if (mouseOver) className = fontClass.over;
      else className = fontClass.default;
      /* (idx + char) is valid for unique key, can not be duplicated */
      // eslint-disable-next-line react/no-array-index-key
      return (
        <span className={className} key={idx.toString() + char}>
          {char}
        </span>
      );
    });

    for (let i = 0; i < temp.length; i++) {
      const { start, end } = temp[i];
      for (let j = start; j < end; j++) {
        tempArray[j] = (
          <span key={j + chars[j]} className="highlightedText">
            {chars[j]}
          </span>
        );
      }
    }
    stringArray = tempArray;
  }

  return stringArray;
};

export default highlightText;
