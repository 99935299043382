import Field from './field';

class SearchField extends Field {
  constructor(field, searchFunction) {
    super(field);
    this.getFunction = searchFunction || (() => null);
  }

  async IsValid(value) {
    if (!this.shown) { return true; }
    if (!super.IsValid(value)) { return false; }

    const result = await this.getFunction(value);
    return !!result;
  }

  Clone() {
    const clone = new SearchField();
    super.Clone(clone);
    clone.getFunction = this.getFunction;
    return clone;
  }
}

export default SearchField;
