const MinutesInHour = 60;
const HoursInDay = 24;
const MilliSecondsInMinute = 1000;
const DaysInWeek = 7;
const AboutSixMonths = 180; /* If more than 180 days passed. we show a year at the end of caption  */
const MinuteInMilliseconds = MinutesInHour * MilliSecondsInMinute;
const OneMinute = 1;
const AnHour = 1;
const divideByTwo = 2;

export const DateTimeUnit = Object.freeze({
  MinutesInHour,
  HoursInDay,
  MilliSecondsInMinute,
  DaysInWeek,
  AboutSixMonths,
  MinuteInMilliseconds,
  DayInMilliseconds: MinutesInHour * HoursInDay * MinuteInMilliseconds,
});

export const DaysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

class TimeDisplayRule {
  constructor(createdDate, loading = false, acceleration = 1, todayDate = new Date(Date.now())) {
    this.createdDate = createdDate;
    this.loading = loading;
    this.acceleration = acceleration;
    this.todayDate = todayDate;
  }

  get Caption() {
    const createdDate = new Date(this.createdDate);
    const minutesAgo = ((this.todayDate.valueOf() - createdDate.valueOf()) / DateTimeUnit.MinuteInMilliseconds) * this.acceleration;
    const hoursAgo = minutesAgo / DateTimeUnit.MinutesInHour;
    const daysAgo = Math.floor((this.todayDate.valueOf() - createdDate.valueOf()) / DateTimeUnit.DayInMilliseconds);
    let caption = '';
    if (this.loading) {
      caption = 'Sending';
    } else if (minutesAgo < OneMinute) {
      caption = 'Just now';
    } else if (minutesAgo < OneMinute + 1) {
      caption = '1 minute ago';
    } else if (minutesAgo < DateTimeUnit.MinutesInHour) {
      const min = Math.floor(minutesAgo);
      caption = `${min} minutes ago`;
    } else if (hoursAgo < AnHour + 1) {
      caption = 'An hour ago';
    } else if (hoursAgo < (DateTimeUnit.HoursInDay / divideByTwo) + 1) {
      const hr = Math.floor(hoursAgo);
      caption = `${hr} hours ago`;
    } else if (hoursAgo < DateTimeUnit.HoursInDay + 1 && this.todayDate === createdDate) {
      const hr = Math.floor(hoursAgo);
      caption = `${hr} hours ago`;
    } else if (daysAgo === 1) {
      caption = 'Yesterday';
    } else if (daysAgo < DateTimeUnit.DaysInWeek) {
      caption = DaysOfWeek[createdDate.getDay()];
    } else if (daysAgo < DateTimeUnit.AboutSixMonths) {
      caption = `${Months[createdDate.getMonth()]} ${createdDate.getDate()}`;
    } else {
      caption = `${Months[createdDate.getMonth()]} ${createdDate.getDate()} ${createdDate.getFullYear()}`;
    }
    return caption;
  }
}

export default TimeDisplayRule;
