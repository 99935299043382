import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from './tooltip';
import '../../style.scss';

const defaultStyle = {
  display: 'flex',
  height: '50px',
  justifyContent: 'center',
  flexDirection: 'column',
  outlineOffset: '-10px',
  textAlign: 'center',
};

const TrashCan = props => (
  <div
    className={props.className}
    style={defaultStyle}
  >
    {props.trashIcon}
  </div>
);

TrashCan.propTypes = {
  className: PropTypes.string,
  trashIcon: PropTypes.node,
};

TrashCan.defaultProps = {
  className: '',
  trashIcon: <i style={{ fontSize: '20px' }} className="fa fa-trash" />,
};

export default Tooltip(TrashCan);
