import React from 'react';
import PropTypes from 'prop-types';

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.progress >= 0) {
      this.setState({
        progress: nextProps.progress,
      });
    }
  }

  render() {
    return (
      <div style={{ padding: '3px' }} className="w3-light-grey">
        {this.state.progress > 0 && <div className="w3-container progress-bar" style={{ transition: 'width 500ms', height: '5px', width: `${this.state.progress}%` }} />}
      </div>
    );
  }
}

ProgressBar.propTypes = {
  // eslint isn't picking up progress being used in UNSAFE_componentWillReceiveProps.
  // eslint-disable-next-line react/no-unused-prop-types
  progress: PropTypes.number,
};

ProgressBar.defaultProps = {
  progress: 0,
};

export default ProgressBar;
