import { withRouter } from 'react-router';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FilterDropDown from './filters/filterDropDown';
import FilterSearchBox from './filters/filterSearchBox';
import RequestStatus, { Status } from '../../modules/ScoutModels/models/requestStatus';
import Auth from '../auth/pulseAuth';
import CommonFetch from '../utils/commonFetch';
import NavigationButton from '../pulseRequests/components/navigationButton';
import Filter, { FilterType, DepartmentFilterStatus, RequestorFilterStatus } from '../../modules/ScoutModels/models/filter';
import Requestors from '../../modules/ScoutModels/models/requestors';
import Requestor from '../../modules/ScoutModels/models/requestor';
import FilterListItem from './filters/filterListItem';


/* The values of width and height are approved by product owner */
const filterListWrapperStyle = {
  padding: '5px',
  width: '400px',
  border: '1px solid #eee',
  height: '500px',
  marginTop: '5px',
};

const filterListStyle = {
  height: '100%',
  overflow: 'auto',
  margin: 0,
};

class InfoBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilter: null,
      filter: new Filter(),
      searchValue: '',
      departments: [],
      requestors: new Requestors(),
      error: '',
    };
    this.toggleFilterList = this.toggleFilterList.bind(this);
    this.onSearchTermChanged = this.onSearchTermChanged.bind(this);
    this.onSelectStatus = this.onSelectStatus.bind(this);
    this.onSelectDepartment = this.onSelectDepartment.bind(this);
    this.onSelectRequestor = this.onSelectRequestor.bind(this);
    this.updateStateAndSearch = this.updateStateAndSearch.bind(this);

    this.filter = this.filter.bind(this);
  }

  componentWillUnmount() {
    /* this is how react-router removes listener */
    if (this.hashChangeListener) this.hashChangeListener();
  }

  onSelectStatus(option) {
    this.state.filter.SetStatus(option.value);
    this.updateStateAndSearch();
  }

  onSelectDepartment(option) {
    this.state.filter.SetDepartment(option.value);
    this.updateStateAndSearch();
  }

  onSelectRequestor(option) {
    // If an older version (v.60 and older) of the bridge api is runing, then we won't have Id's (Key) for the
    // requestors, so filter via the requestors name (value) instead.
    this.state.filter.SetRequestor(option.key ? option.key : option.value);
    this.updateStateAndSearch();
  }

  onSearchTermChanged(val) {
    const keyword = val.substring(this.state.filter.SearchTerm.length);
    this.state.filter.SetKeyword(keyword);
    this.setState(prevState => ({
      filter: prevState.filter,
      searchValue: val,
    }), () => {
      this.props.onSearchTermChanged(val);
    });
  }

  getSelectedRequestorValue() {
    // If an older version (v.60 and older) of the bridge api is still running, then the requestor filter could
    // be the requestor name not it's Id. So if it can't be found by it's Id, Try finding it by its name.
    let selectedRequestor = this.state.requestors.FindById(this.state.filter.requestor);
    if (!selectedRequestor) {
      selectedRequestor = this.state.requestors.FindByName(this.state.filter.requestor);
    }

    return selectedRequestor
      ? selectedRequestor.name
      : RequestorFilterStatus.Any;
  }

  UNSAFE_componentWillMount() {
    this.hashChangeListener = this.props.history.listen(() => {
      this.handleUrlChange();
    });

    if (Auth.isAdmin()) {
      Promise.all([CommonFetch.getRequestorsList(), CommonFetch.getFilterDepartments()])
        .then(([requestors, departments]) => {
          requestors.unshift(new Requestor({ name: 'Any', id: 'Any' }));
          departments.unshift('Any');
          this.setState({
            requestors: new Requestors(requestors),
            departments,
          }, () => {
            this.handleUrlChange();
          });
        });
    } else {
      this.handleUrlChange();
    }
  }

  updateStateAndSearch() {
    this.setState(prevState => ({
      selectedFilter: null,
      filter: prevState.filter,
      searchValue: prevState.filter.SearchTerm,
    }), this.filter);
  }

  handleUrlChange() {
    const filter = this.props.history.location.search.substring('?search='.length);
    let searchTerm = '';
    searchTerm = Filter.DecodeUri(filter);
    this.state.filter.ReAssign(searchTerm);
    this.setState(prevState => ({
      filter: prevState.filter,
      searchValue: searchTerm,
    }));
  }

  toggleFilterList(type) {
    this.setState(prevState => ({ selectedFilter: type === prevState.selectedFilter ? null : type }));
  }

  filter() {
    this.state.filter.ReAssign(this.state.searchValue);
    this.setState(prevState => ({
      filter: prevState.filter,
      searchValue: prevState.filter.SearchTerm,
    }), () => {
      const encodedUri = Filter.EncodeUri(this.state.searchValue);
      this.props.history.push(Filter.Url(encodedUri));
      this.props.onSearchClick(encodedUri);
    });
  }

  render() {
    return (
      <div className="menu filter-menu">
        <div className="filter-select">
          <table className="collapse">
            <tbody style={{ verticalAlign: 'top' }}>
              <tr>
                <td>
                  <span className="w3-left padRight-16 padleft-16">Status</span>
                </td>
                <td>
                  {
                    Auth.isAdmin()
                      ? <span className="w3-left padRight-16">Department</span>
                      : <br />
                  }
                </td>
                <td>
                  {
                    Auth.isAdmin()
                      ? <span className="w3-left padRight-16">Requestor</span>
                      : <br />
                  }
                </td>
              </tr>
              <tr>
                <td>
                  <span className="w3-left padRight-16 padleft-16">
                    <FilterDropDown
                      filterListWrapperStyle={{ ...filterListWrapperStyle, height: '260px' }}
                      filterListWrapperClassName="filter-select-list"
                      filterListStyle={filterListStyle}
                      onSelect={this.onSelectStatus}
                      selectedValue={Filter.ValidateFilterType(this.state.filter.status, Object.keys(Status))
                        || Status.Any}
                      toggleFilterList={this.toggleFilterList}
                      showOpt={this.state.selectedFilter === FilterType.Status}
                      type={FilterType.Status}
                      options={RequestStatus.DisplayNames().map(x => new FilterListItem(x, x))}
                      error={this.state.error}
                    />
                  </span>
                </td>
                <td>
                  {Auth.isAdmin()
                    ? (
                      <span className="w3-left padRight-16">
                        <FilterDropDown
                          filterListWrapperStyle={filterListWrapperStyle}
                          filterListWrapperClassName="filter-select-list"
                          filterListStyle={filterListStyle}
                          onSelect={this.onSelectDepartment}
                          selectedValue={Filter.ValidateFilterType(this.state.filter.department, this.state.departments)
                            || DepartmentFilterStatus.Any}
                          toggleFilterList={this.toggleFilterList}
                          showOpt={this.state.selectedFilter === FilterType.Department}
                          type={FilterType.Department}
                          options={this.state.departments.map(x => new FilterListItem(x, x))}
                          error={this.state.error}
                        />
                      </span>
                    ) : (
                      <br />
                    )}
                </td>
                <td>
                  <span className="w3-left padRight-16">
                    {Auth.isAdmin()
                      ? (
                        <span className="w3-left padRight-16">
                          <FilterDropDown
                            filterListWrapperStyle={filterListWrapperStyle}
                            filterListWrapperClassName="filter-select-list"
                            filterListStyle={filterListStyle}
                            onSelect={this.onSelectRequestor}
                            selectedValue={this.getSelectedRequestorValue()}
                            toggleFilterList={this.toggleFilterList}
                            showOpt={this.state.selectedFilter === FilterType.Requestor}
                            type={FilterType.Requestor}
                            options={this.state.requestors.requestors.map(x => new FilterListItem(x.id, x.name))}
                            error={this.state.error}
                          />
                        </span>
                      ) : (
                        <br />
                      )}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="filter-search">
          <FilterSearchBox onSearchClick={this.filter} searchTerm={this.state.searchValue} onSearchTermChanged={this.onSearchTermChanged} />
          <NavigationButton icon="Add" label="Add" destination="/requests/new/1" />
        </div>
      </div>
    );
  }
}

InfoBar.propTypes = {
  onSearchTermChanged: PropTypes.func,
  onSearchClick: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    push: PropTypes.func,
    listen: PropTypes.func,
  }).isRequired,
};

InfoBar.defaultProps = {
  onSearchTermChanged: () => { },
};

export default withRouter(InfoBar);
