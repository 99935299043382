import { GetEncryptionType } from './EncryptionType';

export default class AttachmentFileDetails {
  constructor(fileDetails) {
    const localFileDetails = fileDetails || {};
    this.Uri = localFileDetails.Uri || '';
    this.UriExpiry = localFileDetails.UriExpiry || null;
    this.EncryptionKey = localFileDetails.EncryptionKey || '';
    this.EncryptionType = GetEncryptionType(localFileDetails.EncryptionType);
    this.Size = localFileDetails.Size || 0;
  }

  Clone() {
    const clone = new AttachmentFileDetails();

    clone.Uri = this.Uri;
    clone.UriExpiry = this.UriExpiry;
    clone.EncryptionKey = this.EncryptionKey;
    clone.EncryptionType = this.EncryptionType;
    clone.Size = this.Size;

    return clone;
  }
}
