import React from 'react';
import PropTypes from 'prop-types';
import Sprite from '../../../components/sprite';
import Request from '../../../../modules/ScoutModels/models/request';
import { StatusCaption } from '../../../../modules/ScoutModels/models/requestStatus';

const paddingStyle = {
  padding: '10px',
  margin: '10px 0px',
  display: 'flex',
  justifyContent: 'space-between',
};

const Status = props => (
  <div className="w3-row panel-content" style={paddingStyle}>
    <div style={{ flex: 1 }}>
      <Sprite icon="Document" color="black" />
      <span className="padLeft-6">{props.requestData.requestNumber}</span>
    </div>
    <div>
      <Sprite icon="Calendar" color="black" />
      <span className="padLeft-6">{props.requestData.DisplayCreatedDate}</span>
    </div>
    <div className="request-status-row" style={{ flex: 1 }}>
      <div className={props.requestData.status.Status}>
        <Sprite icon={StatusCaption[props.requestData.status.Status].replace(/ /g, '')} />
        <span className="padLeft-6">{StatusCaption[props.requestData.status.Status]}</span>
      </div>
    </div>
  </div>
);

Status.propTypes = {
  requestData: PropTypes.instanceOf(Request).isRequired,
};

export default Status;
