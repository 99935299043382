import React from 'react';
import PropTypes from 'prop-types';

import RequiredFieldIndicator from '../../../components/requiredFieldIndicator';

const labelStyle = { display: 'inline-block', minWidth: '100px', paddingRight: '5px' };

const FormLabel = props => (
  <label style={{ ...labelStyle, ...props.style }} htmlFor={props.htmlFor}>
    <RequiredFieldIndicator isRequired={props.required}>
      {props.caption}
      :
    </RequiredFieldIndicator>
  </label>
);

FormLabel.propTypes = {
  caption: PropTypes.string.isRequired,
  required: PropTypes.bool,
  style: PropTypes.shape({}),
  htmlFor: PropTypes.string,
};

FormLabel.defaultProps = {
  required: false,
  style: {},
  htmlFor: null,
};

export default FormLabel;
