// Not putting this through eslint, this is auto generated
// TODO - need to work out how to remove/replace the typescript stuff or getting it working with webpack (maybe thorugh an enforce: "pre" step?) maintaining 2 different techs is unmaintainable.
/* eslint-disable */

const __extends = (this && this.__extends) || function (d, b) {
  for (const p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
  function __() { this.constructor = d; }
  d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
const React = require('react');
const age_1 = require('./age');

const Status = (function (_super) {
  __extends(Status, _super);
  function Status() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Status.prototype.render = function () {
    const statusDivHeight = 40;
    const style = {
      position: 'relative',
      display: 'table',
      height: statusDivHeight,
      padding: '0px 5px 0px 80px',
      border: '5px 0px 5px 0px',
      boxSizing: 'border-box',
      width: '100%',
    };
    const statusIconDivStyle = {
      display: 'table-cell',
      verticalAlign: 'middle',
      width: '1px',
      color: 'silver',
    };
    const nameDivStyle = {
      paddingLeft: 20,
      display: 'table-cell',
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
      width: '1px',
    };
    const ageDivStyle = {
      display: 'table-cell',
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
      textAlign: 'right',
      paddingLeft: '10px',
    };
    const textDivStyle = {
      paddingLeft: 20,
      display: 'table-cell',
      verticalAlign: 'middle',
    };
    return (<div className="Status" style={style}>
      <div style={statusIconDivStyle}>
        <i className="fa fa-bolt fa-2x" aria-hidden="true" />
      </div>
      <div className="StatusName" style={nameDivStyle}>
        {this.props.name}
      </div>
      <div className="StatusText" style={textDivStyle}>
        {this.props.text}
      </div>
      <div className="StatusAge" style={ageDivStyle}>
        <age_1.default created={this.props.created} unsent={this.props.unsent} />
      </div>

            </div>);
  };
  return Status;
}(React.Component));

Status.defaultProps = {
  name: '', text: 'Lorem ipsum', created: new Date(Date.now()), unsent: false,
};

Object.defineProperty(exports, '__esModule', { value: true });
exports.default = Status;
