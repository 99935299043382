import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/ScoutLogo.svg';
import UserContextMenu from '../../modules/common/userContextMenu/userContextMenu';
import AccountMenuItem from '../../modules/common/userContextMenu/accountMenuItem';
import LogoutMenuItem from '../../modules/common/userContextMenu/logoutMenuItem';
import Auth from '../auth/pulseAuth';
import User from '../models/user';

// Renders the header with title passed in.
// The outer div is of type header which positions itself using a flex layout control within the page.
// The second level div uses w3.css layout container, with a style to enforce that it is the full height of header.
// The title is in a span for addressing from CSS so nesting of divs can control layout and not text style.
// WIP: The gravatar for the signed in user goes where the text lists TODO:

const containerStyle = {
  height: '100%',
  zIndex: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const linkStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '9px 0',
};

const Header = () => {
  const [departmentName, setDepartmentName] = useState('');

  useEffect(() => {
    User.departmentName().then(data => setDepartmentName(data));
  });

  return (
    <div className="header">
      <div style={containerStyle}>
        <Link style={linkStyle} to="/"><img style={{ marginBottom: 0 }} src={Logo} alt="Scout Logo" /></Link>
        {Auth.loggedIn() && (
          <UserContextMenu>
            <AccountMenuItem descriptions={!departmentName ? undefined : [departmentName]} />
            <Link to="/signout">
              <LogoutMenuItem />
            </Link>
          </UserContextMenu>
        )}
      </div>
    </div>
  );
};

export default Header;
