import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

const locale = window.navigator.userLanguage || window.navigator.language;

moment.locale(locale);

const suportsNativeDateInput = () => {
  const test = document.createElement('input');

  try {
    test.type = 'date';
  } catch (e) {
    // Let any exceptions that occur from this fall through
  }

  // browsers that support date inputs natively allows the input type to be set to date
  return test.type === 'date';
};

class DateTime extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isValid: true };

    this.onChangeRaw = this.onChangeRaw.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    let isValid = false;
    if (moment(this.props.value, moment.ISO_8601, true).isValid()) {
      isValid = this.validateDateRange(moment(this.props.value, moment.ISO_8601, true).local());
    }
    this.props.valueChanged(this.props.value, isValid, true);
    this.setState({ isValid });
  }

  onChangeRaw(event) {
    const isValid = event.target.value === '';
    this.props.valueChanged(event.target.value, isValid);
    this.setState({ isValid });
  }

  onChange(selectedDate) {
    let isValid = false;
    let dateString = '';
    if (!selectedDate) {
      isValid = true;
    } else if (selectedDate.isValid()) {
      isValid = this.validateDateRange(selectedDate);
      // The react-datepicker component won't let us have a Date picker and supply a time
      // so we'll append the time once we have a valid date.
      dateString = `${selectedDate.format('YYYY-MM-DD')} 00:00:00`;
    }
    this.props.valueChanged(dateString, isValid);
    this.setState({
      isValid,
    });
  }

  get MinDate() {
    let minDate = '';
    if (this.props.allowPastDates) {
      const selectedDate = moment(this.props.value, moment.ISO_8601, true);
      if (selectedDate.isValid()) {
        const selectedDateLocal = selectedDate.local();
        const now = moment();
        minDate = selectedDateLocal.isBefore(now) ? selectedDateLocal : now;
      }
    } else {
      minDate = moment();
    }
    return minDate;
  }

  validateDateRange(date) {
    if (this.props.allowPastDates) { return true; }
    return date.diff(moment(), 'days') >= 0;
  }

  GetOptionalProps() {
    const optionalProps = {};
    if (moment(this.props.value, moment.ISO_8601, true).isValid()) {
      optionalProps.selected = moment(this.props.value, moment.ISO_8601, true).local();
    } else {
      optionalProps.value = this.props.value;
    }
    return optionalProps;
  }

  render() {
    let className = 'preferred-date';
    if (this.props.touched && (!this.state.isValid || !this.props.isValid)) {
      className = `${className} invalid`;
    }

    if (suportsNativeDateInput()) {
      return (
        <div className={className}>
          <input
            onChange={event => this.onChange(moment(event.target.value))}
            type="date"
            min={this.MinDate.format('YYYY-MM-DD')}
            value={this.props.value ? moment(this.props.value).format('YYYY-MM-DD') : ''}
            style={{ width: '150px' }}
          />
        </div>
      );
    }

    return (
      <div className={className}>
        <ReactDatePicker
          locale={locale}
          disabledKeyboardNavigation
          onChangeRaw={this.onChangeRaw}
          onChange={this.onChange}
          minDate={this.MinDate}
          dateFormat="L"
          {...this.GetOptionalProps()}
        />
      </div>
    );
  }
}

DateTime.propTypes = {
  value: PropTypes.string,
  valueChanged: PropTypes.func.isRequired,
  allowPastDates: PropTypes.bool,
  isValid: PropTypes.bool,
  touched: PropTypes.bool,
};

DateTime.defaultProps = {
  value: '',
  allowPastDates: true,
  isValid: true,
  touched: true,
};

export default DateTime;
