import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

/**
 * https://github.com/reactjs/react-modal#app-element
 * http://reactcommunity.org/react-modal/accessibility/
 */
if (document.getElementById('mountPoint')) {
  Modal.setAppElement('#mountPoint');
} else {
  Modal.setAppElement('body');
}

/**
 * Modal Dialog Component as shown below
 *
 * ╔═════════════════════════════════════╗
 * ║ {TITLE}                             ║-HEADER
 * ║═════════════════════════════════════║
 * ║                                     ║
 * ║                                     ║
 * ║             {CONTENT}               ║
 * ║                                     ║
 * ║                        ╔══════════╗ ║
 * ║                        ║ {BUTTON} ║---OPTIONAL
 * ║                        ╚══════════╝ ║
 * ╚═════════════════════════════════════╝
 *
 * It required 4 props
 *
 * 1. title      : A string to be shown on the Header.
 * 2. content    : An object containing HTML markup that will be rendered as Content OR just simply a String.
 * 3. isOpen     : Boolean describing if the modal should be shown or not.
 * 4. closeModal : Function that will be run when the modal is requested to be closed.
 *
 * Please refer to the bottom of this file to see others optional props.
 *
 *
 * ✅ MINIMAL USAGE
 *
 *  <Dialog title='A Dialog' content={<div>It's me!</div>} isOpen closeModal={() => {}} />
 *
 *
 * ✅ Change its Height and Width (by default is 300x600)
 *
 *  <Dialog {...requiredProps} height={500} width={400} />
 *
 *
 * ✅ ADD BUTTON by passing an array of object
 *
 * const buttons = [
 *    {
 *      text: 'Extra Button', style: { width: '250px' },
 *    },
 *    {
 *      text: 'Cancel', onClick: () => {},
 *    },
 *    {
 *      text: 'Save', disabled: true,
 *    },
 *  ];
 *
 *  <Dialog {...requiredProps} buttons={buttons} />
 *
 *
 * ✅ MODIFY STYLE inside the Dialog
 *
 * 1. Modify Content horizontal alignment
 *
 *  <Dialog {...requiredProps} contentStyle={{ textAlign: 'right' }} />
 *
 * 2. Modify Button position
 *
 *  <Dialog {...requiredProps} buttonWrapperStyle={{ left: 0 }} />
 */

const modalDefaultStyle = (height, width) => ({
  content: {
    border: 0,
    borderRadius: 0,
    height: `${height}px`,
    left: '50%',
    padding: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: `${width}px`,
    backgroundColor: 'transparent',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: 'rgba(53, 53, 53, 0.75)',
    zIndex: 9,
  },
});

const headerDefaultStyle = {
  margin: 0,
  padding: '16px',
  width: '100%',
};

const contentDefaultStyle = {
  padding: '16px',
};

const buttonWrapperDefaultStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  padding: '20px 20px 0px 0px',
};

const modalWrapperStyle = {
  backgroundColor: '#fff',
  height: '100%',
};

const buttonClassName = 'zuuseButton';
const headerClassName = 'modalDialogHeader';

const renderButton = (buttons, buttonWrapperStyle) => buttons.length > 0 && (
  <div style={{ ...buttonWrapperDefaultStyle, ...buttonWrapperStyle }}>
    {
      buttons.map(({
        text, onClick, disabled, style,
      }) => (
        <button
          className={buttonClassName}
          style={style}
          onClick={onClick}
          disabled={disabled}
          key={text}
          type="button"
        >
          {text}
        </button>
      ))
    }
  </div>
);

const Dialog = (props) => {
  const { content: defaultContentStyle, overlay: defaultOverlayStyle } = modalDefaultStyle(props.height, props.width);

  const finalModalStyle = {
    content: { ...defaultContentStyle, ...props.modalStyle.content },
    overlay: { ...defaultOverlayStyle, ...props.modalStyle.overlay },
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
      contentLabel={`Dialog for ${props.title}`}
      style={finalModalStyle}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
    >
      <div className={props.dialogClassName} style={{ ...modalWrapperStyle, ...props.modalContentWrapperStyle }}>
        <div className={headerClassName} style={{ ...headerDefaultStyle, ...props.headerStyle }}>
          {props.title}
        </div>
        <div style={{ ...contentDefaultStyle, ...props.contentStyle }}>
          {props.content}
        </div>
        {renderButton(props.buttons, props.buttonWrapperStyle)}
      </div>
    </Modal>
  );
};

Dialog.propTypes = {
  // Required Props
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,

  // Optional
  height: PropTypes.number,
  width: PropTypes.number,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  })),
  modalStyle: PropTypes.shape({ content: PropTypes.shape({}), overlay: PropTypes.shape({}) }),
  headerStyle: PropTypes.shape({}),
  contentStyle: PropTypes.shape({}),
  modalContentWrapperStyle: PropTypes.shape({}),
  buttonWrapperStyle: PropTypes.shape({}),
  shouldCloseOnOverlayClick: PropTypes.bool,
  dialogClassName: PropTypes.string,
};

Dialog.defaultProps = {
  height: 300,
  width: 600,
  buttons: [],
  modalContentWrapperStyle: {},
  modalStyle: {},
  headerStyle: {},
  contentStyle: {},
  buttonWrapperStyle: {},
  shouldCloseOnOverlayClick: false,
  dialogClassName: '',
  closeModal: null,
};

export default Dialog;
