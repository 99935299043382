// WIP: This is expected to grow in size to need a full component.
/* eslint-disable react/no-unused-prop-types, react/prefer-stateless-function */

import React from 'react';
import Header from '../components/header';
import Sprite from '../components/sprite';

class Test extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const style = {
      fontSize: 24,
    };
    return (
      <div className="page" style={style}>
        <Header Title="Scout" />
        <Sprite color="#ff0000" icon="Next" />
        <Sprite icon="Back" />
        <Sprite icon="Next" />
        <Sprite icon="Add" />
        <Sprite icon="Open" />
        <Sprite icon="Sort" />
        <Sprite icon="List" />
        <Sprite icon="Waiting" />
        <Sprite icon="Outstanding" />
        <Sprite icon="InProgress" />
        <Sprite icon="Complete" />
        <Sprite icon="Rejected" />
        <Sprite icon="Status" />
        <Sprite icon="Department" />
        <Sprite icon="Requestor" />
        <Sprite icon="Location" />
        <Sprite icon="Conversation" />
        <Sprite icon="Date" />
        <Sprite icon="Document" />
      </div>
    );
  }
}
export default Test;
