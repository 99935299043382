import { Status as RequestStatus } from './requestStatus';

export const DepartmentFilterStatus = Object.freeze({ Any: 'Any' });

export const RequestorFilterStatus = Object.freeze({ Any: 'Any' });

export const FilterType = Object.freeze({
  Status: 'Status',
  Department: 'Department',
  Requestor: 'Requestor',
});

const MatchIndex = 2;

const KeywordRegExp = new RegExp(
  `((Department|Requestor|Status)\\s*:\\s*)"(.*?)"
    |\\s*[AND]*\\s*((Department|Requestor|Status)\\s*:\\s*)"(.*?)"
    |\\s*[AND]*\\s*((Department|Requestor|Status)\\s*:\\s*)"(.*?)"`,
  'gm',
);

class Filter {
  /* Get keyword part from string */
  static ExtractKeyword(searchTerm) {
    const matched = searchTerm.match(KeywordRegExp);
    let keyword = searchTerm;
    if (matched && matched.length) {
      keyword = matched.reduce((reducedWord, nextMatchedWord) => reducedWord.replace(nextMatchedWord, ''), searchTerm);
    }
    return keyword.trim();
  }

  static GetRegExp(searchFor) {
    return new RegExp(`${searchFor}\\s*:\\s*"(.*?)"`, 'gm');
  }

  static AddANDIfNotEmpty(string) {
    const localString = string
      ? `${string} AND`
      : string;
    return localString;
  }

  static ValidateFilterType(value, list = []) {
    return list.find(item => item === value);
  }

  static Url(string) {
    return string
      ? `/requests/list?search=${string}`
      : '/requests/list';
  }

  static EncodeUri(string) {
    return encodeURIComponent(string);
  }

  static DecodeUri(string) {
    return decodeURIComponent(string);
  }

  constructor(localFilter = {}) {
    this.department = localFilter.department || DepartmentFilterStatus.Any;
    this.status = localFilter.status || RequestStatus.Any;
    this.requestor = localFilter.requestor || RequestorFilterStatus.Any;
    this.keyword = localFilter.keyword || '';
  }

  SetDepartment(value) {
    this.department = value;
  }

  SetStatus(value) {
    this.status = value;
  }

  SetRequestor(value) {
    this.requestor = value;
  }

  SetKeyword(value) {
    this.keyword = value;
  }

  get SearchTerm() {
    let string = '';
    if (this.department && this.department !== DepartmentFilterStatus.Any) string = `Department: "${this.department}"`.trim();
    if (this.status && this.status !== RequestStatus.Any) {
      string = Filter.AddANDIfNotEmpty(string);
      string = `${string} Status: "${this.status}"`.trim();
    }
    if (this.requestor && this.requestor !== RequestorFilterStatus.Any) {
      string = Filter.AddANDIfNotEmpty(string);
      string = `${string} Requestor: "${this.requestor}"`.trim();
    }
    if (this.keyword) {
      string = `${string} ${this.keyword}`.trim();
    }
    return string;
  }

  ReAssign(searchTerm) {
    const department = this.ExtractWord(FilterType.Department, searchTerm);
    const requestor = this.ExtractWord(FilterType.Requestor, searchTerm);
    const status = this.ExtractWord(FilterType.Status, searchTerm);

    this.department = department || DepartmentFilterStatus.Any;
    this.status = status || RequestStatus.Any;
    this.requestor = requestor || RequestorFilterStatus.Any;
    this.keyword = Filter.ExtractKeyword(searchTerm);
  }

  ExtractWord(searchFor, searchTerm = this.SearchTerm) {
    if (searchFor && searchTerm) {
      const matches = Filter.GetRegExp(searchFor).exec(searchTerm);
      return matches && matches.length === MatchIndex
        ? matches[1]
        : '';
    }
    return '';
  }
}

export default Filter;
