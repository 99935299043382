import AttachmentFileDetails from './AttachmentFileDetails';
import { GetFileType } from './FileType';
import { GetSyncMode } from './SyncMode';

export default class Attachment {
  constructor(attachment) {
    const localAttachment = attachment || {};
    this.Id = localAttachment.Id;
    this.Key = localAttachment.Key || '';
    this.File = new AttachmentFileDetails(localAttachment.File);
    this.Keywords = localAttachment.Keywords || '';
    this.Display = localAttachment.Display || '';
    this.AttachedItemReference = localAttachment.AttachedItemReference || '';
    this.AttachedItemType = localAttachment.AttachedItemType || '';
    this.FileType = GetFileType(localAttachment.FileType);
    this.SyncMode = GetSyncMode(localAttachment.SyncMode);
    this.IsActive = localAttachment.IsActive || true;
    this.AllAttachedItemTypes = localAttachment.AllAttachedItemTypes || '';
    this.Thumbnail = (this.Thumbnail && new AttachmentFileDetails(this.Thumbnail)) || null;
    this.VersionDate = localAttachment.VersionDate || '';
    this.VersionBy = localAttachment.VersionBy || '';
    this.CreatedDate = localAttachment.CreatedDate || '';
    this.CreatedBy = localAttachment.CreatedBy || '';
  }

  Clone() {
    const clone = new Attachment();

    clone.Id = this.Id;
    clone.Key = this.Key;
    clone.File = this.File.Clone();
    clone.Keywords = this.Keywords;
    clone.Display = this.Display;
    clone.AttachedItemReference = this.AttachedItemReference;
    clone.AttachedItemType = this.AttachedItemType;
    clone.FileType = this.FileType;
    clone.SyncMode = this.SyncMode;
    clone.IsActive = this.IsActive;
    clone.AllAttachedItemTypes = this.AllAttachedItemTypes;
    clone.Thumbnail = this.Thumbnail;
    clone.VersionDate = this.VersionDate;
    clone.VersionBy = this.VersionBy;
    clone.CreatedDate = this.CreatedDate;
    clone.CreatedBy = this.CreatedBy;

    return clone;
  }
}
