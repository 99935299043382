import { FieldName } from '../../modules/ScoutModels/models/field';

export const Step = Object.freeze({
  one: '1',
  two: '2',
  three: '3',
  four: '4',
});

export const NavigationLabel = Object.freeze({
  Back: 'Back',
  Next: 'Next',
  Done: 'Done',
});

export const NavigationIcon = Object.freeze({
  Back: 'Back',
  Next: 'Next',
});

class RequestWizard {
  constructor(request, isEdit = false, editRequestId = null) {
    this.isEdit = isEdit;
    this.editRequestId = editRequestId;
    this.requestType = null;
    this.request = null;
    this.request = request;
    this.fields = new Map();
  }

  get RequestType() {
    this.validateRequestType();
    return this.requestType;
  }

  set RequestType(requestType) {
    this.requestType = requestType;
    this.configureStepFields();
  }

  /** @private */
  get Request() {
    this.validateRequest();
    return this.request;
  }

  async isFinalStep(step) {
    this.validateRequestWizard();
    let isFinalStep = false;
    switch (step) {
      case Step.one:
      case Step.two:
        return false;
      case Step.three:
        isFinalStep = !await this.isStepRequired(Step.four);
        break;
      case Step.four:
        return true;
      default:
        throw Error('Invalid step');
    }
    return isFinalStep;
  }

  async isStepRequired(step) {
    this.validateRequestWizard();
    let fields;
    switch (step) {
      case Step.one:
      case Step.two:
      case Step.three:
        return true;
      case Step.four:
        fields = this.step4FieldsArray;
        break;
      default:
        throw Error('Invalid step');
    }
    if (RequestWizard.userInputRequired(fields)) {
      return true;
    }
    const stepRequired = !await this.isStepValid(step);
    return stepRequired;
  }

  async isStepValid(step, request = this.Request) {
    this.validateRequestWizard();
    let valid = false;
    switch (step) {
      case Step.one:
      case Step.two:
      case Step.three:
        return false;
      case Step.four:
        valid = await this.validateFields(this.step4FieldsArray, request);
        break;
      default:
        throw Error('Invalid step');
    }
    return valid;
  }

  async numberOfSteps() {
    const requiredSteps = [
      this.isStepRequired(Step.one),
      this.isStepRequired(Step.two),
      this.isStepRequired(Step.three),
      this.isStepRequired(Step.four),
    ];
    const results = await Promise.all(requiredSteps);
    let stepsCount = 0;
    results.forEach((result) => {
      if (result) {
        stepsCount++;
      }
    });
    return stepsCount;
  }

  getField(fieldName) {
    let field = this.fields.get(fieldName);
    if (!field) {
      field = this.RequestType.fields.GetFieldOrEmpty(fieldName);
      field.initialize();
      this.fields.set(fieldName, field);
    }
    return field;
  }

  /** @private */
  validateRequestType() {
    if (!this.requestType) {
      throw Error('Invalid request type');
    }
  }

  /** @private */
  validateRequest() {
    if (!this.request) {
      throw Error('Invalid request');
    }
  }

  /** @private */
  validateRequestWizard() {
    this.validateRequestType();
    this.validateRequest();
  }

  /** @private */
  configureStepFields() {
    this.step3FieldsArray = [
      this.getField(FieldName.Domain),
      this.getField(FieldName.Department),
      this.getField(FieldName.Site),
      this.getField(FieldName.Building),
      this.getField(FieldName.Floor),
      this.getField(FieldName.Room),
      this.getField(FieldName.AssetName),
      this.getField(FieldName.SpecialAccessContactName),
      this.getField(FieldName.SpecialAccessContactDetails),
      this.getField(FieldName.PreferredStartDate),
    ];

    this.step4FieldsArray = [
      this.getField(FieldName.Requestor),
      this.getField(FieldName.RequestorPhone),
      this.getField(FieldName.RequestorEmail),
      this.getField(FieldName.AdditionalNotificationEmailAddresses),
      this.getField(FieldName.CostCentre),
      this.getField(FieldName.Expense),
    ];
  }

  /** @private */
  static userInputRequired(fields) {
    const shownFields = fields.filter(field => field.shown);
    if (!shownFields || shownFields.length === 0) {
      return false;
    }
    return !shownFields.every(field => field.defaultOnly);
  }

  /** @private */
  async validateFields(fields, request = this.Request) {
    const shownFields = fields.filter(field => field.shown);
    if (shownFields.length === 0) {
      return true;
    }
    const fieldsToValidate = [];
    for (let index = 0; index < shownFields.length; index++) {
      const field = shownFields[index];
      fieldsToValidate.push(this.validateField(field, request));
    }
    const results = await Promise.all(fieldsToValidate);
    return results.every(result => result);
  }

  async validateField(field, request = this.Request) {
    const value = request.GetValue(field.name);
    const valid = await field.IsValid(value);
    return valid;
  }
}

export default RequestWizard;
