export const FILE_TYPE = {
  IMAGE: 'Image',
  PREVIEW: 'Preview',
  THUMBNAIL: 'Thumbnail',
};

export const ENCRYPTION_TYPE = {
  NONE: 'None',
  STATIC: 'Static',
  TRANSIENT: 'Transient',
};
