import React from 'react';
import ContextMenuItem from './contextMenuItem';
import LogOutIcon from './images/logOut.png';

const logOutIconStyle = { width: 20, height: 20 };

const LogoutMenuItem = () => (
  <ContextMenuItem icon={<img src={LogOutIcon} alt="Log Out Icon" style={logOutIconStyle} />} descriptions={['Log Out']} />
);

export default LogoutMenuItem;
