import React, { Component } from 'react';
import classNames from 'classnames';

import eventNotifier from '../../modules/common/eventHandling/eventNotifier';
import eventType from '../../modules/common/eventHandling/eventType';

class LoadingIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      centredIcons: 0,
      cornerIcons: 0,
    };

    this.minimumDuration = 400; // the minimum duration the icon should display, to prevent it flickering.
    this.timerId = null;
    this.centredIcons = 0; // Events can occur before the state is set so we store the counters outside state.
    this.cornerIcons = 0;

    this.StopLoader = this.StopLoader.bind(this);
    this.StartTimer = this.StartTimer.bind(this);
    this.Start = this.Start.bind(this);
    this.End = this.End.bind(this);
    this.Reset = this.Reset.bind(this);
  }

  componentDidMount() {
    eventNotifier.on(eventType.ApiCallStarted, this.Start);
    eventNotifier.on(eventType.ApiCallEnded, this.End);
    eventNotifier.on(eventType.RouteChanged, this.Reset);
  }

  componentWillUnmount() {
    eventNotifier.removeListener(eventType.ApiCallStarted, this.Start);
    eventNotifier.removeListener(eventType.ApiCallEnded, this.End);
    eventNotifier.removeListener(eventType.RouteChanged, this.Reset);
  }

  get ShouldShowLoader() {
    return this.state.loading || this.state.centredIcons > 0 || this.state.cornerIcons > 0;
  }

  get PositionCenter() {
    return this.state.centredIcons > 0;
  }

  StopLoader() {
    this.setState({ loading: false });
    this.timerId = null;
  }

  StartTimer(duration = this.minimumDuration) {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.timerId = setTimeout(this.StopLoader, duration);
  }

  Start(event = {}) {
    if (event.loading) {
      const duration = event.loading.minimumDuration ? event.loading.minimumDuration : this.minimumDuration;
      this.StartTimer(duration);
      if (event.block) {
        this.centredIcons++;
        this.setState({ loading: true, centredIcons: this.centredIcons });
      } else {
        this.cornerIcons++;
        this.setState({ loading: true, cornerIcons: this.cornerIcons });
      }
    }
  }

  End(event = {}) {
    if (event.loading) {
      if (event.block && this.centredIcons > 0) { // events may happen before render so don't allow counters to go below 0
        this.centredIcons--;
        this.setState({ centredIcons: this.centredIcons });
      } else if (this.cornerIcons > 0) {
        this.cornerIcons--;
        this.setState({ cornerIcons: this.cornerIcons });
      }
    }
  }

  Reset() {
    this.centredIcons = 0;
    this.cornerIcons = 0;
    this.setState({
      loading: false,
      centredIcons: 0,
      cornerIcons: 0,
    });
  }

  render() {
    return (
      <div
        className={classNames(
          'loader-placeholder',
          { 'show-loader-top': this.ShouldShowLoader && !this.PositionCenter },
          { 'show-loader-center': this.ShouldShowLoader && this.PositionCenter },
        )}
      >
        <div className="cssload-loader" />
      </div>
    );
  }
}

export default LoadingIndicator;
