import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NavigationButton from '../../components/navigationButton';
import Request from '../../../../modules/ScoutModels/models/request';
import CommonFetch from '../../../utils/commonFetch';
import Toaster, { TOAST_ERROR } from '../../../../modules/common/toaster/toaster';

const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

const rowClass = 'w3-row w3-padding-12';
const colLabelClass = 'w3-col s3 m3';
const colValueClass = 'w3-col s9 m9';

class Step5 extends Component {
  static localDate(value) {
    if (moment(value, moment.ISO_8601, true).isValid()) {
      return moment(value, moment.ISO_8601, true).local();
    }
    return value;
  }

  constructor(props) {
    super(props);
    this.state = { requestUpdated: false };
  }

  async componentDidMount() {
    if (this.props.hasBeenEdited) {
      try {
        const updatedRequest = await CommonFetch.getRequestDetails(this.props.requestID);
        this.props.request.versionDate = updatedRequest.versionDate;
        this.props.request.versionBy = updatedRequest.versionBy;
        this.setState({
          requestUpdated: true,
        });
      } catch (e) {
        console.error(e);
        Toaster.pop('There was an error getting updated request.', TOAST_ERROR);
      }
    }
  }

  render() {
    const nextButton = this.props.isEdit
      ? { icon: 'Open', label: 'Details', destination: `/requests/details/${this.props.requestID}` }
      : { icon: 'List', label: 'List', destination: `/requests/list/${this.props.requestID}` };
    return (
      <div className="stepform">
        <h5>
          <b>Thank You</b>
        </h5>
        <div className="fields">
          <div style={{ paddingBottom: '20px' }}>
            <b>
              {`Your service request has been ${this.props.hasBeenEdited ? 'edited' : 'completed'}. Return to the ${
                this.props.isEdit ? 'Details view to review your service request' : 'List view to manage your service requests'
              }.`}
            </b>
          </div>
          <div className={rowClass}>
            <div className={colLabelClass}>
              <b>Id:</b>
            </div>
            <div className={colValueClass}>
              <span>{this.props.request.requestNumber}</span>
            </div>
          </div>
          <div className={rowClass}>
            <div className={colLabelClass}>
              <b>Reported:</b>
            </div>
            <div className={colValueClass}>
              <span>{Step5.localDate(this.props.request.createdDate).format('LLLL')}</span>
            </div>
          </div>
          <div className={rowClass}>
            <div className={colLabelClass}>
              <b>Reporter:</b>
            </div>
            <div className={colValueClass}>
              <span>{this.props.request.requestor}</span>
            </div>
          </div>
          {this.state.requestUpdated && (
            <Fragment>
              <div className={rowClass}>
                <div className={colLabelClass}>
                  <b>Updated:</b>
                </div>
                <div className={colValueClass}>
                  <span>{this.props.request.DisplayVersionDate}</span>
                </div>
              </div>
              <div className={rowClass}>
                <div className={colLabelClass}>
                  <b>Updated By:</b>
                </div>
                <div className={colValueClass}>
                  <span>{this.props.request.versionBy}</span>
                </div>
              </div>
            </Fragment>
          )}
        </div>
        <div className="button-group">
          <div className="w3-right">
            <NavigationButton icon={nextButton.icon} label={nextButton.label} destination={nextButton.destination} iconOnLeft={false} />
          </div>
        </div>
      </div>
    );
  }
}
Step5.propTypes = {
  request: PropTypes.instanceOf(Request).isRequired,
  isEdit: PropTypes.bool.isRequired,
  hasBeenEdited: PropTypes.bool.isRequired,
  requestID: PropTypes.string.isRequired,
};

export default Step5;
