import Uuid from 'uuid/v4';

export const FieldName = Object.freeze({
  Title: 'Title',
  Description: 'Description',
  Attachments: 'Attachments',
  Requestor: 'Requestor',
  JobTask: 'JobTask',
  JobType: 'JobType',
  JobSubType: 'JobSubType',
  OHSIssue: 'OHSIssue',
  ReferenceNumber: 'ReferenceNumber',
  Chargeable: 'Chargeable',
  EstimatedCompletionDate: 'EstimatedCompletionDate',
  Site: 'Site',
  Building: 'Building',
  Floor: 'Floor',
  Room: 'Room',
  AssetName: 'AssetName',
  SpecialAccess: 'SpecialAccess',
  SpecialAccessContactName: 'SpecialAccessContactName',
  SpecialAccessContactDetails: 'SpecialAccessContactDetails',
  RequestorPhone: 'RequestorPhone',
  RequestorEmail: 'RequestorEmail',
  Department: 'Department',
  CostCentre: 'CostCentre',
  PreferredStartDate: 'PreferredStartDate',
  Domain: 'Domain',
  Expense: 'Expense',
  AdditionalNotificationEmailAddresses: 'AdditionalNotificationEmailAddresses',
  Consent: 'Consent',
  ConsentMessage: 'ConsentMessage',
});

export const DataType = Object.freeze({
  Boolean: 'boolean',
});

const FieldType = Object.freeze({
  [FieldName.Consent]: DataType.Boolean,
  [FieldName.OHSIssue]: DataType.Boolean,
  [FieldName.SpecialAccess]: DataType.Boolean,
});

export const RequiredFields = Object.freeze({
  [FieldName.Title]: FieldName.Title,
  [FieldName.Building]: FieldName.Building,
  [FieldName.Floor]: FieldName.Floor,
  [FieldName.Requestor]: FieldName.Requestor,
});

export const RequiredReadOnlyFields = Object.freeze({
  [FieldName.Title]: FieldName.Title,
  [FieldName.Building]: FieldName.Building,
  [FieldName.Floor]: FieldName.Floor,
  [FieldName.Requestor]: FieldName.Requestor,
  [FieldName.AdditionalNotificationEmailAddresses]: FieldName.AdditionalNotificationEmailAddresses,
});

export const DefaultCaption = Object.freeze({
  Title: 'Title',
  Domain: 'Domain',
  Site: 'Site',
  Building: 'Building',
  Description: 'Description',
  Attachments: 'Add pictures or documents',
  Floor: 'Floor',
  Room: 'Room',
  AssetName: 'Asset',
  Department: 'Department',
  JobTask: 'Job Task',
  JobType: 'Job Type',
  JobSubType: 'Job Sub Type',
  Requestor: 'Requested by',
  RequestorPhone: 'Phone Number',
  RequestorEmail: 'E-mail Address',
  CostCentre: 'Cost Centre',
  SpecialAccess: 'Special Access',
  SpecialAccessContactName: 'Special Access Contact Name',
  SpecialAccessContactDetails: 'Special Access Contact Details',
  PreferredStartDate: 'Preferred Start Date',
  ReferenceNumber: 'Reference Number',
  OHSIssue: 'OHS Issue',
  Expense: 'Expense',
  AdditionalNotificationEmailAddresses: 'Additional Email Addresses',
  Consent: 'Consent',
  ConsentMessage: 'Consent Message',
});

const FieldsWithUserDefault = Object.freeze({
  // Step 3 fields
  [FieldName.Department]: 'DepartmentName',
  // Step 4 fields
  [FieldName.Requestor]: 'FullName',
  [FieldName.RequestorPhone]: 'Phone',
  [FieldName.RequestorEmail]: 'Email',
  [FieldName.AdditionalNotificationEmailAddresses]: 'Email',
  [FieldName.CostCentre]: 'CostCentreName',
  [FieldName.Expense]: 'ExpenseName',
});

export const LookupFields = [
  FieldName.Department,
  FieldName.CostCentre,
];
export const SearchFields = [FieldName.Expense];

class Field {
  constructor(field = {}) {
    this.id = field.id;
    this.caption = field.caption || null;
    this.name = field.name || null;
    this.dataType = FieldType[this.name];
    this.default = null;
    this.Default = field.default;
    this.pattern = field.pattern || null;
    this.shown = !!field.shown;
    this.required = this.shown ? !!field.required : false;
    this.tooltip = field.tooltip || null;
    this.patternValidationFailedMessage = field.patternValidationFailedMessage || null;
    this.note = field.note || null;
    this.defaultOnly = !!field.defaultOnly;
    /* toJSON property needs to be an instance method */
    this.toJSON = this.ToJson.bind(this);
  }

  /* Customize property names for JSON.stringify */
  ToJson() {
    const field = {
      id: this.id,
      default: this.default === '' ? null : this.default,
      pattern: this.pattern === '' ? null : this.pattern,
      caption: this.caption === '' ? null : this.caption,
      tooltip: this.tooltip === '' ? null : this.tooltip,
      patternValidationFailedMessage: this.patternValidationFailedMessage === '' ? null : this.patternValidationFailedMessage,
      name: this.name,
      shown: this.shown,
      required: this.required,
      note: this.note,
      defaultOnly: this.defaultOnly,
    };
    return field;
  }

  get DefaultCaption() {
    return DefaultCaption[this.name];
  }

  get captionOrDefault() {
    return this.caption || this.DefaultCaption;
  }

  get IsRequired() {
    return !!RequiredFields[this.name];
  }

  get IsRequiredReadOnly() {
    return !!RequiredReadOnlyFields[this.name];
  }

  get HasUserDefault() {
    return !!FieldsWithUserDefault[this.name];
  }

  get UserDefaultKey() {
    return FieldsWithUserDefault[this.name];
  }

  set Default(value) {
    this.default = this.CastDefaultValue(value);
  }

  Clone(clone = new Field()) {
    /* eslint-disable no-param-reassign */
    clone.id = this.id;
    clone.name = this.name;
    clone.dataType = this.dataType;
    clone.Default = this.default;
    clone.pattern = this.pattern;
    clone.shown = this.shown;
    clone.required = this.required;
    clone.caption = this.caption;
    clone.tooltip = this.tooltip;
    clone.patternValidationFailedMessage = this.patternValidationFailedMessage;
    clone.note = this.note;
    clone.defaultOnly = this.defaultOnly;
    /* eslint-enable no-param-reassign */
    return clone;
  }

  CompareTo(field) {
    return this.default === field.default
      && this.name === field.name
      && this.pattern === field.pattern
      && this.shown === field.shown
      && this.required === field.required
      && this.caption === field.caption
      && this.tooltip === field.tooltip
      && this.patternValidationFailedMessage === field.patternValidationFailedMessage
      && this.note === field.note
      && this.defaultOnly === field.defaultOnly;
  }

  IsValid(value) {
    if (!this.shown) { return true; }
    if (this.required && typeof value === 'boolean' && typeof value !== 'undefined') { return true; }
    if (this.required && !value) { return false; }
    if (this.pattern && value && !value.toString().match(this.pattern)) { return false; }
    return true;
  }

  /** @private */
  CastDefaultValue(defaultValue) {
    if (this.dataType === DataType.Boolean) {
      if (defaultValue === 'true' || defaultValue === true) {
        return true;
      }
      return defaultValue === 'false' || defaultValue === false ? false : null;
    }
    return defaultValue || null;
  }

  /** @protected */
  // Need this here so that consuming objects not need to check the instance type before calling the function.
  // eslint-disable-next-line class-methods-use-this
  initialize() { }
}

export const ChargeableField = Object.freeze(
  new Field({
    id: Uuid(),
    caption: 'Chargeable',
    name: 'Chargeable',
  }),
);

export const EstimatedCompletionDateField = Object.freeze(
  new Field({
    id: Uuid(),
    caption: 'Estimated Completion Date',
    name: 'EstimatedCompletionDate',
  }),
);

export default Field;
