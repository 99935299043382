import React from 'react';
import PropTypes from 'prop-types';
import ContextMenu from './contextMenu';
import Gravatar from '../gravatar/gravatar';
import Auth from '../auth/auth';

const radiusRatio = 2;
const size = 36;
const radius = size / radiusRatio;

const UserContextMenu = props => (
  <div>
    {
      Auth.loggedIn() && (
        <ContextMenu icon={<Gravatar size={size} radius={radius} email={Auth.email()} />}>
          {props.children}
        </ContextMenu>
      )
    }
  </div>
);

UserContextMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserContextMenu;
