import React from 'react';
import PropTypes from 'prop-types';

const wrapperStyle = {
  display: 'flex',
};
const indicatorStyle = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '25px',
  marginLeft: '5px',
};

const RequiredField = props => (
  <div style={wrapperStyle}>
    <div>{props.children}</div>
    {
        props.isRequired ? <div style={indicatorStyle}><span>*</span></div> : null
      }
  </div>
);

RequiredField.propTypes = {
  isRequired: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
export default RequiredField;
