import PropTypes from 'prop-types';
import CryptoJS from 'crypto-js';
import React from 'react';
import shortid from 'shortid';
import { FILE_TYPE, ENCRYPTION_TYPE } from './constants';
import Encryption from '../../common/auth/encryption';
import fetchData from '../../common/api/fetchData';
import Image from './Image';

const NoImage = require('../images/NoPhoto.png');

/**
 *  1. Check if attachment meta is valid for image source
 *  2. Create image components based on encryption type
 */
class ImageComponent {
  /**
    * Check if a file type is valid for rendering with img tag
    * @param {Object} attachment
    */
  static isValidImageType(attachment) {
    return attachment.FileType === FILE_TYPE.IMAGE
      || attachment.FileType === FILE_TYPE.PREVIEW
      || attachment.FileType === FILE_TYPE.THUMBNAIL;
  }

  /**
    * Get encrypted base64 , decrypt and change it to objecturl
    * @param {String} data encrypted base64
    * @param {String} EncryptionKey encryption key for decrypt data
    * @return {String}  image url
    */

  static getDecryptedImageBlob(data, EncryptionKey) {
    const decrypted = Encryption.decrypt(data, EncryptionKey);
    const blob = Encryption.b64toBlob(decrypted.toString(CryptoJS.enc.Base64));
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(blob);
    return imageUrl;
  }

  /**
    * create an image tag based on encryption type
    * @param {Object} meta contains meta data representing a file
    * @return {Promise Object} if resolve, it gives image tag
    */

  static createImageComponent(meta) {
    return new Promise((resolve) => {
      if (!meta) {
        resolve(null);
      }

      const {
        Uri,
        EncryptionKey,
        EncryptionType,
      } = meta;

      switch (EncryptionType) {
        /**
         *  1. Encryption type is None, return image tag with given uri
         */
        case ENCRYPTION_TYPE.NONE: {
          resolve(<Image key={shortid.generate()} uri={Uri} />);
          break;
        }

        /**
         *  2. Encryption type is Static, get data with uri , decrypt and return image tag
         */
        case ENCRYPTION_TYPE.STATIC: {
          fetchData({ URL: Uri, noHeader: true })
            .then(response => response.text())
            .then((result) => {
              if (result) {
                const url = ImageComponent.getDecryptedImageBlob(result, EncryptionKey);
                resolve(<Image key={shortid.generate()} uri={url} />);
              }
            });

          break;
        }

        /**
         *  To be implemented
         *  3. Encryption type is Transient,
         */
        case ENCRYPTION_TYPE.TRANSIENT: {
          break;
        }

        default: {
          resolve(null);
        }
      }
    });
  }

  /**
    * Pass attachment object and get an image tag
    * @param {Object} attachment
    * @return {Promise Object} if resolve, returns an object with a thumbnail image ( if there is ) and an image file
    */

  static processAttachment(attachment, emptyImage = null) {
    const images = {};
    const ThumbnailIndex = 0;
    const FileIndex = 1;

    return new Promise((resolve) => {
      if (ImageComponent.isValidImageType(attachment)) {
        const { Thumbnail, File } = attachment;

        // When an image is in staging the Uri is null so it cannot be previewed from the server.
        if (File.Uri) {
          const promiseArray = [
            ImageComponent.createImageComponent(Thumbnail),
            ImageComponent.createImageComponent(File),
          ];

          Promise.all(promiseArray).then((results) => {
            if (results[ThumbnailIndex]) {
              images.Thumbnail = results[ThumbnailIndex];
            }

            if (results[FileIndex]) {
              images.File = results[FileIndex];
            }

            resolve(images);
          });
        } else {
          images.File = <Image key={shortid.generate()} options={{ classes: 'no-found' }} uri={emptyImage || NoImage} />;
          resolve(images);
        }
      } else {
        images.File = <Image key={shortid.generate()} options={{ classes: 'no-found' }} uri={emptyImage || NoImage} />;
        resolve(images);
      }
    });
  }

  /**
    *
    * @param {Object} attachment
    * @return {Promise Object} if resolve, returns an image component or "No Found" image tag
    */

  static getImages(attachment, emptyImage) {
    return new Promise((resolve) => {
      ImageComponent.processAttachment(attachment, emptyImage).then((result) => {
        resolve(result);
      });
    });
  }
}


ImageComponent.propTypes = {
  attachment: PropTypes.objectOf(PropTypes.object),
};

ImageComponent.defaultProps = {
  attachment: {},
};

export default ImageComponent;
