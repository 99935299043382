import { Component } from 'react';
import PropTypes from 'prop-types';

import { UserManager } from './authConstants';

export default class SignInCallback extends Component {
  constructor(props) {
    super(props);

    this.signinRedirectCallback = this.signinRedirectCallback.bind(this);

    UserManager.signinRedirectCallback().then(user => this.signinRedirectCallback(user));
  }

  signinRedirectCallback(user) {
    const newPath = user.state;
    this.props.history.push(newPath);
  }

  render() {
    return null;
  }
}

SignInCallback.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
