import Field, { LookupFields, SearchFields } from './field';
import LookupField from './lookupField';
import SearchField from './searchField';

class FieldFactory {
  constructor(fieldResourceAccess) {
    this.fieldResourceAccess = fieldResourceAccess || {};
  }

  createFields(fields) {
    const newFields = [];
    for (let index = 0; index < fields.length; index++) {
      const field = fields[index];
      let newField = null;
      if (LookupFields.some(fieldName => fieldName === field.name)) {
        newField = new LookupField(field, this.fieldResourceAccess[field.name]);
      }
      if (SearchFields.some(fieldName => fieldName === field.name)) {
        newField = new SearchField(field, this.fieldResourceAccess[field.name]);
      }
      if (!newField) {
        newFields.push(new Field(field));
      }
      if (newField) {
        newFields.push(newField);
      }
    }
    return newFields;
  }
}

export default FieldFactory;
