import React from 'react';
import PropTypes from 'prop-types';

const noop = () => { };

const RequestTile = props => (
  <div className="request-title">
    <div
      className={`card ${props.className}`}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children}
    </div>
  </div>
);

RequestTile.propTypes = {
  className: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

RequestTile.defaultProps = {
  className: '',
  onMouseEnter: noop,
  onMouseLeave: noop,
  onClick: noop,
  children: null,
};

export default RequestTile;
