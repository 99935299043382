import React from 'react';
import PropTypes from 'prop-types';
/**
 * if a child is an image tag without width or height style given,
 * we add 100% for width and height so that it is fit in its container
 * https://stackoverflow.com/questions/15295697/make-an-image-to-fit-its-parent-dimensions
 */
const SetDefaultStyleOfImage = children => React.Children.map(children, (child) => {
  let tmpChild = child;
  const typeOfChild = tmpChild.type;
  if (typeOfChild === 'img') {
    if (!tmpChild.props.style || (!tmpChild.props.style.width || !tmpChild.props.style.height)) {
      const childStyle = tmpChild.props.style || {};
      tmpChild = React.cloneElement(tmpChild, { style: { ...childStyle, width: '100%', height: '100%' } });
    }
  }
  return tmpChild;
});

const defaultWrapperStyle = {
  width: '100%',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
};

const Slide = props => (
  <div className="clickable" onClick={props.onClick} style={defaultWrapperStyle}>
    {SetDefaultStyleOfImage(props.children)}
  </div>
);

Slide.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};

Slide.defaultProps = {
  children: null,
  onClick: null,
};

export default Slide;
