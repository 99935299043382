import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ResizeObserver from 'react-resize-observer';

import eventTypes from '../eventTypes';
import ArrowButton from './ArrowButton';
import StyleToNumericConverter from '../StyleToNumericConverter';

const Direction = {
  Prev: 'prev',
  Next: 'next',
};

const defaultTextStyle = {
  position: 'absolute',
  bottom: '-25px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textAlign: 'center',
};

const wrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '0 10px',
  position: 'relative',
};

const slidesTrackStyle = {
  display: 'flex',
  position: 'relative',
  /* Wanted to put Infinity but not allowed for width value, */
  /* this element is like a long strip and most of area are hidden by a wrapper element. */
  /* When users click arrows, we move this strip to show slides we are supposed to show. */
  width: '10000px',
};

const defaultSlideStyle = (margin = 0, customStyle) => ({
  width: '75px',
  height: '75px',
  color: 'white',
  margin: '5px',
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  flexDirection: 'column',
  boxSizing: 'content-box',
  padding: '5px',
  border: '1px solid lightgrey',
  marginLeft: margin ? `${margin}px` : '5px',
  marginRight: margin ? `${margin}px` : '5px',
  ...customStyle,
});

const radix = 10;

/* make a file name width 10 pixels smaller than its parent width ( slide ) */
const textWidthOffset = 10;

/* used for calculating element width. unit width + padding * 2 + margin * 2 + border * 2 */
const numberOfStyleElements = 2;

/**
 * returns an element's total width including padding, margin and border
 */
const GetSlideWidth = (item, margin = 0) => {
  const GetElementStyle = StyleToNumericConverter(item);
  const width = GetElementStyle('width');
  const paddingL = GetElementStyle('paddingLeft') || 0;
  const paddingR = GetElementStyle('paddingRight') || 0;
  const border = GetElementStyle('borderLeftWidth') || 0;

  return width
    + (margin * numberOfStyleElements)
    + (paddingL + paddingR)
    + (border * numberOfStyleElements);
};

const ParseInt = value => parseInt(value, radix);

/**
 * how many slides can be fit in current slider container
 */
const GetVisibleSlidesLength = (itemsLength, totalWidth, unitWidth) => Math.min(itemsLength, Math.floor(totalWidth / unitWidth)) || 1;

class Slider extends Component {
  constructor(props) {
    super(props);
    this.minimumUnitMargin = 5;
    this.children = [];
    this.slides = null;
    this.state = {
      totalUnits: this.props.totalUnits,
      currentIndex: 0,
      unitMargin: this.minimumUnitMargin,
      visibleItems: 0,
      maxArrowSectionWidth: -1,
      sliderInitialized: false,
    };
    this.wrapperWidth = null;
    this.RearrangeSlides = this.RearrangeSlides.bind(this);
    this.OnPrevClick = this.OnPrevClick.bind(this);
    this.OnNextClick = this.OnNextClick.bind(this);
  }

  componentDidMount() {
    if (this.props.events) {
      this.props.events
        .on(eventTypes.AddSlide, (slide) => { if (slide) this.AddNewSlide(slide); }) /* add slides and move the Slider to show the last added ones */
        .on(eventTypes.InitSlider, (slide) => { if (slide) this.AddNewSlide(slide, true); }) /* add slides and no move the Slider */
        .on(eventTypes.RemoveSlide, (id) => { if (id) { this.RemoveSlide(id); } });
    }

    if (this.innerSlide && this.innerSlide.childNodes && this.innerSlide.childNodes.length) {
      this.RearrangeSlides();
    }
  }

  componentWillUnmount() {
    if (this.props.events) this.props.events.off(eventTypes.AddSlide).off(eventTypes.RemoveSlide).off(eventTypes.InitSlider);
  }

  get ShouldShowNoAttachmentFound() {
    return this.state.sliderInitialized && this.state.totalUnits === 0 && this.props.readOnly;
  }

  GetSliderWidth() {
    return StyleToNumericConverter(this.slider)('width');
  }

  AddNewSlide(slides, disableMove) {
    if (!slides.length && disableMove) {
      this.setState({
        sliderInitialized: true,
      });
    }
    if (!slides.length) return;
    this.setState((prevState) => {
      const newSlides = slides.map(slide => React.cloneElement(
        slide,
        {
          style: { ...defaultSlideStyle(this.state.unitMargin, this.props.slideContainerStyle) },
        },
      ));

      this.children = Array.isArray(this.children)
        ? this.children.concat(newSlides)
        : [this.children].concat(newSlides);
      this.props.storeSlides(this.children);

      return {
        sliderInitialized: disableMove || prevState.sliderInitialized,
        totalUnits: prevState.totalUnits + slides.length,
      };
    }, () => {
      this.setState((prevState) => {
        const currentSliderWidth = this.GetSliderWidth();

        const unitWidth = GetSlideWidth(this.innerSlide.childNodes[0], this.state.unitMargin);

        const visibleSlides = GetVisibleSlidesLength(this.innerSlide.childNodes.length, currentSliderWidth, unitWidth);

        const moveTo = disableMove ? 0 : prevState.totalUnits - prevState.currentIndex - visibleSlides;

        return {
          currentIndex: moveTo + prevState.currentIndex,
          visibleItems: visibleSlides,
        };
      }, () => { this.RearrangeSlides(); this.UpdateMaxWidthOfArrowSection(); });
    });
  }

  RemoveSlide(targetId) {
    const children = [];
    let selectedIndex = 0;
    React.Children.forEach(this.children, (child, index) => {
      if (child.props.id !== targetId) {
        children.push(child);
      } else {
        selectedIndex = index;
      }
    });
    this.children = children;
    this.props.storeSlides(this.children);
    this.setState(prevState => ({
      totalUnits: prevState.totalUnits - 1,
    }), () => {
      if (this.state.currentIndex && selectedIndex <= this.state.totalUnits) {
        this.HandleSliderMove(Direction.Prev);
      } else if (this.state.totalUnits) {
        this.RearrangeSlides();
      }
    });
  }

  HandleSliderMove(direction, shouldRearrangeSlides = true) {
    this.setState((prevState) => {
      const moveDirection = direction === Direction.Prev ? -1 : 1;
      let pxToMove = this.GetSliderWidth() / this.state.visibleItems;
      if (prevState.currentIndex) pxToMove *= prevState.currentIndex + moveDirection;
      this.MoveSlider(`translateX(-${ParseInt(pxToMove)}px)`);
      return {
        currentIndex: prevState.currentIndex + moveDirection,
      };
    }, () => {
      if (shouldRearrangeSlides) this.RearrangeSlides();
    });
  }

  MoveSlider(value) {
    this.innerSlide.style.transition = 'transform 300ms ease';
    this.innerSlide.style.transform = value;
  }

  OnPrevClick() {
    if (!this.props.disableArrows && this.state.currentIndex > 0) this.HandleSliderMove(Direction.Prev);
  }

  OnNextClick() {
    if (!this.props.disableArrows && this.state.visibleItems + this.state.currentIndex < this.state.totalUnits) this.HandleSliderMove(Direction.Next);
  }

  /* This is being called when Slider wrapper width is resized, slides are added or slide is removed to get the Slider position center */
  RearrangeSlides(
    currentSliderWidth = this.GetSliderWidth(),
    slideWidth = GetSlideWidth(this.innerSlide.childNodes[0], this.state.unitMargin),
  ) {
    const slideWithoutMargin = slideWidth - (this.state.unitMargin * numberOfStyleElements);
    this.setState((prevState) => {
      let visibleItems = prevState.visibleItems;
      const prevVisibleItems = visibleItems;

      /* Check if we need to update a number of slides to show when the slide wrapper is stretched or minimized */
      visibleItems = GetVisibleSlidesLength(
        this.innerSlide.childNodes.length,
        currentSliderWidth,
        slideWithoutMargin + (this.minimumUnitMargin * numberOfStyleElements),
      );
      const newMargin = (currentSliderWidth - (slideWithoutMargin * visibleItems)) / (visibleItems * numberOfStyleElements) || 0;
      let newIndex = prevState.currentIndex;

      /* If a number slides to show increased, update slide index ( which makes the slider move with correct translateX value ) */
      if (visibleItems > prevVisibleItems) {
        newIndex -= visibleItems - prevVisibleItems;
        if (newIndex < 0) newIndex = 0;
      }

      return {
        visibleItems,
        unitMargin: newMargin,
        currentIndex: newIndex,
      };
    }, () => {
      this.MoveSlider(`translateX(-${ParseInt((slideWithoutMargin + (this.state.unitMargin * numberOfStyleElements)) * this.state.currentIndex)}px)`);
    });
  }

  UpdateMaxWidthOfArrowSection(width = this.wrapperWidth) {
    if (this.slider) {
      const sliderWidth = this.GetSliderWidth();
      const numberOfArrows = 2;
      if (sliderWidth) {
        this.setState(prevState => ({
          maxArrowSectionWidth: Math.floor((width - (sliderWidth - (prevState.unitMargin * numberOfArrows))) / numberOfArrows),
        }));
      }
    }
  }

  GetClonedChildren() {
    let textStyle = {};
    const defaultStyle = defaultSlideStyle(this.state.unitMargin, this.props.slideContainerStyle);
    if (this.innerSlide && this.innerSlide.childNodes && this.innerSlide.childNodes.length > 0) {
      const unitWidth = GetSlideWidth(this.innerSlide.childNodes[0], this.state.unitMargin);
      const leftValue = `-${this.state.unitMargin}px`;
      textStyle = {
        ...defaultTextStyle,
        left: leftValue,
        width: `${unitWidth - textWidthOffset}px`,
      };
    }
    this.slides = React.Children.map(this.children, (child) => {
      const combinedStyle = child.props && child.props.style
        ? { ...child.props.style, ...defaultStyle }
        : defaultStyle;

      const component = this.props.showFileName
        ? (
          <div>
            {child}
            <span style={textStyle}>
              {child.props.fileName}
            </span>
          </div>
        )
        : <div>{child}</div>;
      return React.cloneElement(component, { style: combinedStyle });
    });
  }

  render() {
    this.GetClonedChildren();

    const defaultNodeStyle = { width: '100%', position: 'relative', overflow: 'hidden' };

    const nodeStyle = this.props.showFileName
      ? { ...defaultNodeStyle, paddingBottom: '20px' }
      : defaultNodeStyle;

    const slidesContainerStyle = {
      width: '100%',
      position: 'relative',
      height: this.slides.length > 0
        ? 'inherit'
        : 0,
    };

    return (
      <div style={wrapperStyle}>
        <ResizeObserver onResize={(wrapperRect) => {
          this.wrapperWidth = wrapperRect.width;
          this.UpdateMaxWidthOfArrowSection(wrapperRect.width);
        }}
        />
        <div style={slidesContainerStyle}>
          <div style={{ padding: '0px 15px', width: '100%' }}>
            <div ref={(node) => { this.slider = node; }} style={nodeStyle}>
              <ResizeObserver onResize={(rect) => {
                if (this.innerSlide && this.innerSlide.childNodes && this.innerSlide.childNodes.length > 0) {
                  this.RearrangeSlides(ParseInt(rect.width), GetSlideWidth(this.innerSlide.childNodes[0], this.state.unitMargin));
                }
              }}
              />
              <div ref={(node) => { this.innerSlide = node; }} style={slidesTrackStyle}>
                {this.slides}
              </div>
            </div>
          </div>

        </div>
        { this.slides && this.slides.length > 0 && (
          <ArrowButton
            direction="left"
            show={this.state.currentIndex > 0}
            maxArrowSectionWidth={this.state.maxArrowSectionWidth}
            OnClick={this.OnPrevClick}
          >
            {this.props.previousIcon}
          </ArrowButton>
        )}
        { this.slides && this.slides.length > 0 && (
          <ArrowButton
            direction="right"
            show={this.state.visibleItems + this.state.currentIndex < this.state.totalUnits}
            maxArrowSectionWidth={this.state.maxArrowSectionWidth}
            OnClick={this.OnNextClick}
          >
            {this.props.nextIcon}
          </ArrowButton>
        )}
      </div>
    );
  }
}

Slider.propTypes = {
  slideContainerStyle: PropTypes.shape({}),
  disableArrows: PropTypes.bool,
  totalUnits: PropTypes.number,
  showFileName: PropTypes.bool,
  events: PropTypes.shape({
    on: PropTypes.func,
    off: PropTypes.func,
    emit: PropTypes.func,
  }),
  nextIcon: PropTypes.node,
  previousIcon: PropTypes.node,
  readOnly: PropTypes.bool,
  storeSlides: PropTypes.func,
};

Slider.defaultProps = {
  slideContainerStyle: {},
  readOnly: false,
  disableArrows: false,
  showFileName: false,
  totalUnits: 0,
  events: null,
  nextIcon: <i style={{ fontSize: '30px' }} className="fa fa-angle-right" />,
  previousIcon: <i style={{ fontSize: '30px' }} className="fa fa-angle-left" />,
  storeSlides: () => {},
};

export default Slider;
