import React from 'react';
import PropTypes from 'prop-types';

const Tooltip = (Component) => {
  class TooltipComponent extends React.Component {
    constructor(props) {
      super(props);
      this.showTooltip = this.showTooltip.bind(this);
      this.hideTooltip = this.hideTooltip.bind(this);
      this.state = {
        show: false,
      };
    }

    showTooltip() {
      this.setState({
        show: true,
      });
    }

    hideTooltip() {
      this.setState({
        show: false,
      });
    }

    render() {
      if (!this.props.tooltipText) {
        return <Component {...this.props} />;
      }
      return (
        <div
          className="field-tip"
          onMouseLeave={this.hideTooltip}
          onMouseOver={this.showTooltip}
          onFocus={this.showTooltip}
        >
          {
                        this.props.tooltipText
                        && this.state.show
                        && (<span className="tip-content">{this.props.tooltipText}</span>)
                    }
          <Component {...this.props} />
        </div>
      );
    }
  }

  TooltipComponent.propTypes = {
    tooltipText: PropTypes.string,
  };

  TooltipComponent.defaultProps = {
    tooltipText: null,
  };

  return TooltipComponent;
};

export default Tooltip;
