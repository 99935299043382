export const EncryptionType = Object.freeze({
  Unknown: 'Unknown',
  None: 'None',
  Static: 'Static',
  Transient: 'Transient',
});

export const GetEncryptionType = encryptionType => (EncryptionType[encryptionType] ? EncryptionType[encryptionType] : EncryptionType.Unknown);

export default EncryptionType;
