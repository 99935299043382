class NotFoundError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NotFoundError';
    // Babel has issues when extending native classes so we need to fix the this property.
    // This is a limitation due to the compilation of ES6 to ES5.
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}

export default NotFoundError;
