import React from 'react';
import { DropTarget } from 'react-dnd';
import PropTypes from 'prop-types';
import '../../style.scss';

const DeleteDropTarget = (BaseComponent) => {
  const deleteTarget = {
    drop(props, monitor) {
      const item = monitor.getItem();
      props.OnSlideDrop(item && item.id);
    },
  };

  function collect(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget(),
      highlighted: monitor.canDrop(),
    };
  }

  class DeleteDropTargetComponent extends React.Component {
    render() {
      const dropzoneBorder = this.props.highlighted
        ? 'dropzoneHighlighOutline'
        : 'dropzoneOutline';

      return this.props.connectDropTarget(<div><BaseComponent {...this.props} className={dropzoneBorder} /></div>);
    }
  }

  DeleteDropTargetComponent.propTypes = {
    connectDropTarget: PropTypes.func,
    highlighted: PropTypes.bool,
  };

  DeleteDropTargetComponent.defaultProps = {
    connectDropTarget: null,
    highlighted: false,
  };

  return DropTarget('file', deleteTarget, collect)(DeleteDropTargetComponent);
};

export default DeleteDropTarget;
