import React from 'react';
import PropTypes from 'prop-types';

const TooltipIcon = props => props.tooltip && (
  <sup className="sprite supTooltipIcon" onClick={props.onTooltipClick} data-value={props.id}>
    {'\uf05a'}
  </sup>
);


TooltipIcon.propTypes = {
  id: PropTypes.string,
  tooltip: PropTypes.string,
  onTooltipClick: PropTypes.func,
};

TooltipIcon.defaultProps = {
  tooltip: null,
  id: null,
  onTooltipClick: null,
};

export default TooltipIcon;
