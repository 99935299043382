import _ from 'lodash';
import Request from './request';

const defaultBatchSize = 20;

class Requests {
  constructor(requests = [], isListBySearch = false) {
    this.requests = Array.isArray(requests)
      ? requests.map(request => new Request(request))
      : [];
    this.IsListBySearch = isListBySearch;
    this.filter = null;
    this.sortOption = null;
  }

  get RequestIdsForBatch() {
    return this.requests.length
      ? _.chunk(this.requests.map(request => request.id), defaultBatchSize).map(ids => ids.join(' , '))
      : [];
  }

  get HasRequests() {
    return this.requests.length > 0;
  }

  get EmptyListMessage() {
    return this.IsListBySearch
      ? 'No results found, try another search'
      : 'You have no active or archived requests, select +Add to get started';
  }

  SetSortBy(option) {
    this.sortOption = option;
  }

  SetLastConversationTime(conversationList) {
    this.requests.forEach((request) => {
      const conversation = conversationList.find(localRequest => localRequest.Id === request.id);
      if (conversation) request.SetLastConversationTime(conversation);
    });
  }

  SetConversationLoadingStatus(status) {
    this.requests.forEach((request) => {
      request.SetConversationLoadingStatus(status);
    });
  }

  SetFilter(filter) {
    this.filter = filter;
  }

  get Requests() {
    if (this.sortOption) {
      this.requests = _.orderBy(this.requests, [this.sortOption.value], ['desc']);
    }
    return this.requests;
  }
}

export default Requests;
