import React from 'react';
import PropTypes from 'prop-types';

const Error = (props) => {
  let errorContainerStyle = {
    backgroundColor: '#F2F2F2',
  };

  if (props.containerStyle) {
    errorContainerStyle = props.containerStyle;
  }


  const inputStyle = {
    color: '#f44336',
    WebkitAnimation: 'opac 0.8s',
    animation: 'opac 0.8s',
    textAlign: 'center',
  };
  return (
    <div style={errorContainerStyle}>
      <div style={inputStyle} className="w3-left-align">{props.errorMessage}</div>
    </div>
  );
};

Error.propTypes = {
  errorMessage: PropTypes.string,
  containerStyle: PropTypes.shape({}), // Style
};

Error.defaultProps = {
  errorMessage: '',
  containerStyle: null,
};

export default Error;
