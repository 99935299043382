

const message1 = require('./message');
/**
 * A conversation maps to a conversation on the server as returned by the API.
 */
const Conversation = (function ConversationWrapper() {
  function ConversationConstructor() {
    this.MessageList = [];
  }
  ConversationConstructor.fromObject = function fromObject(source) {
    const convo = new ConversationConstructor();
    convo.Id = source.Id;
    convo.VersionDate = new Date(source.VersionDate);
    for (let i = 0; i < source.MessageList.length; ++i) {
      const msg = source.MessageList[i];
      const message = message1.Message.fromObject(msg);
      convo.MessageList.push(message);
    }
    return convo;
  };
  return ConversationConstructor;
}());
exports.Conversation = Conversation;
