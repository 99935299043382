import PropTypes from 'prop-types';
import React from 'react';
import ImageComponent from './ImageComponent';
import { FILE_TYPE } from './constants';

/** Pass attachment payload as a prop and render images

    - Sample Payload -

      const payload =  {
          "Thumbnail": {
            "Uri": "https://www.gravatar.com/avatar/8af38a7f551548f186aaaa99888ed91e?d=mm&s=46&r=r",
            "UriExpiry": "",
            "EncryptionKey": "Yc6De94rCbk3ZOIVHsFa3Q==",
            "Size": 0,
            "EncryptionType": "None"
          },
          "VersionDate": "2018-05-03T02:03:58.841Z",
          "VersionBy": "string",
          "CreatedDate": "2018-05-03T02:03:58.841Z",
          "CreatedBy": "string",
          "Id": "00000001-0000-0000-0000-000000000001",
          "Key": "string",
          "File": {
            "Uri": "https://www.gravatar.com/avatar/8af38a7…?d=mm&s=36&r=r",
            "UriExpiry": "2018-05-03T02:03:58.841Z",
            "EncryptionKey": "Yc6De94rCbk3ZOIVHsFa3Q==",
            "Size": 0,
            "EncryptionType": "Static"
          },
          "Keywords": "string",
          "Display": "string",
          "AttachedItemReference": "00000000-0000-0000-0000-000000000000",
          "AttachedItemType": "string",
          "FileType": "Image",
          "SyncMode": 0,
          "IsActive": true
        }

    - Sample Usage -

      By default, it renders thumbnail if exists , if not , render full size image
      <ImageProvider attachments={payload}/>

      If we want a full size image instead of thumbnail, pass prop
      <ImageProvider render={ImageProvider.FILE_TYPE.IMAGE} attachments={payload}/>

 */

const getAttachments = (payload, ...rest) => {
  const results = {};
  if (payload) {
    payload.forEach((attachment) => {
      if (attachment && rest.includes(attachment.FileType)) {
        results[attachment.FileType] = attachment;
      }
    });
  }

  return results;
};


class ImageProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
    };

    /**
     *  Stores current attachment Id
     */
    this.attachment = {};
  }

  componentDidMount() {
    this.renderImage(this.props.attachment).then((result) => {
      this.updateState(result);
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    /**
     *  If incoming attachment payload is different from previous one, re-render images
     */
    if (!this.attachment[props.attachment.Id]) {
      this.attachment = {};
      this.renderImage(props.attachment).then((result) => {
        this.updateState(result);
      });
    }
  }

  updateState(result, callback = null) {
    /**
    *  To render a full size image instead of thumbnail, we pass prop
    */

    let imageToRender = null;

    if (this.props.render) {
      if (this.props.render === FILE_TYPE.IMAGE) {
        imageToRender = result.File ? result.File : result.Thumbnail;
      }
    } else {
      /* default , we render thumbnail if exists , otherwise full size image */
      imageToRender = result.Thumbnail ? result.Thumbnail : result.File;
    }

    this.setState({
      image: imageToRender,
    }, () => {
      if (callback) { /* Added for unit testing , updateState method is ran after promise resolve  */
        callback();
      }
    });
  }

  /**
    * Updates this.attachment object with new attachment id
    * @param {Object} attachment
    */
  updateAttachmentId(attachment) {
    if (attachment) {
      this.attachment[attachment.Id] = true;
    }
  }


  renderImage(attachment) {
    this.updateAttachmentId(attachment);
    return ImageComponent.getImages(attachment, this.props.emptyImage);
  }

  render() {
    return (
      <span>
        {this.state.image}
      </span>
    );
  }
}

ImageProvider.FILE_TYPE = FILE_TYPE;

ImageProvider.getAttachments = getAttachments;

ImageProvider.propTypes = {
  attachment: PropTypes.shape({
    Thumbnail: PropTypes.shape({
      Uri: PropTypes.string,
      UriExpiry: PropTypes.string,
      EncryptionKey: PropTypes.string,
      Size: PropTypes.number,
      EncryptionType: PropTypes.string,
    }),
    VersionDate: PropTypes.string,
    VersionBy: PropTypes.string,
    CreatedDate: PropTypes.string,
    CreatedBy: PropTypes.string,
    Id: PropTypes.string,
    Key: PropTypes.string,
    File: PropTypes.shape({
      Uri: PropTypes.string,
      UriExpiry: PropTypes.string,
      EncryptionKey: PropTypes.string,
      Size: PropTypes.number,
      EncryptionType: PropTypes.string,
    }),
    Keywords: PropTypes.string,
    Display: PropTypes.string,
    AttachedItemReference: PropTypes.string,
    AttachedItemType: PropTypes.string,
    FileType: PropTypes.string,
    SyncMode: PropTypes.string,
    IsActive: PropTypes.bool,
  }),
  emptyImage: PropTypes.string,
  render: PropTypes.string,
};

ImageProvider.defaultProps = {
  attachment: {},
  emptyImage: null,
  render: null,
};

export default ImageProvider;
