import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NavigationButton from '../components/navigationButton';
import Thumbnail from '../components/thumbnail';
import RequestTile from './requestTile';
import Request from '../../../modules/ScoutModels/models/request';
import RequestSummary from './requestSummary';
import RequestStatus from './requestStatus';

const divOpenButtonStyle = {
  right: '0px',
  position: 'absolute',
  bottom: '0px',
};

const contentWrapperStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginRight: '5px',
};

class ListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestID: this.props.request.id,
      hoverShowOpenButton: false,
    };
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
  }

  onMouseLeave() {
    this.setState({ hoverShowOpenButton: false });
  }

  onMouseEnter() {
    this.setState({ hoverShowOpenButton: true });
  }

  render() {
    let showHighlight = false;
    let showOpenButton = false;

    // logic for onMouse Hover
    if (this.state.hoverShowOpenButton) {
      showHighlight = true;
      showOpenButton = true;
    }

    const openButtonStyle = showOpenButton ? {} : { display: 'none' };
    const highlightedClass = showHighlight ? 'card-highlighted' : '';

    return (
      <RequestTile
        className={highlightedClass}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onMouseEnter}
      >
        <div style={contentWrapperStyle}>
          <RequestSummary
            request={this.props.request}
            SearchTerm={this.props.SearchTerm}
          />
          <RequestStatus
            request={this.props.request}
            SearchTerm={this.props.SearchTerm}
          />
        </div>
        <div className="thumbnail">
          <Thumbnail id={this.props.request.id} />
        </div>
        <div className="w3-right" style={divOpenButtonStyle}>
          <div style={openButtonStyle}>
            <NavigationButton icon="Open" label="Open" destination={`/requests/details/${this.state.requestID}`} />
          </div>
        </div>
      </RequestTile>
    );
  }
}

ListItem.propTypes = {
  request: PropTypes.instanceOf(Request),
  SearchTerm: PropTypes.string,
};

ListItem.defaultProps = {
  request: null,
  SearchTerm: '',
};

export default ListItem;
