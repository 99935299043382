import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import Toaster, { TOAST_ERROR } from '../../../../modules/common/toaster/toaster';
import CommonFetch from '../../../utils/commonFetch';
import NavigationButton from '../../components/navigationButton';
import { PreviewAttachment as Attachment } from '../../../../modules/attachment/Attachment';
import { SyncMode } from '../../../../modules/attachment/model/attachment/SyncMode';
import WizardField, { WizardFieldType } from './controls/WizardField';
import Sprite from '../../../components/sprite';
import { FieldName } from '../../../../modules/ScoutModels/models/field';
import DescriptionField from './controls/descriptionField';
import FormLabel from './controls/formLabel';
import Request from '../../../../modules/ScoutModels/models/request';
import RadioButton from '../../components/radioButton';
import JobTypeSection from './controls/jobTypeSection';
import JobTypes from '../../../../modules/ScoutModels/models/jobTypes';
import events from '../../../../modules/attachment/ImageSlider/events';
import TooltipIcon from '../../components/tooltipIcon';
import TooltipText from '../../components/tooltipText';

// stylesconst
const textAreaStyle = { height: '100px', width: '100%', whiteSpace: 'pre-wrap' };
const consentCaptionStyle = { width: '100%', whiteSpace: 'pre-wrap', marginTop: '8px' };
const bottomLabelStyle = { marginTop: '8px', width: '150px' };

const ohsFormLabel = {
  minWidth: 0,
};

// buttons
const attachmentIconSize = '14px';
const attachmentSlideIconSize = '20px';

const allowedAttachments = 'image/png,image/gif,image/jpeg,image/bmp,image/webp,text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

class Step2 extends Component {
  static initialiseOption(options, text, handleChange) {
    const selectedOption = options.find(option => text && option.text.toUpperCase() === text.toUpperCase());
    if (selectedOption) {
      handleChange({ ...selectedOption, isValid: true }, !!text);
    } else {
      handleChange({ text, isValid: false }, !!text);
    }
  }

  constructor(props) {
    super(props);
    this.internalTitleField = null;
    this.internalDescriptionField = null;
    this.internalAttachmentsField = null;
    this.internalJobTaskField = null;
    this.internalJobTypeField = null;
    this.internalJobSubTypeField = null;
    this.internalOhsIssueField = null;
    this.internalReferenceNumberField = null;
    this.numberOfAttachments = null;
    this.internalConsentField = null;
    this.internalConsentMessageField = null;
    this.internalConsentMessage = '';

    this.event = events();

    this.state = {
      jobTasksList: null,
      jobTypes: null,
      jobSubTypes: null,
      loading: false,
      typeListShown: '',
      isDirty: false,
      titleTouched: false,
      descriptionTouched: false,
      attachmentsTouched: false,
      jobTaskTouched: false,
      jobTaskOptionValid: false,
      jobTypeTouched: false,
      jobTypeOptionValid: false,
      jobSubTypeTouched: false,
      ohsIssueData: null,
      jobSubTypeOptionValid: false,
      referenceNumberTouched: false,
      showDescriptionClicked: false,
      showDescription: false,
      showTooltip: '',
      consentData: null,
      consentMessageTouched: false,
    };

    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleJobTypeChange = this.handleJobTypeChange.bind(this);
    this.handleJobSubTypeChange = this.handleJobSubTypeChange.bind(this);
    this.onShowLineClick = this.onShowLineClick.bind(this);
    this.nextButtonCallbackFunction = this.nextButtonCallbackFunction.bind(this);
    this.divListHandler = this.divListHandler.bind(this);
    this.handleJobTaskChange = this.handleJobTaskChange.bind(this);
    this.handleOhsIssueChange = this.handleOhsIssueChange.bind(this);
    this.handleReferenceNumberChange = this.handleReferenceNumberChange.bind(this);
    this.onTooltipClick = this.onTooltipClick.bind(this);
    this.handleConsentChangeEvent = this.handleConsentChangeEvent.bind(this);
    this.handleConsentMessageChange = this.handleConsentMessageChange.bind(this);
  }

  componentDidMount() {
    if (this.props.request.ohsIssue !== undefined) {
      this.setState({
        ohsIssueData: this.props.request.ohsIssue ? 'yes' : 'no',
      });
    }
    if (this.props.request.consent !== undefined) {
      if (this.props.request.consentMessage !== undefined && !this.props.request.consent) {
        this.internalConsentMessage = this.props.request.consentMessage;
      }
      this.handleConsentChange(this.props.request.consent ? 'yes' : 'no');
    }

    this.loadJobTasks();
    this.loadJobTypes();
  }

  componentWillUnmount() {
    this.props.saveRequest(this.props.request);
  }

  onShowLineClick() {
    this.setState(prevState => ({
      showDescriptionClicked: true,
      showDescription: !prevState.showDescription,
    }));
  }

  onTooltipClick(event) {
    event.stopPropagation();
    const target = event.target;
    this.setState(prevState => ({ showTooltip: prevState.showTooltip === target.getAttribute('data-value') ? '' : target.getAttribute('data-value') }));
  }

  get nextButtonEnabled() {
    /* eslint-disable-next-line max-len */ // Going to leave this here, probably be useful in the future.
    // console.log(`[titleValid:${this.titleValid}][descriptionValid:${this.descriptionValid}][attachmentsValid:${this.attachmentsValid}][jobTaskValid:${this.jobTaskValid}][ohsIssueValid:${this.ohsIssueValid}][referenceNumberValid:${this.referenceNumberValid}][jobSubTypeValid:${this.jobSubTypeValid}][jobTypeValid:${this.jobTypeValid}][consentValid:${this.consentValid}][consentMessageValid:${this.consentMessageValid}]`);
    return this.titleValid
      && this.descriptionValid
      && this.attachmentsValid
      && this.jobTaskValid
      && this.ohsIssueValid
      && this.referenceNumberValid
      && this.jobSubTypeValid
      && this.jobTypeValid
      && this.consentValid
      && this.consentMessageValid;
  }

  get titleField() {
    if (!this.internalTitleField) {
      this.internalTitleField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.Title);
    }
    return this.internalTitleField;
  }

  get titleValid() {
    return this.titleField.IsValid(this.props.request.title);
  }

  get descriptionField() {
    if (!this.internalDescriptionField) {
      this.internalDescriptionField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.Description);
    }
    return this.internalDescriptionField;
  }

  get descriptionValid() {
    return this.descriptionField.IsValid(this.props.request.description);
  }

  get attachmentsField() {
    if (!this.internalAttachmentsField) {
      this.internalAttachmentsField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.Attachments);
    }
    return this.internalAttachmentsField;
  }

  get attachmentsValid() {
    return !this.attachmentsField.required || (this.attachmentsField.required && this.numberOfAttachments > 0);
  }

  get jobTaskField() {
    if (!this.internalJobTaskField) {
      this.internalJobTaskField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.JobTask);
    }
    return this.internalJobTaskField;
  }

  get jobTaskValid() {
    return (!this.jobTaskField.required || this.state.jobTaskOptionValid)
      && (this.state.jobTaskOptionValid || !this.props.request.jobTask || this.props.request.jobTask.length === 0)
      && this.jobTaskField.IsValid(this.props.request.jobTask);
  }

  get jobTypeField() {
    if (!this.internalJobTypeField) {
      this.internalJobTypeField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.JobType);
    }
    return this.internalJobTypeField;
  }

  get jobTypeValid() {
    return (!this.jobTypeField.required || this.state.jobTypeOptionValid)
      && (this.state.jobTypeOptionValid || !this.props.request.jobType || this.props.request.jobType.length === 0)
      && this.jobTypeField.IsValid(this.props.request.jobType);
  }

  get jobSubTypeField() {
    if (!this.internalJobSubTypeField) {
      this.internalJobSubTypeField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.JobSubType);
    }
    return this.internalJobSubTypeField;
  }

  get jobSubTypeValid() {
    return (this.props.request.jobType && this.jobTypeValid && this.props.request.jobSubType && this.state.jobSubTypeOptionValid)
      || (!this.props.request.jobType && !this.jobTypeField.required && !this.props.request.jobSubType);
  }

  get ohsIssueField() {
    if (!this.internalOhsIssueField) {
      this.internalOhsIssueField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.OHSIssue);
    }
    return this.internalOhsIssueField;
  }

  get ohsIssueValid() {
    return this.ohsIssueField.IsValid(this.props.request.ohsIssue);
  }

  get consentField() {
    if (!this.internalConsentOptionField) {
      this.internalConsentOptionField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.Consent);
    }
    return this.internalConsentOptionField;
  }

  get consentValid() {
    return this.consentField.IsValid(this.props.request.consent);
  }

  get consentMessageField() {
    if (!this.internalConsentMessageField) {
      this.internalConsentMessageField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.ConsentMessage);
    }
    return this.internalConsentMessageField;
  }

  get consentMessageValid() {
    return this.consentField.required || this.props.request.consent !== undefined
      ? this.consentMessageField.IsValid(this.props.request.consentMessage) : true;
  }

  get referenceNumberField() {
    if (!this.internalReferenceNumberField) {
      this.internalReferenceNumberField = this.props.request.requestType.fields.GetFieldOrEmpty(FieldName.ReferenceNumber);
    }
    return this.internalReferenceNumberField;
  }

  get referenceNumberValid() {
    return this.referenceNumberField.IsValid(this.props.request.referenceNumber);
  }

  async loadJobTasks() {
    if (this.jobTaskField.shown) {
      try {
        const jobTaskOptions = await CommonFetch.getJobTasks(this.props.request.requestType.jobTaskCategories);
        if (jobTaskOptions) {
          this.setState({
            jobTasksList: jobTaskOptions,
          }, () => {
            Step2.initialiseOption(
              this.state.jobTasksList,
              this.props.request.jobTask ? this.props.request.jobTask : '',
              this.handleJobTaskChange,
            );
          });
        }
      } catch (error) {
        console.error(error);
        Toaster.pop('There was an error getting job tasks.', TOAST_ERROR);
      }
    }
  }

  async loadJobTypes() {
    if (this.jobTypeField.shown) {
      try {
        const jobtypes = await CommonFetch.getJobTypes();
        if (jobtypes) {
          const localJobTypes = new JobTypes(jobtypes);
          this.setState({
            jobTypes: localJobTypes,
            jobSubTypes: [],
          }, () => {
            if (this.props.request.jobType) {
              Step2.initialiseOption(
                this.state.jobTypes.JobSubTypeOptions(this.props.request.jobType),
                this.props.request.jobSubType,
                this.handleJobSubTypeChange,
              );
            }
            Step2.initialiseOption(
              this.state.jobTypes.JobTypeOptions,
              this.props.request.jobType ? this.props.request.jobType : '',
              this.handleJobTypeChange,
            );
          });
        }
      } catch (error) {
        console.error(error);
        Toaster.pop('There was an error getting job types.', TOAST_ERROR);
      }
    }
  }

  handleTitleChange(value) {
    this.props.request.title = value;
    this.setState({ titleTouched: true });
  }

  handleDescriptionChange(value) {
    this.props.request.description = value;
    this.setState({ descriptionTouched: true });
  }

  handleAttachmentChange(value, attachments) {
    this.props.request.attachments = attachments;
    if (this.numberOfAttachments !== null) {
      this.setState({ attachmentsTouched: true });
    }
    this.numberOfAttachments = value;
  }

  handleJobTaskChange(option, jobTaskTouched = true) {
    this.props.request.jobTask = option.text;
    this.setState({ jobTaskTouched, jobTaskOptionValid: option.isValid });
  }

  handleJobTypeChange(option, jobTypeTouched = true) {
    if (this.props.request.jobType !== option.text || !this.props.request.jobType) {
      this.props.request.jobSubType = '';
    }
    this.props.request.jobType = option.text;
    this.setState(prevState => ({
      jobTypeTouched,
      jobTypeOptionValid: option.isValid,
      jobSubTypes: prevState.jobTypes.JobSubTypeOptions(option.text),
    }));
  }

  handleJobSubTypeChange(option, jobSubTypeTouched = true) {
    this.props.request.jobSubType = option.text;
    this.setState({ jobSubTypeTouched, jobSubTypeOptionValid: option.isValid });
  }

  handleOhsIssueChange(event) {
    if (event) {
      const value = event.target.id;
      this.props.request.ohsIssue = value === 'yes';
      this.setState({ ohsIssueData: value });
    }
  }

  handleConsentChangeEvent(event) {
    if (event) {
      const value = event.target.id;
      this.handleConsentChange(value);
    }
  }

  handleConsentChange(value) {
    this.props.request.consent = value === 'yes';
    this.setState({ consentData: value });
    if (this.props.request.consent) {
      this.props.request.consentMessage = this.consentField.note;
    } else {
      this.props.request.consentMessage = this.internalConsentMessage;
    }
  }

  handleConsentMessageChange(value) {
    this.props.request.consentMessage = value;
    this.setState({ consentMessageTouched: true });
    this.internalConsentMessage = value;
  }

  handleReferenceNumberChange(value) {
    this.props.request.referenceNumber = value;
    this.setState({ referenceNumberTouched: true });
  }

  divListHandler(type) {
    this.setState({
      typeListShown: type,
    });
  }

  nextButtonCallbackFunction(destination) {
    this.props.history.replace(destination);
  }

  // eslint is saying that this render method is too complex, but it is not. It is just long.
  // eslint-disable-next-line complexity
  render() {
    const nextDestination = this.props.isEdit ? `/requests/edit/3/${this.props.editRequestID}` : '/requests/new/3';
    const backButton = this.props.isEdit
      ? { icon: 'Open', label: 'Cancel', destination: `/requests/details/${this.props.editRequestID}` }
      : { icon: 'Back', label: 'Back', destination: '/requests/new/1' };
    const nextButton = { icon: 'Next', label: 'Next', destination: nextDestination };
    return (
      <div className="stepform">
        <h5>
          <b>What is the problem? </b>
          {this.titleField.shown && (<TooltipIcon tooltip={this.titleField.tooltip} id={this.titleField.id} onTooltipClick={this.onTooltipClick} />)}
        </h5>
        <div className={`${this.state.loading ? 'w3-disabled ' : ''}fields`}>
          {this.titleField.shown && (
            <div>
              <TooltipText
                show={this.state.showTooltip === this.titleField.id}
                id={this.titleField.id}
                tooltip={this.titleField.tooltip}
                onTooltipClick={this.onTooltipClick}
              />
              <textarea
                id="txtTitle"
                style={textAreaStyle}
                placeholder={`Enter ${this.titleField.captionOrDefault}...`}
                value={this.props.request.title}
                onChange={event => this.handleTitleChange(event.target.value)}
                name="Title"
                required={this.titleField.required}
                title={this.titleField.required ? 'Please fill out this field.' : ''}
                className={this.titleValid || !this.state.titleTouched ? 'validInput' : 'invalidInput'}
              />
              <TooltipText
                show={!this.titleValid && this.state.titleTouched}
                tooltip={this.titleField.patternValidationFailedMessage
                  || (this.titleField.required ? `*${this.titleField.captionOrDefault} is required.` : undefined)}
                customClass="tooltipWarning"
              />
            </div>
          )}
          <DescriptionField
            descriptionField={this.descriptionField}
            showDescriptionClicked={this.state.showDescriptionClicked}
            showDescription={this.state.showDescription}
            onShowLineClick={this.onShowLineClick}
            textAreaStyle={textAreaStyle}
            description={this.props.request.description}
            handleChange={this.handleDescriptionChange}
            descriptionValid={this.descriptionValid}
            descriptionTouched={this.state.descriptionTouched}
            isDirty={this.state.isDirty}
          />
          {this.attachmentsField.shown && (
            <div className="attachment-wrapper" style={{ marginTop: '8px' }}>
              <FormLabel caption={this.attachmentsField.captionOrDefault} required={this.attachmentsField.required} />
              <TooltipIcon tooltip={this.attachmentsField.tooltip} id={this.attachmentsField.id} onTooltipClick={this.onTooltipClick} />
              <TooltipText
                show={this.state.showTooltip === this.attachmentsField.id}
                id={this.attachmentsField.id}
                tooltip={this.attachmentsField.tooltip}
                onTooltipClick={this.onTooltipClick}
              />
              <TooltipText
                show={!this.attachmentsValid && this.state.attachmentsTouched}
                tooltip={this.attachmentsField.patternValidationFailedMessage
                  || (this.attachmentsField.required ? `*${this.attachmentsField.captionOrDefault} is required.` : undefined)}
                customClass="tooltipWarning"
              />
              <Attachment
                attachmentWrapperClass="attachmentDiv"
                accept={allowedAttachments}
                attachedItemReference={this.props.request.id}
                attachedItemType="ServiceRequest"
                trashIcon={<Sprite icon="Trash" fontSize={attachmentIconSize} />}
                nextIcon={<Sprite icon="Next" fontSize={attachmentSlideIconSize} />}
                previousIcon={<Sprite icon="Back" fontSize={attachmentSlideIconSize} />}
                uploadIcon={<Sprite icon="Upload" fontSize={attachmentIconSize} />}
                showFileName
                StateChange={state => this.handleAttachmentChange(state.totalAttachments, state.attachments)}
                syncMode={SyncMode.AvailableOffline}
                deferredCommit
                localAttachments={this.props.request.attachments}
              />
            </div>
          )}
          {this.consentField.shown && this.consentField.note && (
            <div style={consentCaptionStyle}>
              <FormLabel caption={this.consentField.note} style={ohsFormLabel} />
            </div>
          )}
          {this.consentField.shown && (
            <div>
              <div className="radioButton">
                <div style={bottomLabelStyle}>
                  <FormLabel caption={this.consentField.captionOrDefault} style={ohsFormLabel} required={this.consentField.required} />
                </div>
                <div className="radioButton">
                  <label htmlFor="yes" style={{ display: 'flex' }}>
                    Yes
                    <RadioButton
                      onSelectHandler={this.handleConsentChangeEvent}
                      valueData="yes"
                      selectedData={this.state.consentData}
                    />
                  </label>
                  <label htmlFor="no" style={{ display: 'flex' }}>
                    No
                    <RadioButton
                      onSelectHandler={this.handleConsentChangeEvent}
                      valueData="no"
                      selectedData={this.state.consentData}
                    />
                  </label>
                  <TooltipIcon tooltip={this.consentField.tooltip} id={this.consentField.id} onTooltipClick={this.onTooltipClick} />
                </div>
              </div>
              <TooltipText
                show={this.state.showTooltip === this.consentField.id}
                id={this.consentField.id}
                tooltip={this.consentField.tooltip}
                onTooltipClick={this.onTooltipClick}
              />
            </div>
          )}
          {this.consentMessageField.shown && this.props.request.consent !== undefined && !this.props.request.consent && (
            <div>
              <FormLabel style={{ minWidth: '0px', marginTop: '8px' }} caption={this.consentMessageField.captionOrDefault} required={this.consentMessageField.required} />
              <TooltipIcon tooltip={this.consentMessageField.tooltip} id={this.consentMessageField.id} onTooltipClick={this.onTooltipClick} />
              <TooltipText
                show={this.state.showTooltip === this.consentMessageField.id}
                id={this.consentMessageField.id}
                tooltip={this.consentMessageField.tooltip}
                onTooltipClick={this.onTooltipClick}
              />
              <textarea
                id="txtConsentMessage"
                style={textAreaStyle}
                placeholder={`Enter ${this.consentMessageField.captionOrDefault}...`}
                value={this.props.request.consentMessage}
                onChange={event => this.handleConsentMessageChange(event.target.value)}
                name="ConsentMessage"
                required={this.consentMessageField.required}
                title={this.consentMessageField.required ? 'Please fill out this field.' : ''}
                className={this.consentMessageValid || !this.state.consentMessageTouched ? 'validInput' : 'invalidInput'}
              />
              <TooltipText
                show={!this.consentMessageValid && this.state.consentMessageTouched}
                tooltip={this.consentMessageField.patternValidationFailedMessage
                  || (this.consentMessageField.required ? `*${this.consentMessageField.captionOrDefault} is required.` : undefined)}
                customClass="tooltipWarning"
              />
            </div>
          )}
          <WizardField
            wizardFieldType={WizardFieldType.Autocomplete}
            type={FieldName.JobTask}
            field={this.jobTaskField}
            optionsData={this.state.jobTasksList}
            parentVal={{ text: this.props.request.jobTask }}
            valueChanged={option => this.handleJobTaskChange(option)}
            event={this.event}
            divShown={this.divListHandler}
            showOpt={this.state.typeListShown === FieldName.JobTask}
            isValid={this.jobTaskValid || !this.state.jobTaskTouched}
            showTooltip={this.state.showTooltip === this.jobTaskField.id}
            showError={!this.jobTaskValid && this.state.jobTaskTouched}
            onTooltipClick={this.onTooltipClick}
          />
          {this.jobTypeField.shown
            && (
              <JobTypeSection
                event={this.event}
                jobTypeField={this.jobTypeField}
                jobTypes={this.state.jobTypes}
                divListHandler={this.divListHandler}
                jobTypeValid={this.jobTypeValid || !this.state.jobTypeTouched}
                jobTypeTouched={this.state.jobTypeTouched}
                jobSubTypeField={this.jobSubTypeField}
                request={this.props.request}
                divShown={this.divListHandler}
                handleJobTypeChange={this.handleJobTypeChange}
                handleJobSubTypeChange={this.handleJobSubTypeChange}
                jobSubTypeValid={this.jobSubTypeValid || !this.state.jobSubTypeTouched}
                jobSubTypes={this.state.jobSubTypes}
                jobSubTypeTouched={this.state.jobSubTypeTouched}
              />
            )
          }
          {this.ohsIssueField.shown && (
            <div>
              <div className="radioButton">
                <div style={bottomLabelStyle}>
                  <FormLabel caption={this.ohsIssueField.captionOrDefault} style={ohsFormLabel} required={this.ohsIssueField.required} />
                </div>
                <div className="radioButton">
                  <label htmlFor="yes" style={{ display: 'flex' }}>
                    Yes
                    <RadioButton
                      onSelectHandler={this.handleOhsIssueChange}
                      valueData="yes"
                      selectedData={this.state.ohsIssueData}
                    />
                  </label>
                  <label htmlFor="no" style={{ display: 'flex' }}>
                    No
                    <RadioButton
                      onSelectHandler={this.handleOhsIssueChange}
                      valueData="no"
                      selectedData={this.state.ohsIssueData}
                    />
                  </label>
                  <TooltipIcon tooltip={this.ohsIssueField.tooltip} id={this.ohsIssueField.id} onTooltipClick={this.onTooltipClick} />
                </div>
              </div>
              <TooltipText
                show={this.state.showTooltip === this.ohsIssueField.id}
                id={this.ohsIssueField.id}
                tooltip={this.ohsIssueField.tooltip}
                onTooltipClick={this.onTooltipClick}
              />
            </div>
          )}
          <WizardField
            wizardFieldType={WizardFieldType.Input}
            type={FieldName.ReferenceNumber}
            field={this.referenceNumberField}
            parentVal={{ text: this.props.request.referenceNumber }}
            valueChanged={this.handleReferenceNumberChange}
            divShown={this.divListHandler}
            showOpt={this.state.typeListShown === FieldName.ReferenceNumber}
            isValid={this.referenceNumberValid || !this.state.referenceNumberTouched}
            showTooltip={this.state.showTooltip === this.referenceNumberField.id}
            showError={!this.referenceNumberValid && this.state.referenceNumberTouched}
            onTooltipClick={this.onTooltipClick}
            className={this.referenceNumberValid || !this.state.referenceNumberTouched ? 'validInput' : 'invalidInput'}
            maxLength={40}
          />
        </div>
        <div className="button-group">
          <div className="w3-left back">
            <NavigationButton icon={backButton.icon} label={backButton.label} destination={backButton.destination} />
          </div>
          <div className="w3-right next">
            {this.state.loading ? (
              <div style={{ right: '25px', position: 'relative' }}>
                <div className="wrapper">
                  <div className="cssload-loader" />
                </div>
              </div>
            ) : (
              <NavigationButton
                icon={nextButton.icon}
                label={nextButton.label}
                destination={nextButton.destination}
                iconOnLeft={false}
                enabled={this.nextButtonEnabled}
                onClick={this.nextButtonCallbackFunction}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Step2.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  request: PropTypes.instanceOf(Request).isRequired,
  isEdit: PropTypes.bool.isRequired,
  saveRequest: PropTypes.func.isRequired,
  editRequestID: PropTypes.string,
};

Step2.defaultProps = {
  editRequestID: '',
};

export default withRouter(Step2);
