export const Status = Object.freeze({
  Unknown: 'Unknown',
  Any: 'Any',
  Waiting: 'Waiting',
  Rejected: 'Rejected',
  Outstanding: 'Outstanding',
  InProgress: 'InProgress',
  Complete: 'Complete',
});

export const StatusCaption = Object.freeze({
  Any: 'Any',
  Waiting: 'Waiting',
  Rejected: 'Rejected',
  Outstanding: 'Outstanding',
  InProgress: 'In Progress',
  Complete: 'Complete',
});

class RequestStatus {
  static DisplayNames() {
    const status = { ...Status };
    delete status.Unknown;
    return Object.keys(status);
  }

  constructor(status = null) {
    this.status = status;
    this.toJSON = this.ToJSON.bind(this);
  }

  ToJSON() {
    return this.Status;
  }

  get Status() {
    return this.status ? Status[this.status] : Status.Unknown;
  }

  get ShouldAllowEditRequest() {
    return this.Status === Status.Waiting;
  }

  Clone() {
    const clone = new RequestStatus();
    clone.status = this.status;
    return clone;
  }

  IsEqual(status) {
    return this.status === status.status;
  }
}

export default RequestStatus;
