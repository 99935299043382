import React from 'react';
import PropTypes from 'prop-types';
import Field from '../../../../modules/ScoutModels/models/field';
import Caption from '../../../../modules/ScoutModels/models/caption';


const labelStyle = {
  minWidth: '120px',
  verticalAlign: 'top',
};

const valueStyle = {
  overflow: 'hidden',
};

const FieldRow = (props) => {
  if (!props.field || (!props.field.shown && !props.show)) return null;
  const localCaption = new Caption(props.field.caption || props.field.DefaultCaption, props.value);
  return (
    <tr>
      <td style={labelStyle}>
        {localCaption.label}
        :
      </td>
      <td style={valueStyle}>{localCaption.value}</td>
    </tr>
  );
};

FieldRow.propTypes = {
  field: PropTypes.instanceOf(Field),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  show: PropTypes.bool.isRequired,
};

FieldRow.defaultProps = {
  field: null,
  value: null,
};

export default FieldRow;
