import React from 'react';
import PropTypes from 'prop-types';
import ChatWindow from '../../modules/common/conversation/chatWindow';
import Sprite from '../components/sprite';

const ConversationPage = props => (
  <div className="conversationBox w3-content">
    <ChatWindow
      mainContainerClass="mainContainer"
      timelineContainerClass="timelineContainer"
      submitIcon={<Sprite fontSize="18px" icon="SubmitConversation" color="black" />}
      conversationId={props.match.params.conversationId}
    >
      <div style={{ marginBottom: '15px' }}>
        <Sprite icon="Conversation" color="black" />
        <b style={{ fontSize: '18px' }} className="padLeft-6">Conversation</b>
      </div>
    </ChatWindow>
  </div>
);

ConversationPage.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ conversationId: PropTypes.string }) }).isRequired,
};


export default ConversationPage;
