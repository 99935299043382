import React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import Slide from './Slide';

const fileSource = {
  beginDrag(props) {
    return {
      id: props.id,
    };
  },
};

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});

/* WARNING:: This component needs to be a class. not a functional component (react-dnd requirement) */
/* If you change it to a functional component, it won't work */
class SlideComponent extends React.Component {
  render() {
    let style = { width: '100%', height: '100%' };
    if (this.props.altStyle) {
      style = { ...style, ...this.props.altStyle };
    }
    return this.props.connectDragSource(<div style={style}><Slide onClick={this.props.onClick}>{this.props.children}</Slide></div>);
  }
}

SlideComponent.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  altStyle: PropTypes.shape({ }),
  connectDragSource: PropTypes.func.isRequired,
};

SlideComponent.defaultProps = {
  children: null,
  altStyle: null,
  onClick: () => { },
};

export { SlideComponent };
export default DragSource('file', fileSource, collect)(SlideComponent);
