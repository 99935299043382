const millisecondsToRevokeObjectURL = 100;

const downloadFile = (blob, fileName, mimeType) => {
  // Blob available globally
  // eslint-disable-next-line no-undef
  const newBlob = new Blob([blob], { type: mimeType });

  // IE special case
  if (window.top.navigator.msSaveOrOpenBlob) {
    window.top.navigator.msSaveOrOpenBlob(newBlob, fileName);
    return;
  }

  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = fileName;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Firefox special case
  setTimeout(() => {
    window.URL.revokeObjectURL(data);
  }, millisecondsToRevokeObjectURL);
};

export default downloadFile;
