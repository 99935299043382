import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TimeDisplayRule, { DateTimeUnit } from './models/TimeDisplayRule';

class Age extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      refreshDuration: DateTimeUnit.MinuteInMilliseconds / this.props.acceleration,
    };
    this.refresh = this.refresh.bind(this);
    this.timeDisplayRule = new TimeDisplayRule(this.props.created, this.props.unsent, this.props.acceleration);
  }

  componentDidMount() {
    if (!this.currentRefreshTimeout) {
      this.currentRefreshTimeout = setTimeout(this.refresh, this.state.refreshDuration);
    }
  }

  componentDidUpdate() {
    if (!this.currentRefreshTimeout) {
      this.currentRefreshTimeout = setTimeout(this.refresh, this.state.refreshDuration);
    }
  }

  componentWillUnmount() {
    if (this.currentRefreshTimeout) {
      clearTimeout(this.currentRefreshTimeout);
      this.currentRefreshTimeout = null;
    }
  }

  refresh() {
    const currentCount = this.state.count;
    const increment = currentCount + 1;
    this.setState({ count: increment });
    this.currentRefreshTimeout = null;
  }

  render() {
    return (
      <div className="Age" title={this.props.created.toString()}>
        <i className="fa fa-clock-o" aria-hidden="true" />
        {this.timeDisplayRule.Caption}
      </div>
    );
  }
}

Age.propTypes = {
  created: PropTypes.instanceOf(Date).isRequired,
  unsent: PropTypes.bool.isRequired,
  acceleration: PropTypes.number,
};

Age.defaultProps = {
  acceleration: 1,
};

export default Age;
