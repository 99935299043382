import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/header';
import InfoBar from '../menu/menu';
import ListView from './listView/listView';
import CommonFetch from '../utils/commonFetch';
import Requests from '../../modules/ScoutModels/models/requests';
import SortOptions from '../menu/filters/sortOptions';
import { makeCancelable } from '../../modules/common/api/fetchData';

const pathname = '/requests/list';

class RequestList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requests: null,
      loading: true,
      searchTerm: '',
    };
    this.handleFilters = this.handleFilters.bind(this);
    this.onSearchTermChanged = this.onSearchTermChanged.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.sortRequestsBy = this.sortRequestsBy.bind(this);
    this.cancelableFetch = null;
  }

  componentWillUnmount() {
    if (this.hashChangeListener) this.hashChangeListener();
  }

  onSearch(query) {
    if (this.cancelableFetch) this.cancelableFetch.cancel();
    window.sessionStorage.setItem('search', query);
    if (query) {
      this.cancelableFetch = makeCancelable(CommonFetch.getSearchResult(query));
      this.setState({
        loading: true,
        requests: null,
      });
      this.cancelableFetch
        .promise
        .then((results) => {
          if (results) this.initializeRequestList(results, true);
          else {
            this.setState({
              loading: false,
            });
          }
        });
    } else {
      this.getRequestList();
    }
  }

  onSearchTermChanged(val) {
    this.setState({ searchTerm: val }, () => {
      if (
        !this.state.searchTerm
        && (this.state.requests && !this.state.requests.HasRequests)
        && this.state.requests.IsListBySearch) {
        this.setState({ loading: true });
        this.getRequestList();
      }
    });
  }

  getRequestList() {
    if (this.cancelableFetch) this.cancelableFetch.cancel();
    window.sessionStorage.removeItem('search');
    this.cancelableFetch = makeCancelable(CommonFetch.getRequestList());
    this.cancelableFetch
      .promise
      .then((list) => {
        this.initializeRequestList(list);
      });
  }

  UNSAFE_componentWillMount() {
    this.hashChangeListener = this.props.history.listen((location, history) => {
      if (history === 'POP' && location.pathname === pathname) {
        this.handleUrlChange();
      }
    });
    this.handleUrlChange();
  }

  handleFilters(val) {
    this.state.requests.SetFilter(val);
    this.setState(prevState => ({ requests: prevState.requests }));
  }

  sortRequestsBy(val) {
    this.state.requests.SetSortBy(val);
    this.setState(prevState => ({ requests: prevState.requests }));
  }

  initializeRequestList(list, isListBySearch = false) {
    const requests = new Requests(list, isListBySearch);
    requests.SetSortBy(SortOptions[0]);
    if (requests.HasRequests) this.initializeRequestConversations(requests);
    this.setState({ requests, loading: false });
  }

  initializeRequestConversations(requests) {
    CommonFetch.getConversationList(requests.RequestIdsForBatch).on('Resolved', ({ result, IsLastPromise }) => {
      Promise
        .resolve(result.json())
        .then((conversation) => {
          requests.SetLastConversationTime(conversation);
          if (IsLastPromise) requests.SetConversationLoadingStatus(false);
          this.setState({ requests });
        });
    });
  }

  handleUrlChange() {
    this.setState({ loading: true });
    const search = this.props.history.location.search.substring('?search='.length);
    this.setState({
      searchTerm: '',
    }, () => {
      if (search) {
        this.onSearch(search);
      } else {
        this.getRequestList();
      }
    });
  }

  render() {
    return (
      <div className="page">
        <Header Title="Scout" />
        <InfoBar
          onFiltersSelected={this.handleFilters}
          onSearchClick={this.onSearch}
          onSearchTermChanged={this.onSearchTermChanged}
        />
        {this.state.loading ? (
          <div className="wrapper">
            <div className="cssload-loader" />
          </div>
        ) : (
          <ListView
            sortRequestsBy={this.sortRequestsBy}
            requests={this.state.requests}
            error={this.state.error}
            autoSelect={this.props.params.requestID}
            searchTerm={this.state.searchTerm}
          />
        )}
      </div>
    );
  }
}
RequestList.propTypes = {
  params: PropTypes.shape({
    requestID: PropTypes.string,
  }),
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    listen: PropTypes.func,
  }).isRequired,
};

RequestList.defaultProps = {
  params: { requestID: '' },
};
export default RequestList;
