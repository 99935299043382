// Not putting this through eslint, this is auto generated
// TODO - need to work out how to remove/replace the typescript stuff or getting it working with webpack (maybe thorugh an enforce: "pre" step?) maintaining 2 different techs is unmaintainable.
/* eslint-disable */

const __extends =
  (this && this.__extends) ||
  function (d, b) {
    for (const p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : ((__.prototype = b.prototype), new __());
  };
const React = require('react');
require('whatwg-fetch');
const contribution_1 = require('./contribution');
const timeline_1 = require('./timeline');
const error_1 = require('../components/error');
const conversation_1 = require('../model/conversation/conversation');
const auth_1 = require('../auth/auth').default;

const pageVisibility_1 = require('./pageVisibility');

/**
 * Component that maps an e-mail address to a ChatWindow image.
 */
const ChatWindow = (function (_super) {
  __extends(ChatWindow, _super);
  function ChatWindow(props) {
    const _this = _super.call(this, props) || this;
    _this.state = {
      conversation: null,
      unsentMessages: new Array(),
      errorMessage: null,
      refreshDuration: 10000,
      updateAvaliable: false,
      lastUpdateAtempt: new Date(),
    };
    _this.updateConversationWithNewMessage = _this.updateConversationWithNewMessage.bind(_this);
    _this.getConversation = _this.getConversation.bind(_this);
    _this.pageVisibilityHasChanged = _this.pageVisibilityHasChanged.bind(_this);
    _this.pageVisibility = new pageVisibility_1.PageVisibility();
    _this.pageVisibility.subscribeIsVisibleEvent(_this.pageVisibilityHasChanged);
    return _this;
  }

  ChatWindow.prototype.pageVisibilityHasChanged = function (isVisible) {
    if (isVisible) {
      if (this.state.conversation != null) {
        const mimumUpdateTime = this.state.lastUpdateAtempt.getTime() + 2000;
        const currentTime = new Date();
        if (currentTime.getTime() > mimumUpdateTime) {
          this.getConversationVersion();
        }
        this.refreshConversationPulse();
      }
    } else {
      this.clearTimeOut();
    }
  };

  ChatWindow.prototype.componentWillMount = function () {
    if (this.props.conversationId) {
      this.getConversation(this.props.conversationId);
    } else if (this.props.match && this.props.match.conversationId) {
      this.getConversation(this.props.match.conversationId);
    } else {
      console.log('No conversation ID supplied.');
    }
    this.refreshConversationPulse();
  };

  ChatWindow.prototype.setErrorState = function (errorMessage) {
    let stringMessage;
    if (typeof errorMessage === 'string') {
      stringMessage = errorMessage;
    } else if (errorMessage.props === 'string') {
      stringMessage = errorMessage.props.error;
    } else {
      stringMessage = 'Error loading conversation.';
    }
    this.setState({
      errorMessage: stringMessage,
      unsentMessages: new Array(),
      conversation: null,
    });
    console.log(errorMessage);
  };

  ChatWindow.prototype.componentWillUpdate = function (nextProps, nextState) {
    let updateIsAvaliable = nextState.updateAvaliable;
    if (updateIsAvaliable == false) {
      updateIsAvaliable =
        this.props.conversationId != nextProps.conversationId ||
        (this.props.match && this.props.match.conversationId != nextProps.match.conversationId);
    }
    if (updateIsAvaliable) {
      if (this.props.match && this.props.match.conversationId) {
        this.getConversation(nextProps.match.conversationId);
      } else if (this.props.conversationId) {
        this.getConversation(nextProps.conversationId);
      }
    }
  };

  ChatWindow.prototype.getConversation = function (conversationId) {
    const _this = this;
    this.setState({
      updateAvaliable: false,
    });
    const conversationUrl = `${ENV_ENDPOINTS.conversationEndpoint + auth_1.clientShortName()}/conversations/${conversationId}`;
    const config = {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${auth_1.token()}`,
      }),
    };
    fetch(conversationUrl, config)
      .then(response => {
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 204) {
          const convo = new conversation_1.Conversation();
          convo.Id = conversationId;
          convo.VersionDate = new Date();
          return convo;
        }
        throw new error_1.default(`Error loading conversation\n(${response.status}) ${response.statusText}`);
      })
      .then(convoObject => {
        _this.setState({
          errorMessage: null,
          unsentMessages: new Array(),
          conversation: conversation_1.Conversation.fromObject(convoObject),
        });
      })
      .catch(error => _this.setErrorState(error));
  };

  ChatWindow.prototype.getConversationVersion = function () {
    const _this = this;
    this.setState({
      lastUpdateAtempt: new Date(),
    });
    let conversationId = '';
    if (this.props.conversationId) {
      conversationId = this.props.conversationId;
    } else if (this.props.match && this.props.match.conversationId) {
      conversationId = this.props.match.conversationId;
    } else {
      return;
    }
    const conversationUrl = `${ENV_ENDPOINTS.conversationEndpoint + auth_1.clientShortName()}/conversations/summary?ids=${conversationId}`;
    const config = {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${auth_1.token()}`,
      }),
    };
    fetch(conversationUrl, config)
      .then(response => response.json())
      .then(convoVersion => {
        if (convoVersion.length != 0 && convoVersion[0] && convoVersion[0].VersionDate) {
          const latestVersionDate = new Date(convoVersion[0].VersionDate);
          const currentVersionDate = new Date(_this.state.conversation.VersionDate);
          const updateIsAvaliable = latestVersionDate.getTime() > currentVersionDate.getTime();
          if (updateIsAvaliable) {
            _this.setState({
              updateAvaliable: updateIsAvaliable,
            });
          }
        }
      })
      .catch(error => _this.setErrorState(error));
  };

  ChatWindow.prototype.updateConversation = function (conversation) {
    const _this = this;
    const conversationUrl = `${ENV_ENDPOINTS.conversationEndpoint + auth_1.clientShortName()}/conversations`;
    const config = {
      method: 'PATCH',
      body: JSON.stringify(conversation),
      headers: new Headers({
        Authorization: `Bearer ${auth_1.token()}`,
        'Content-Type': 'application/json',
      }),
    };
    fetch(conversationUrl, config)
      .then(response => {
        if (response.status == 200) {
          return response.json();
        }
        throw new error_1.default(`Error updating conversation\n(${response.status}) ${response.statusText}`);
      })
      .then(convoObject => {
        _this.setState({
          errorMessage: null,
          unsentMessages: new Array(),
          conversation: conversation_1.Conversation.fromObject(convoObject),
        });
      })
      .catch(error => _this.setErrorState(error));
  };

  ChatWindow.prototype.updateConversationWithNewMessage = function (message) {
    // console.log(message);
    const unsentMessages = this.state.unsentMessages;
    unsentMessages.push(message);
    this.setState({ unsentMessages });
    const convo = new conversation_1.Conversation();
    convo.Id = this.state.conversation.Id;
    convo.MessageList.push(message);
    this.updateConversation(convo);
  };

  ChatWindow.prototype.refreshConversationPulse = function () {
    const _this = this;
    if (!this.currentRefreshTimeout) {
      this.currentRefreshTimeout = setTimeout(() => {
        _this.getConversationVersion();
        _this.currentRefreshTimeout = null;
        _this.refreshConversationPulse();
      }, this.state.refreshDuration);
    }
  };

  ChatWindow.prototype.componentWillUnmount = function () {
    this.pageVisibility.removePageVisibilityEvent();
    this.clearTimeOut();
  };

  ChatWindow.prototype.clearTimeOut = function () {
    if (this.currentRefreshTimeout) {
      clearTimeout(this.currentRefreshTimeout);
      this.currentRefreshTimeout = null;
    }
  };

  ChatWindow.prototype.render = function () {
    const mainDiv = {
      backgroundColor: 'white',
    };

    const timelineDiv = {};
    if (this.props.maxHeight > 0) {
      timelineDiv.maxHeight = `${this.props.maxHeight}px`;
      if(!this.props.compactMode) {
        timelineDiv.overflowY = 'auto';
      }
    }
    const loadingDivStyle = {
      fontFamily: 'Roboto',
      fontSize: '18px',
      color: 'silver',
      backgroundColor: 'white',
      padding: '20px',
    };
    if (this.state.errorMessage != null) {
      return <error_1.default errorMessage={this.state.errorMessage} />;
    }
    if (this.state.conversation == null) {
      return <div style={loadingDivStyle}>Loading Conversation...</div>;
    }

    const {
      mainContainerClass = '',
      timelineContainerClass = '',
    } = this.props;

    return (
      <div className={mainContainerClass} style={mainDiv}>
        <div>{this.props.children}</div>
        <div className={timelineContainerClass} id={"chatWindow"} style={timelineDiv}>
          <timeline_1.default messages={this.state.conversation.MessageList} unsentMessages={this.state.unsentMessages} compactMode={this.props.compactMode} maxHeight={this.props.maxHeight} expandIconUp={this.props.expandIconUp} expandIconDown={this.props.expandIconDown} />
        </div>
        <contribution_1.default newMessageEvent={this.updateConversationWithNewMessage} submitIcon={this.props.submitIcon} />
      </div>);
  };
  return ChatWindow;
}(React.Component));

ChatWindow.defaultProps = {
  children: null,
};

Object.defineProperty(exports, '__esModule', { value: true });
exports.default = ChatWindow;
