import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RadioButton from '../../components/radioButton';
import NavigationButton from '../../components/navigationButton';
import RequestType from '../../../../modules/ScoutModels/models/requestType';
import RequestTypeTitle from '../../components/requestTypeTitle';

const backButton = { icon: 'List', label: 'Cancel', destination: '/requests/list' };
const nextButton = { icon: 'Next', label: 'Next', destination: '/requests/new/2' };
const divParent = { padding: '8px 0px', display: 'flex' };

class Step1 extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.setRequestType(event.target.id);
  }

  render() {
    return (
      <div className="stepform">
        <h5>
          <b>What type of request would you like to raise?</b>
        </h5>
        <div className="fields">
          <div>
            {this.props.requestTypes.length === 0
              ? 'No request forms available. Please contact your Pulse Administrator.'
              : this.props.requestTypes.map(requestType => (
                <div key={requestType.identifier} style={divParent}>
                  <RadioButton onSelectHandler={this.handleChange} valueData={requestType.identifier} selectedData={this.props.selectedRequestType} />
                  <RequestTypeTitle
                    requestType={requestType}
                    onTitleClick={() => {
                      this.props.setRequestType(requestType.identifier);
                    }}
                  />
                </div>
              ))
            }
          </div>
        </div>
        <div className="button-group">
          <div className="w3-left back">
            <NavigationButton icon={backButton.icon} label={backButton.label} destination={backButton.destination} />
          </div>
          <div className="w3-right next">
            <NavigationButton
              icon={nextButton.icon}
              label={nextButton.label}
              destination={nextButton.destination}
              iconOnLeft={false}
              enabled={!!this.props.selectedRequestType}
            />
          </div>
        </div>
      </div>
    );
  }
}

Step1.propTypes = {
  setRequestType: PropTypes.func.isRequired,
  selectedRequestType: PropTypes.string,
  requestTypes: PropTypes.arrayOf(PropTypes.instanceOf(RequestType)).isRequired,
};

Step1.defaultProps = {
  selectedRequestType: '',
};

export default Step1;
