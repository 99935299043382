import React from 'react';
import PropTypes from 'prop-types';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    // redirect to role's landing page
    this.props.history.replace('/requests/list');
  }

  render() {
    return (
      <div className="w3-main content" />
    );
  }
}

Index.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};
