import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import RequestTile from './requestTile';
import Sprite from '../../components/sprite';
import NavigationButton from '../components/navigationButton';
import ListItem from './listItem';
import Requests from '../../../modules/ScoutModels/models/requests';

const ColumnStyle = {
  marginTop: '100px',
  textAlign: 'center',
};

const ColumnBtnStyle = {
  marginTop: '155px',
};

const magnifierStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const getEmptyListItems = () => (
  <Fragment>
    <RequestTile className="card-empty" />
    <RequestTile className="card-empty" />
  </Fragment>
);

const ListViewBody = (props) => {
  const getListItems = () => props.requests.Requests.map(request => (
    <ListItem
      request={request}
      key={request.id}
      SearchTerm={props.searchTerm}
    />
  ));

  return (
    <div className="list-body">
      <Fragment>
        {props.requests.HasRequests ? getListItems() : null}
        {!props.requests.IsListBySearch && !props.requests.requests.length ? getEmptyListItems() : null}
      </Fragment>
      {!props.requests.HasRequests && (
        <div className="w3-row w3-container">
          <div className="w3-display-middle" style={ColumnStyle}>
            {props.requests.IsListBySearch && (
              <div style={magnifierStyle}>
                <Sprite fontSize="22px" color="#1c3b50" icon="Search" />
              </div>
            )}
            <div>{props.requests.EmptyListMessage}</div>
          </div>
          {!props.requests.IsListBySearch && (
            <div className="w3-display-middle" style={ColumnBtnStyle}>
              <NavigationButton icon="Add" label="Add" destination="/requests/new/1" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ListViewBody.propTypes = {
  requests: PropTypes.instanceOf(Requests).isRequired,
  searchTerm: PropTypes.string,
};

ListViewBody.defaultProps = {
  searchTerm: '',
};

export default ListViewBody;
