const SortOptions = [
  Object.freeze({
    value: 'createdDate',
    caption: 'By Date',
  }),
  Object.freeze({
    value: 'status.status',
    caption: 'By Status',
  }),
];

export default SortOptions;
