import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { UserManager } from './authConstants';

export default class Signout extends Component {
  constructor(props) {
    super(props);
    this.signOutUser = this.signOutUser.bind(this);
  }

  componentDidMount() {
    this.signOutUser();
  }

  signOutUser() {
    UserManager.getUser().then((user) => {
      if (user) {
        // The user is logged in.
        UserManager.signoutRedirect();
      } else {
        // The user is not logged in.
        this.props.history.push('/');
      }
    });
  }

  render() {
    return (
      <div className="page">
        <div className="wrapper">
          <div className="cssload-loader" />
        </div>
      </div>
    );
  }
}

Signout.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};
