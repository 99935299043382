import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';

import eventNotifier from '../modules/common/eventHandling/eventNotifier';
import eventType from '../modules/common/eventHandling/eventType';

import LoadingIndicator from './components/loadingIndicator';

const locale = window.navigator.userLanguage || window.navigator.language;

const Layout = (props) => {
  const notifyMovingRoute = () => {
    eventNotifier.emit(eventType.RouteChanged);
  };

  const location = useLocation();
  useEffect(() => { notifyMovingRoute(); }, [location]);

  moment.locale(locale);

  return (
    <div style={{ height: '100%' }}>
      <ToastContainer style={{ top: '56px' }} autoClose={6000} hideProgressBar />
      {/* 56px is the height of the title bar */}
      <LoadingIndicator />
      {props.children}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

Layout.defaultProps = {
  children: null,
};

export default withRouter(Layout);
