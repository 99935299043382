import { DefaultCaption, FieldName } from './field';
import EmptyField from './emptyField';
import FieldFactory from './fieldFactory';

/* Fields to be displayed when a service type is null. */
export const DefaultFields = [
  {
    name: FieldName.Title,
    shown: true,
    required: true,
    caption: DefaultCaption.title,
  },
  {
    name: FieldName.Requestor,
    caption: DefaultCaption.requestor,
    shown: true,
    required: true,
  }, {
    caption: DefaultCaption.requestorPhone,
    name: FieldName.RequestorPhone,
    shown: true,
  }, {
    caption: DefaultCaption.requestorEmail,
    name: FieldName.RequestorEmail,
    shown: true,
  }, {
    caption: DefaultCaption.AdditionalNotificationEmailAddresses,
    name: FieldName.AdditionalNotificationEmailAddresses,
    shown: true,
  }, {
    caption: DefaultCaption.costCentre,
    name: FieldName.CostCentre,
    shown: true,
  }, {
    caption: DefaultCaption.department,
    name: FieldName.Department,
    shown: true,
  }, {
    caption: DefaultCaption.jobTask,
    name: FieldName.JobTask,
    shown: true,
    required: true,
  }, {
    caption: DefaultCaption.site,
    name: FieldName.Site,
    shown: true,
    required: true,
  }, {
    caption: DefaultCaption.building,
    name: FieldName.Building,
    shown: true,
    required: true,
  }, {
    caption: DefaultCaption.floor,
    name: FieldName.Floor,
    shown: true,
    required: true,
  }, {
    caption: DefaultCaption.room,
    name: FieldName.Room,
    shown: true,
  }, {
    name: FieldName.Attachments,
    caption: DefaultCaption.Attachments,
    shown: true,
  }];

class Fields {
  constructor(fields = [], fieldResourceAccess = {}) {
    this.initializeFields(fields, fieldResourceAccess);
    /* toJSON property needs to be an instance method */
    this.toJSON = this.ToJson.bind(this);
  }

  ToJson() {
    return this.fields;
  }

  initializeFields(fields, fieldResourceAccess) {
    const factory = new FieldFactory(fieldResourceAccess);
    this.fields = factory.createFields(fields);
  }

  GetField(name) {
    return this.fields.find(field => field.name === name);
  }

  GetFieldOrEmpty(name) {
    const field = this.GetField(name);
    return field || new EmptyField();
  }

  SetAsDefault() {
    this.initializeFields(DefaultFields);
  }

  SetFields(fields) {
    this.fields = fields;
  }

  AddFields(fields) {
    this.fields = [...this.fields, ...fields];
  }

  Clone() {
    return this.fields.map(field => field.Clone());
  }

  CompareTo(fields) {
    return this.fields.map((localField) => {
      const field = fields.find(otherField => otherField.name === localField.name);
      if (!field) return true;
      return localField.CompareTo(field);
    }).filter(isValueSame => !isValueSame).length === 0;
  }
}

export default Fields;
