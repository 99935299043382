import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import eventNotifier from '../eventHandling/eventNotifier';
import eventType from '../eventHandling/eventType';

import { UserManager } from './authConstants';
import Auth from './auth';
import SignInRedirect from './signInRedirect';

class ProtectedRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoggedIn: false, isAuthorised: false };

    this.setupEvents = this.setupEvents.bind(this);
    this.checkUserLogin = this.checkUserLogin.bind(this);
    this.requestUserLogin = this.requestUserLogin.bind(this);
    this.requestTokenRefresh = this.requestTokenRefresh.bind(this);
  }

  async componentDidMount() {
    eventNotifier.emit(eventType.RouteChanged);

    this.setupEvents();
    await this.checkUserLogin();
  }

  setupEvents() {
    UserManager.events.addUserSignedOut(() => {
      // Generally called when the user signed out the user in another tab
      // Logs are here otherwise this behaviour is silent
      console.log('User Signed Out');
      this.requestUserLogin();
    });

    UserManager.events.addUserUnloaded(() => {
      // Generally called when the user signed out on this site
      // Logs are here otherwise this behaviour is silent
      console.log('User Unloaded');
    });

    UserManager.events.addAccessTokenExpired(() => {
      // The access token has expired,
      // it should be able to refresh the token if the refresh token is valid
      console.warn('Access Token Expired');
      this.checkUserLogin();
    });

    UserManager.events.addSilentRenewError((error) => {
      // An error occurred while the token was being refreshed
      // It will automatically try again later
      console.error(`Silent Renew Error:\n${error}`);
    });
  }

  async checkUserLogin() {
    const user = await UserManager.getUser();

    if (!user) {
      // The user is not logged in.
      this.requestUserLogin();
      return;
    }

    // The user is logged in.
    if (user.expired) {
      // But the access token has expired, try to refresh it.
      await this.requestTokenRefresh();
    }

    await Auth.updateUser(user);
    const isAuthorised = this.props.authorisedRoles.length === 0 || Auth.hasAtLeastOneRole(this.props.authorisedRoles);
    this.setState({ isLoggedIn: true, isAuthorised });

    try {
      await UserManager.clearStaleState();
      // Logs are here otherwise this behaviour is silent
      console.log('Clear Stale State: Success');
    } catch (e) {
      console.error('Clear Stale State: Error\n', e.message);
    }

    if (!isAuthorised) {
      this.props.history.push(this.props.unauthorisedPath);
    }
  }

  requestUserLogin() {
    // Request for a user login
    console.log('Requesting User Login');
    this.setState({ isLoggedIn: false });
    const uriState = SignInRedirect.uriState();
    UserManager.signinRedirect({ state: uriState });
  }

  async requestTokenRefresh() {
    this.setState({ isLoggedIn: false });
    try {
      // This will try to refresh the token using the refresh token
      // Logs are here otherwise this behaviour is silent
      console.log('Refreshing Token');
      await UserManager.signinSilent();
    } catch (error) {
      // There was an error refreshing the tokens, make them log in again
      console.error(`Failed To Refresh Token:\n${error}`);
      this.requestUserLogin();
    }
  }

  render() {
    if (this.state.isLoggedIn && this.state.isAuthorised && Auth.loggedIn()) {
      // The user is logged in, show them what they came here for
      return (
        <Route
          {...this.props}
          render={localProps => <this.props.component {...localProps} />}
        />
      );
    }

    // The user is not logged in, show a spinner while the magic happens
    return (
      <div className="page">
        <div className="wrapper">
          <div className="cssload-loader" />
        </div>
      </div>
    );
  }
}

ProtectedRoute.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  authorisedRoles: PropTypes.arrayOf(PropTypes.string),
  unauthorisedPath: PropTypes.string,
};

ProtectedRoute.defaultProps = {
  authorisedRoles: [],
  unauthorisedPath: '/unauthorised',
};

export default withRouter(ProtectedRoute);
