import React from 'react';
import PropTypes from 'prop-types';
import Uuid from 'uuid/v4';
import async from 'async';
import AttachmentDTO from '../model/attachment/Attachment';
import {
  SlideDragDrop, SliderImageUpload, Slider, Slide,
} from '../ImageSlider';
import { ImageComponent } from '../ImageProvider';
import { FileType, ValidImageTypes } from '../model/attachment/FileType';
import { SyncMode } from '../model/attachment/SyncMode';
import { EncryptionType } from '../model/attachment/EncryptionType';
import events from '../ImageSlider/events';
import AttachmentEventTypes from './eventTypes';
import SliderEventTypes from '../ImageSlider/eventTypes';
import Toaster, { TOAST_ERROR } from '../../common/toaster/toaster';
import ProgressBar from './ProgressBar';
import DownloadableFile from './DownloadableFile';
import { PropertyCaseConverter } from '../../common/utils/PropertyCaseConverter';
import StyleToNumericConverter from '../ImageSlider/StyleToNumericConverter';
import AttachmentDataService from './attachmentDataService';

const HundredPercent = 100;
const DelayHideProgressBar = 2000;
const ProgressBarHeight = 10;

const PromiseEventTypes = {
  Resolved: 'Resolved',
  Error: 'Error',
};

const GetFileName = str => str.substring(0, str.lastIndexOf('.'));

/* like Promise.all(), this function run promises array in parallel, but return results if there is any promise resolved  */
/* using Promise.all(), we have to wait until all promises are resolved then we get the results */
/* this is used for multiple files uploading and shows a progress bar to users. */
const ResolvePromiseArray = (promiseArray) => {
  const event = events();
  let resolved = 0;
  promiseArray.forEach((promise) => {
    promise.then((result) => {
      event.emit(PromiseEventTypes.Resolved, { result, resolved: ++resolved });
    }).catch((e) => { event.emit('Error', e); });
  });
  return {
    on(eventType, callback) {
      event.on(eventType, result => callback(result));
      return this;
    },
  };
};

const GetAttachmentType = (type) => {
  if (ValidImageTypes.indexOf(type) > -1) return FileType.Image;
  return FileType.Document;
};

class Attachment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progressbarActive: false,
      uploadProgress: 0,
      totalLen: 0,
      hasAttachment: false,
    };

    this.attachments = new Set();
    this.events = this.props.events || events();
    this.OnFileUpload = this.OnFileUpload.bind(this);
    this.OnFileDelete = this.OnFileDelete.bind(this);
    this.OnRejectedFileUpload = this.OnRejectedFileUpload.bind(this);
    this.OnClick = this.OnClick.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.attachmentWrapper = null;
  }

  componentDidMount() {
    if (this.props.attachedItemReference) {
      this.getAttachmentTags(this.props.attachedItemReference, true, this.props.localAttachments);
    }
  }

  get AttachmentWrapperHeight() {
    if (!this.attachmentWrapper) return 0;
    return StyleToNumericConverter(this.attachmentWrapper)('height');
  }

  get hasAttachment() {
    return Array.from(this.attachments).filter(attachment => attachment.IsActive).length > 0;
  }

  getAttachmentTags(attachedItemReference = this.props.attachedItemReference, initialRender = false, additionalAttachments = null) {
    AttachmentDataService.GetAttachmentData(attachedItemReference)
      .then((data) => {
        const pascalCaseData = PropertyCaseConverter.pascalCaseJson(data);
        /* check if we already have incoming attachments in our memory */
        const newAttachments = [];
        pascalCaseData.forEach((attachment) => {
          let found = false;
          this.attachments.forEach((existingAttachment) => {
            if (existingAttachment.Id === attachment.Id) found = true;
          });
          if (!found) newAttachments.push(attachment);
        });

        const files = newAttachments.map(file => new AttachmentDTO(file));

        // make sure we don't duplicate items that are in memory and saved to the server, prefer the local item
        const localAdditionalAttachments = [...additionalAttachments];
        files.forEach((file) => {
          const sameIdItem = localAdditionalAttachments.some(additionalAttachment => additionalAttachment.Id === file.Id);
          if (!sameIdItem) {
            localAdditionalAttachments.push(file);
          }
        });

        this.PopulateSlider(localAdditionalAttachments, initialRender);
      })
      .catch(e => console.log(`Error getting AttachmentTags ${e}`));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.attachedItemReference == null && nextProps.attachedItemReference) {
      this.getAttachmentTags(nextProps.attachedItemReference, true);
    }
  }

  PopulateSlider(files, initialRender = false) {
    const imageComponentPromises = files
      .filter(item => item.IsActive)
      .map(file => new Promise((resolve, reject) => {
        ImageComponent.getImages(file).then((result) => {
          resolve({
            data: result,
            attachment: file,
          });
        }).catch((e) => {
          reject(e);
        });
      }));

    if (!imageComponentPromises.length && initialRender) this.events.emit(SliderEventTypes.InitSlider, [], initialRender);

    Promise.all(imageComponentPromises).then((results) => {
      if (results.length) {
        this.events.emit(SliderEventTypes.InitSlider, this.GetSlides(results), initialRender);
      }
    }).catch((e) => {
      console.log(`Error getting AttachmentTags ${e}`);
    });
  }

  OnClick(id) {
    this.events.emit(AttachmentEventTypes.SelectThumbnail, id);
  }

  OnRejectedFileUpload(files) {
    if (files && files.length) {
      const rejectedFileTyps = new Set();
      files.forEach((file) => {
        rejectedFileTyps.add(file.type);
      });
      const message = 'Invalid file types are not uploaded';
      /* TODO:: PBI20818 toast needs to be refactor that it can accept not only string also any components */
      Toaster.pop(message, TOAST_ERROR);
      this.setState({
        progressbarActive: false,
      });
    }
  }

  OnFileUpload(files) {
    this.setState({
      progressbarActive: true,
      totalLen: files.length,
    });

    const postAttachmentsPromises = files.map(file => this.UploadAttachment(file));

    ResolvePromiseArray(postAttachmentsPromises)
      .on(PromiseEventTypes.Resolved, (resolvedPromise) => {
        const {
          result,
          resolved,
        } = resolvedPromise;
        this.events.emit(SliderEventTypes.AddSlide, [result], files.length);
        this.setState({
          uploadProgress: (resolved / postAttachmentsPromises.length) * HundredPercent,
        }, () => {
          if (this.state.uploadProgress === HundredPercent) {
            setTimeout(() => {
              this.setState({
                uploadProgress: 0,
              });
            }, DelayHideProgressBar);
          }

          if (resolved === this.state.totalLen) {
            this.setState({
              progressbarActive: false,
            });
          }
        });
      })
      .on(PromiseEventTypes.Error, (message) => {
        Toaster.pop(message, TOAST_ERROR);
      });
  }

  /* Upload a single file */
  UploadAttachment(file) {
    return new Promise((resolve, reject) => {
      const fileRequestData = new FormData();
      const newFile = file;
      newFile.FileName = newFile.name;

      const id = Uuid();

      fileRequestData.append('datafile[]', newFile);

      const PostAttachment = callback => AttachmentDataService.PostAttachmentFile(id, fileRequestData)
        .then((response) => {
          if (!response.ok) {
            return {
              ok: response.ok,
              error: response.statusText,
              status: response.status,
            };
          }
          return { ok: true };
        })
        .then((data) => {
          callback(null, data);
        }).catch((err) => {
          callback(err);
        });

      const CreateAttachmentDto = (postAttachmentResponse, callback) => {
        if (postAttachmentResponse !== undefined && postAttachmentResponse.ok === false) {
          callback(new Error('Error Saving File for Attachment'));
        }
        const newAttachment = new AttachmentDTO({
          Id: id,
          Key: id,
          File: {
            EncryptionType: EncryptionType.Static,
          },
          Display: newFile.FileName,
          AttachedItemReference: this.props.attachedItemReference,
          AttachedItemType: this.props.attachedItemType,
          FileType: GetAttachmentType(file.type),
          SyncMode: this.props.syncMode,
        });
        callback(null, newAttachment);
      };

      const PutMeta = (newAttachment, callback) => AttachmentDataService.PutAttachmentMeta(newAttachment)
        .then(() => {
          callback(null, newAttachment);
        }).catch((err) => {
          callback(err);
        });

      const CreateNewSlideComponent = (newAttachment, callback) => {
        this.attachments.add(newAttachment);
        this.setState({ hasAttachment: this.hasAttachment });
        const isPreviewAvailable = ValidImageTypes.indexOf(newFile.type) > -1;
        let image = null;
        if (!isPreviewAvailable) {
          image = DownloadableFile(newFile, newAttachment, this.props.attachmentEndpointIsCloud);
        } else {
          /* Note:: we can use data URL for both thumbnail and preview passed in via file object during drag and drop */
          image = <img id={id} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} src={newFile.preview} alt={newFile.FileName || 'preview'} />;
          this.events.emit(AttachmentEventTypes.AddPreview, image);
        }
        const SlideComponent = this.props.readOnly ? Slide : SlideDragDrop;
        callback(
          null,
          <SlideComponent
            fileName={GetFileName(newFile.name)}
            onClick={() => { if (isPreviewAvailable) this.OnClick(id); }}
            id={id}
          >
            {image}
          </SlideComponent>,
        );
      };

      let waterfall;
      if (this.props.deferredCommit) {
        waterfall = [PostAttachment, CreateAttachmentDto, CreateNewSlideComponent];
      } else {
        waterfall = [PostAttachment, CreateAttachmentDto, PutMeta, CreateNewSlideComponent];
      }

      async.waterfall(waterfall, (err, result) => {
        if (result) {
          resolve(result);
        }
        if (err) {
          reject(err);
        }
      });
    });
  }

  GetSlides(results) {
    return results.map((item) => {
      const {
        attachment,
        data,
      } = item;
      this.attachments.add(attachment);
      this.setState({ hasAttachment: this.hasAttachment });

      /* WIP:: PBI 17021 for implementing preview component, we might have to check if incoming data object has both thumbnail and actual size image. */
      let component = data.Thumbnail || data.File;
      /* replace a component if file type is document ( replace default image source and make the file downloadable on click ). */
      if (attachment.FileType === FileType.Document) {
        component = DownloadableFile(null, attachment, this.props.attachmentEndpointIsCloud, false);
      }

      if (attachment.FileType === FileType.Image) {
        /* current attachment api won't generate thumbnails yet. */
        /* we need to add full size images for preview component */
        const preview = data.File || data.Thumbnail;
        this.events.emit(
          AttachmentEventTypes.AddPreview,
          React.cloneElement(preview, {
            id: attachment.Id,
            alt: attachment.Display || 'Preview',
            style: {
              maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto', objectFit: 'contain',
            },
          }),
        );
      }
      const SlideComponent = this.props.readOnly ? Slide : SlideDragDrop;
      return (
        <SlideComponent
          fileName={GetFileName(attachment.Display)}
          onClick={() => { if (attachment.FileType === FileType.Image) this.OnClick(attachment.Id); }}
          id={attachment.Id}
          trashIcon={this.props.trashIcon}
        >
          {component}
        </SlideComponent>
      );
    });
  }

  OnFileDelete(fileId) {
    let selectedAttachment = null;
    this.attachments.forEach((attachment) => {
      const id = attachment.Id || attachment.id;
      if (id === fileId) {
        selectedAttachment = attachment;
        selectedAttachment.IsActive = false;
        selectedAttachment.VersionDate = '';
        this.events.emit(AttachmentEventTypes.RemovePreview, fileId);
      }
    });

    if (selectedAttachment) {
      if (!this.props.deferredCommit) {
        AttachmentDataService.PostAttachmentMeta(selectedAttachment)
          .then((response) => {
            if (response && response.ok) {
              this.attachments.delete(selectedAttachment);
              this.events.emit(SliderEventTypes.RemoveSlide, fileId);
            } else {
              console.log(`Error Deleting File. ${response.status} ${response.statusText}`);
            }
          })
          .catch((e) => {
            console.log(`Error Deleting File. ${e}`);
          });
      } else {
        this.events.emit(SliderEventTypes.RemoveSlide, fileId);
      }
      this.setState({ hasAttachment: this.hasAttachment });
    } else {
      console.log('Error Deleting File.');
    }
  }

  handleStateChange(state) {
    const attachments = this.attachments;
    if (this.props.StateChange) {
      this.props.StateChange({ ...state, attachments });
    }
  }

  render() {
    this.Slider = this.props.readOnly
      ? Slider
      : SliderImageUpload;

    const progressBarClass = this.state.uploadProgress > 0 || this.state.progressbarActive
      ? 'animated fadeIn'
      : 'animated-fast fadeOut';

    const progressbarDisplay = this.state.uploadProgress > 0 || this.state.progressbarActive
      ? 'flex'
      : 'none';

    const hasAttachmentClass = this.props.readOnly
      || this.state.hasAttachment
      ? ' hasAttachments'
      : '';

    return (
      <div
        ref={(node) => { this.attachmentWrapper = node; }}
        className={`defaultAttachment ${this.props.attachmentWrapperClass}${hasAttachmentClass}`}
      >
        <this.Slider
          minLength={this.props.minLength}
          StateChange={state => this.handleStateChange(state)}
          readOnly={this.props.readOnly}
          tooltipOnReadOnlyTrashCan={this.props.tooltipOnReadOnlyTrashCan}
          showFileName={this.props.showFileName}
          accept={this.props.accept}
          events={this.events}
          OnRejectedFileUpload={this.OnRejectedFileUpload}
          OnFileUpload={this.OnFileUpload}
          OnFileDelete={this.OnFileDelete}
          trashIcon={this.props.trashIcon}
          previousIcon={this.props.previousIcon}
          nextIcon={this.props.nextIcon}
          uploadIcon={this.props.uploadIcon}
        />
        <div
          className={progressBarClass}
          style={{
            display: progressbarDisplay,
            width: '100%',
            flexDirection: 'column',
            position: 'absolute',
            top: `${this.AttachmentWrapperHeight
              ? this.AttachmentWrapperHeight - ProgressBarHeight
              : 0}px`,
          }}
        >
          <ProgressBar progress={this.state.uploadProgress} />
        </div>
      </div>
    );
  }
}

Attachment.propTypes = {
  attachedItemReference: PropTypes.string,
  attachmentWrapperClass: PropTypes.string,
  tooltipOnReadOnlyTrashCan: PropTypes.string,
  accept: PropTypes.string,
  readOnly: PropTypes.bool,
  showFileName: PropTypes.bool,
  events: PropTypes.shape({
    on: PropTypes.func,
    off: PropTypes.func,
    emit: PropTypes.func,
  }),
  StateChange: PropTypes.func,
  trashIcon: PropTypes.node,
  nextIcon: PropTypes.node,
  previousIcon: PropTypes.node,
  uploadIcon: PropTypes.node,
  attachmentEndpointIsCloud: PropTypes.bool,
  deferredCommit: PropTypes.bool,
  minLength: PropTypes.number,
  attachedItemType: PropTypes.string,
  syncMode: PropTypes.string,
  localAttachments: PropTypes.shape({}),
};

Attachment.defaultProps = {
  attachedItemReference: null,
  tooltipOnReadOnlyTrashCan: null,
  showFileName: false,
  StateChange: null,
  attachmentWrapperClass: '',
  deferredCommit: false,
  readOnly: false,
  accept: ValidImageTypes.join(','),
  events: null,
  trashIcon: undefined,
  nextIcon: undefined,
  previousIcon: undefined,
  uploadIcon: undefined,
  attachmentEndpointIsCloud: true,
  minLength: -1,
  attachedItemType: 'unknown',
  syncMode: SyncMode.OnlineOnly,
  localAttachments: new Set(),
};

export default Attachment;
