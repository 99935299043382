
class UserDetails {
  constructor(details, defaults) {
    this.details = details;
    this.defaults = defaults;
  }

  get FullName() {
    return this.details ? this.details.fullName : '';
  }

  get Email() {
    return this.details ? this.details.email : '';
  }

  get Phone() {
    return this.details ? this.details.phoneNumber : '';
  }

  get CostCentreName() {
    return this.defaults ? this.defaults.CostCentreName : '';
  }

  get JobTaskName() {
    return this.defaults ? this.defaults.JobTaskName : '';
  }

  get DomainName() {
    return this.defaults ? this.defaults.DomainName : '';
  }

  get SiteName() {
    return this.defaults ? this.defaults.SiteName : '';
  }

  get DepartmentName() {
    return this.defaults ? this.defaults.DepartmentName : '';
  }

  get BuildingName() {
    return this.defaults ? this.defaults.BuildingName : '';
  }

  get FloorName() {
    return this.defaults ? this.defaults.FloorName : '';
  }

  get RoomName() {
    return this.defaults ? this.defaults.RoomName : '';
  }

  get ExpenseName() {
    return this.defaults ? this.defaults.ExpenseName : '';
  }
}

export default UserDetails;
