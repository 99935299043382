

/**
 *  A MessageType defines whether a message was a Comment - generated by a user - or an action - generated by the system.
 */
const MessageType = (function MessageTypeWrapper() {
  function MessageTypeInner() {
  }
  return MessageTypeInner;
}());
MessageType.Comment = 'Comment';
MessageType.Action = 'Action';
exports.MessageType = MessageType;
