import React from 'react';
import PropTypes from 'prop-types';
import Sprite from '../../components/sprite';

const FilterDropdownTitle = (props) => {
  const spanStyle = {
    paddingLeft: '8px',
    paddingRight: '6px',
    color: props.color,
  };

  return (
    <div className="clickable" onClick={props.onClick}>
      <Sprite icon={props.icon} color={props.color} />
      <span className="filterDropdownListItem-text" style={spanStyle}>
        {props.value}
      </span>
      <Sprite icon="Down" color={props.color} />
    </div>
  );
};

FilterDropdownTitle.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default FilterDropdownTitle;
