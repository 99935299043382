import { UserManager as OidcUserManager } from 'oidc-client';

export const UserManagerDefaultSettings = {
  authority: ENV_ENDPOINTS.authority,
  client_id: ENV_ENDPOINTS.clientId,
  redirect_uri: `${ENV_ENDPOINTS.clientRoot}${ENV_ENDPOINTS.signinRedirectPath}`,
  post_logout_redirect_uri: `${ENV_ENDPOINTS.clientRoot}`,
  response_type: ENV_ENDPOINTS.responseType,
  scope: ENV_ENDPOINTS.clientScope,
  automaticSilentRenew: true,
  loadUserInfo: true,
};

export const UserManager = new OidcUserManager(UserManagerDefaultSettings);
