import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Sprite from '../../components/sprite';

const hide = {
  display: 'none',
  transition: 'all 500ms ease',
};

const show = {
  transition: 'all 1000ms ease',
  animation: 'opac 1000ms',
};

const spriteStyle = {
  padding: '0 5px',
};

const messageStyle = {
  display: 'inline',
};

const HiddenText = (props) => {
  const style = props.show ? show : hide;
  return props.text ? (
    <div className={classNames('hidden-text', { [props.className]: props.className })} style={style}>
      {props.icon && (
        <Sprite icon={props.icon} fontSize="16px" style={spriteStyle} />
      )}
      <div className="message" style={messageStyle}>{props.text}</div>
    </div>
  ) : null;
};

HiddenText.propTypes = {
  text: PropTypes.string.isRequired,
  show: PropTypes.bool,
  icon: PropTypes.string,
  className: PropTypes.string,
};

HiddenText.defaultProps = {
  show: false,
  icon: null,
  className: null,
};

export default HiddenText;
