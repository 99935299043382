import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Field, { FieldName } from '../../../../../modules/ScoutModels/models/field';
import WizardField, { WizardFieldType } from './WizardField';
import RadioButton from '../../../components/radioButton';
import FormLabel from './formLabel';
import EmptyField from '../../../../../modules/ScoutModels/models/emptyField';
import TooltipIcon from '../../../components/tooltipIcon';
import TooltipText from '../../../components/tooltipText';

const labelStyle = { display: 'flex', minWidth: 'default' };
const divInputSpAccessStyleHide = {
  maxHeight: '0px',
  overflow: 'hidden',
  transition: 'all .5s ease',
};
const divInputSpAccessStyleShow = {
  overflow: 'hidden',
  transition: 'all .5s ease',
  animation: 'opac .5s',
};
const tableCellStyle = { display: 'table-cell', verticalAlign: 'middle' };

class SpecialAccessSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.specialAccessContactName,
      details: this.props.specialAccessContactDetails,
      showTooltip: false,
    };

    this.onTooltipClick = this.onTooltipClick.bind(this);
    this.onRadioButtonChange = this.onRadioButtonChange.bind(this);
  }

  onTooltipClick(event) {
    event.stopPropagation();
    const target = event.target;
    this.setState(prevState => ({ showTooltip: prevState.showTooltip === target.getAttribute('data-value') ? '' : target.getAttribute('data-value') }));
  }

  onRadioButtonChange(event) {
    // When the radio button changes store if special access is required in the
    // request object.
    this.props.onSpecialAccessChange(event.target.id === 'yes');
  }

  render() {
    if (this.props.specialAccessField.shown) {
      return (
        <Fragment>
          <div>
            <div style={{ display: 'flex', paddingTop: '10px' }}>
              <span style={tableCellStyle} className="padRight-16">
                Does this space require special access?
              </span>
              <FormLabel style={labelStyle} caption="Yes" />
              <RadioButton onSelectHandler={this.onRadioButtonChange} valueData="yes" selectedData={this.props.specialAccessData} />
              <FormLabel style={labelStyle} caption="No" />
              <RadioButton onSelectHandler={this.onRadioButtonChange} valueData="no" selectedData={this.props.specialAccessData} />
              <TooltipIcon
                tooltip={this.props.specialAccessField.tooltip}
                id={this.props.specialAccessField.id}
                onTooltipClick={this.onTooltipClick}
              />
            </div>
            <TooltipText
              show={this.state.showTooltip === this.props.specialAccessField.id}
              id={this.props.specialAccessField.id}
              tooltip={this.props.specialAccessField.tooltip}
              onTooltipClick={this.onTooltipClick}
            />
          </div>
          <div style={this.props.specialAccessData === 'yes' ? divInputSpAccessStyleShow : divInputSpAccessStyleHide}>
            <WizardField
              wizardFieldType={WizardFieldType.Input}
              type={FieldName.SpecialAccessContactName}
              field={this.props.specialAccessContactNameField}
              parentVal={{ text: this.state.name }}
              valueChanged={(value) => {
                this.props.onSpecialAccessContactNameChange(value);
                this.setState({ name: value });
              }}
              divShown={this.props.divShown}
              showOpt={this.state.typeListShown === FieldName.SpecialAccessContactName}
              isValid={this.props.specialAccessContactNameValid}
              showTooltip={this.state.showTooltip === this.props.specialAccessContactNameField.id}
              showError={!this.props.specialAccessContactNameValid}
              onTooltipClick={this.onTooltipClick}
              className={`w3-text ${this.props.specialAccessContactNameValid ? 'validInput' : 'invalidInput'}`}
              maxLength={50}
            />
            <WizardField
              wizardFieldType={WizardFieldType.Input}
              type={FieldName.SpecialAccessContactDetails}
              field={this.props.specialAccessContactDetailsField}
              parentVal={{ text: this.state.details }}
              valueChanged={(value) => {
                this.props.onSpecialAccessContactDetailsChange(value);
                this.setState({ details: value });
              }}
              divShown={this.props.divShown}
              showOpt={this.state.typeListShown === FieldName.SpecialAccessContactDetails}
              isValid={this.props.specialAccessContactDetailsValid}
              showTooltip={this.state.showTooltip === this.props.specialAccessContactDetailsField.id}
              showError={!this.props.specialAccessContactDetailsValid}
              onTooltipClick={this.onTooltipClick}
              className={`w3-text ${this.props.specialAccessContactDetailsValid ? 'validInput' : 'invalidInput'}`}
              maxLength={100}
            />
          </div>
        </Fragment>
      );
    }
    return null;
  }
}

SpecialAccessSection.propTypes = {
  specialAccessField: PropTypes.oneOfType([PropTypes.instanceOf(Field), PropTypes.instanceOf(EmptyField)]).isRequired,
  specialAccessData: PropTypes.string,
  onSpecialAccessChange: PropTypes.func.isRequired,
  specialAccessContactName: PropTypes.string,
  specialAccessContactNameField: PropTypes.oneOfType([PropTypes.instanceOf(Field), PropTypes.instanceOf(EmptyField)]).isRequired,
  onSpecialAccessContactNameChange: PropTypes.func.isRequired,
  specialAccessContactNameValid: PropTypes.bool.isRequired,
  specialAccessContactDetails: PropTypes.string,
  specialAccessContactDetailsField: PropTypes.oneOfType([PropTypes.instanceOf(Field), PropTypes.instanceOf(EmptyField)]).isRequired,
  onSpecialAccessContactDetailsChange: PropTypes.func.isRequired,
  specialAccessContactDetailsValid: PropTypes.bool.isRequired,
  divShown: PropTypes.func.isRequired,
  divInputStyle: PropTypes.shape({}),
};

SpecialAccessSection.defaultProps = {
  specialAccessData: undefined,
  specialAccessContactName: undefined,
  specialAccessContactDetails: undefined,
  divInputStyle: {},
};

export default SpecialAccessSection;
