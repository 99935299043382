import _ from 'lodash';

import RequestType from './requestType';

class RequestTypes {
  constructor(requestTypes = [], fieldResourceAccess = {}) {
    this.fieldResourceAccess = fieldResourceAccess;
    this.requestTypes = Array.isArray(requestTypes)
      ? this.OrderBy(requestTypes)
      : [];
    this.filteredRequestTypes = [];
    this.defaultRequestType = new RequestType();
  }

  OrderBy(list = this.requestTypes, orderBy = 'title') {
    return _.orderBy(list, [request => request[orderBy].toUpperCase()]).map(type => new RequestType(type, this.fieldResourceAccess));
  }

  ApplyRoleFilter(roles) {
    this.filteredRequestTypes = _.filter(this.requestTypes, requestType => requestType.roles.some(role => roles.includes(role)));
    this.MarkDuplicates();
  }

  MarkDuplicates() {
    // A request type is a duplicate if we have two with the same title.
    this.RequestTypes.forEach((requestType) => {
      const hasDuplicate = this.RequestTypes.some(requestTypeDup => requestTypeDup.title === requestType.title
        && requestTypeDup.identifier !== requestType.identifier);
      if (hasDuplicate) {
        // We're not reassigning the param, we're changing a value in it.
        // eslint-disable-next-line no-param-reassign
        requestType.useRolesInTitle = true;
      }
    });
  }

  FindAndReplace(requestType) {
    this.requestTypes.splice(this.findIndex(requestType && requestType.id), 1, requestType);
  }

  RemoveRequestType(id) {
    this.requestTypes.splice(this.findIndex(id), 1);
  }

  findIndex(id) {
    let index = null;
    if (!id) {
      index = this.requestTypes.findIndex(requesttype => !requesttype.id);
    } else {
      index = this.requestTypes.findIndex(requesttype => id === requesttype.id);
    }
    return index;
  }

  SetRequestTypeById(id) {
    this.RequestType = this.GetRequestTypeById(id);
  }

  SetRequestType(requestType) {
    this.RequestType = requestType || this.requestTypes[0];
  }

  AddRequestType(requestType) {
    this.requestTypes.push(requestType);
  }

  get HasSavedRequestTypes() {
    return this.requestTypes.filter(requestType => requestType.id).length > 0;
  }

  get HasNewRequestType() {
    return !!this.requestTypes.find(requestType => !requestType.id);
  }

  get RequestTypes() {
    if (this.filteredRequestTypes.length) {
      return this.filteredRequestTypes;
    }
    return this.requestTypes;
  }

  get SelectOptions() {
    return this.requestTypes.map(requestType => requestType.SelectOption);
  }

  GetRequestTypeById(id) {
    if (!id) {
      return this.GetRequestType();
    }
    return this.RequestTypes.find(requestType => requestType.id === id);
  }

  GetRequestType(identifier) {
    if (!identifier) {
      console.log('No request form template set, using default template.');
      this.defaultRequestType.SetAsDefault();
      return this.defaultRequestType;
    }
    const requestTypeFound = this.RequestTypes.find(requestType => requestType.identifier === identifier);
    if (!requestTypeFound) {
      console.warn(`Request form template with identifier '${identifier}' does not exist, using default template.`);
      this.defaultRequestType.SetAsDefault();
      return this.defaultRequestType;
    }
    return requestTypeFound;
  }
}

export default RequestTypes;
