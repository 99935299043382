import React from 'react';
import PropTypes from 'prop-types';
import Sprite from '../../components/sprite';
import HighlighText from '../../menu/filters/highlightText';
import Request from '../../../modules/ScoutModels/models/request';

const descriptionStyle = {
  flex: 1,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
};

const requestNumberStyle = {
  marginTop: '5px',
  minHeight: '20px',
};

const RequestSummary = props => (
  <div>
    <div className="details-wrapper">
      <div className="request-status">
        <div className={`${props.request.status.Status} request-status-icon`}>
          <Sprite icon={props.request.status.Status} fontSize="26px" />
        </div>
        <div className="request-status-caption">
          <b>
            {props.SearchTerm !== ''
              ? HighlighText(props.SearchTerm, props.request.status.Status)
              : props.request.status.Status}
          </b>
        </div>
        <div style={requestNumberStyle}>
          {
              props.request.requestNumber && (
              <React.Fragment>
                <span><b className="icon">#</b></span>
                <span className="padLeft-8">
                  <b>
                    {props.SearchTerm !== ''
                      ? HighlighText(props.SearchTerm, ` ${props.request.requestNumber}`)
                      : ` ${props.request.requestNumber}`}
                  </b>
                </span>
              </React.Fragment>
              )
            }
        </div>
      </div>
      <div style={descriptionStyle}>
        <p className="block-with-text">{props.SearchTerm !== '' ? HighlighText(props.SearchTerm, props.request.title) : props.request.title}</p>
      </div>
    </div>
  </div>
);

RequestSummary.propTypes = {
  request: PropTypes.instanceOf(Request),
  SearchTerm: PropTypes.string,
};

RequestSummary.defaultProps = {
  request: null,
  SearchTerm: '',
};

export default RequestSummary;
