import React from 'react';
import PropTypes from 'prop-types';
import Sprite from '../../../components/sprite';
import ChatWindow from '../../../../modules/common/conversation/chatWindow';

const Conversation = (props) => {
  const sendCommentIcon = <Sprite icon="SubmitConversation" color="black" />;
  const expandIconUp = <Sprite icon="Up" color="black" />;
  const expandIconDown = <Sprite icon="Down" color="black" />;
  return (
    props.id
    && (
      <div>
        <div style={{ marginBottom: '10px' }}>
          <Sprite icon="Conversation" color="black" />
          <b className="padLeft-6">Conversation</b>
        </div>
        <div className="conversationBox">
          <ChatWindow
            conversationId={props.id}
            compactMode={false}
            submitIcon={sendCommentIcon}
            maxHeight={250}
            expandIconUp={expandIconUp}
            expandIconDown={expandIconDown}
          />
        </div>
      </div>
    )
  );
};

Conversation.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Conversation;
