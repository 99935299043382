import Auth from '../../modules/common/auth/auth';
import MissingConfigError, { ConfigType } from '../errors/missingConfigError';
import CommonFetch from '../utils/commonFetch';
import UserDetails from '../utils/userDetails';

class User {
  constructor() {
    this.userDetails = null;
  }

  static async departmentName() {
    const userDetails = await User.UserDetails();
    const deptName = userDetails ? userDetails.DepartmentName : null;
    return deptName === '' ? null : deptName;
  }

  static async UserDetails() {
    if (!this.userDetails) {
      if (Auth.loggedIn()) {
        try {
          const [userDetailsData, userDefaultsData] = await Promise.all([
            CommonFetch.getUserDetails(),
            CommonFetch.getUserDefaults(),
          ]);

          this.userDetails = new UserDetails(userDetailsData, userDefaultsData);
        } catch (error) {
          if (error instanceof MissingConfigError && error.configType === ConfigType.ClientShortName) {
            return this.userDetails;
          }
          throw error;
        }
      }
    }
    return this.userDetails;
  }
}

export default User;
