class EmptyField {
  constructor() {
    this.shown = false;
    this.required = false;
    this.isValid = true;
    this.caption = '';
    this.defaultOnly = true;
  }

  IsValid() {
    return this.isValid;
  }

  /** @protected */
  // Need this here so that consuming objects not need to check the instance type before calling the function.
  // eslint-disable-next-line class-methods-use-this
  initialize() { }
}

export default EmptyField;
