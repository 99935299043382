

/**
 * The Message represent a single element within a conversation as represented and returned by the API.
 */
const Message = (function messageWrapper() {
  function MessageConstructor() {
  }
  /**
     * Convert a untyped JavaScript object into a typed Message, including date conversions.
     * @param source The untyped source to be converted
     */
  MessageConstructor.fromObject = function fromObject(source) {
    const message = Object.create(MessageConstructor.prototype);
    message.Comment = source.Comment;
    message.CreatedDate = new Date(source.CreatedDate);
    message.Email = source.Email;
    message.Name = source.Name;
    message.MessageType = source.MessageType;
    return message;
  };
  return MessageConstructor;
}());
exports.Message = Message;
