export const ConfigType = Object.freeze({
  ClientShortName: 'ClientShortName',
});

class MissingConfigError extends Error {
  constructor(configType, message) {
    super(message);
    this.name = 'MissingConfigError';
    this.configType = configType;
    // Babel has issues when extending native classes so we need to fix the this property.
    // This is a limitation due to the compilation of ES6 to ES5.
    Object.setPrototypeOf(this, MissingConfigError.prototype);
  }
}

export default MissingConfigError;
