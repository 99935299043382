import React from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import HighlighText from '../../menu/filters/highlightText';
import Sprite from '../../components/sprite';
import Request from '../../../modules/ScoutModels/models/request';
import ConversationTime from '../components/conversationTime';

const locale = window.navigator.userLanguage || window.navigator.language;

Moment.locale(locale);

const descriptionStyle = {
  flex: 1,
  overflow: 'hidden',
};

const bottomDescriptionStyle = {
  display: 'flex',
  flexDirection: 'row',
};

const flexRow = {
  display: 'flex',
  flexDirection: 'row',
  width: '33.3%',
};

const RequestStatus = props => (
  <div className="bottom-description" style={bottomDescriptionStyle}>
    <div style={flexRow}>
      <Sprite icon="Calendar" color="#bebebe" />
      <span className="padLeft-8">
        <b>{Moment(props.request.createdDate).fromNow()}</b>
      </span>
    </div>
    <div style={flexRow}>
      <ConversationTime conversation={props.request.conversation} />
    </div>
    <div style={flexRow}>
      <div style={{ ...descriptionStyle }}>
        <Sprite icon="Requestor" color="#bebebe" />
        <span className="padLeft-8 elipsis">
          <b>{props.SearchTerm !== '' ? HighlighText(props.SearchTerm, props.request.requestor) : props.request.requestor}</b>
        </span>
      </div>
      <div hidden>
        {props.SearchTerm !== '' ? HighlighText(props.SearchTerm, props.request.department) : props.request.department}
      </div>
    </div>
  </div>
);

RequestStatus.propTypes = {
  request: PropTypes.instanceOf(Request),
  SearchTerm: PropTypes.string,
};

RequestStatus.defaultProps = {
  request: null,
  SearchTerm: '',
};

export default RequestStatus;
