import React from 'react';
import PropTypes from 'prop-types';

import ContextMenuItem from './contextMenuItem';
import Gravatar from '../gravatar/gravatar';
import Auth from '../auth/auth';

const avatarSize = 46;
const radiusRatio = 2;
const radius = avatarSize / radiusRatio;

const AccountMenuItem = props => (
  <ContextMenuItem
    icon={<Gravatar size={avatarSize} radius={radius} email={Auth.email()} />}
    title={Auth.usersName()}
    descriptions={props.descriptions.concat([`${Auth.clientProjectName() || ''} ${Auth.clientShortName() ? `(${Auth.clientShortName()})` : ''}`])}
  />
);

AccountMenuItem.propTypes = {
  descriptions: PropTypes.arrayOf(PropTypes.string),
};

AccountMenuItem.defaultProps = {
  descriptions: [],
};

export default AccountMenuItem;
