import { Slide, SlideDragDrop } from './Slide';
import { SliderImageUpload, Slider } from './Slider';

// import Slider from './Slider/Slider';
// import SliderImageUploadComponent from './Slider/SliderImageUpload';

/**
 * How to use it
 *
 * 1. Default slider ( no image addition and delete, just a slider with arrows )
 *
 * import { Slider, Slide } from './'
 *
 * By default, all image tags' width and height will have 100% if you don't specify them
 * the slide has a default style too. (width , height 75px)
 * <Slider>
 *   <Slide><img src="source1" alt="" /></Slide>
 *   <Slide><img src="source2" alt="" /></Slide>
 *   <Slide><img src="source3" alt="" /></Slide>
 *   <Slide><img src="source4" alt="" /></Slide>
 * </Slider>
 *
 * For overriding styles of the Slide (container of your images)
 * <Slider slideContainerStyle={{width:'100px',height:'100px',border:'2px solid black'}}>
 *   <Slide><img style={style object} src="source1" alt="" /></Slide>
 *   <Slide><img style={style object} src="source2" alt="" /></Slide>
 *   <Slide><img style={style object} src="source3" alt="" /></Slide>
 *   <Slide><img style={style object} src="source4" alt="" /></Slide>
 * </Slider>
 *
 * 2. Slider with addition and delete slides
 * NOTE:: your consumer component needs to have an event object instance and pass it to SliderImageUpload to add slides and remove
 *
 * import { SliderImageUpload, SlideDragDrop } from './'
 * import events from './events'
 *
 *  constructor(props){
 *   super(props);
 *   this.events = events();
 * }
 *
 *  <SliderImageUpload events={this.events} OnFileUpload={this.OnFileUpload} OnFileDelete={this.OnFileDelete}>
 *   <SlideDragDrop><img src={fileImage} alt="thumbnail" /></SlideDragDrop>
 *   <SlideDragDrop><img src={fileImage} alt="thumbnail" /></SlideDragDrop>
 *   <SlideDragDrop><img src={fileImage} alt="thumbnail" /></SlideDragDrop>
 *   <SlideDragDrop><img src={fileImage} alt="thumbnail" /></SlideDragDrop>
 *  </SliderImageUpload>
 *
 *   you get files you drop, you might call server api to send images , when its done, emit an event
 *
 *   OnFileUpload(files) {
 *   if (files && files.length) {
 *     // 1.api call to upload files
 *     // 2.get previews from image files
 *     // 3.emit an event
 *     this.events.on('AddSlide',[array of SlideDragDrops])
 *   }
 *  }
 *
 *   you get an id you drop, you might call server api to remove the image with the given id and emit an event
 *
 *   OnFileDelete(fileId, callback) {
 *   if (fileId && callback) {
 *     // 1.api call to delete the file
 *     // 2.emit an event
 *     this.events.on('RemoveSlide',fileId)
 *   }
 *  }
 *
 */

export {
  Slider,
  Slide,
  SlideDragDrop,
  SliderImageUpload,
};
