import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Field, { FieldName } from '../../../../../modules/ScoutModels/models/field';
import JobTypes from '../../../../../modules/ScoutModels/models/jobTypes';
import Request from '../../../../../modules/ScoutModels/models/request';
import EmptyField from '../../../../../modules/ScoutModels/models/emptyField';
import WizardField, { WizardFieldType } from './WizardField';

const wrapperStyle = { margin: '16px 0px' };

class JobTypeSection extends Component {
  constructor(props) {
    super(props);

    this.state = { showTooltip: '' };

    this.onTooltipClick = this.onTooltipClick.bind(this);
  }

  onTooltipClick(event) {
    event.stopPropagation();
    const target = event.target;
    this.setState(prevState => ({ showTooltip: prevState.showTooltip === target.getAttribute('data-value') ? '' : target.getAttribute('data-value') }));
  }

  render() {
    return (
      <div>
        <div style={wrapperStyle}>
          <WizardField
            wizardFieldType={WizardFieldType.Autocomplete}
            type={FieldName.JobType}
            field={this.props.jobTypeField}
            optionsData={this.props.jobTypes && this.props.jobTypes.JobTypeOptions}
            parentVal={{ text: this.props.request.jobType }}
            valueChanged={option => this.props.handleJobTypeChange(option)}
            event={this.props.event}
            divShown={this.props.divShown}
            showOpt={this.state.typeListShown === FieldName.JobType}
            isValid={this.props.jobTypeValid}
            showTooltip={this.state.showTooltip === this.props.jobTypeField.id}
            showError={!this.props.jobTypeValid}
            onTooltipClick={this.onTooltipClick}
          />
        </div>
        <div style={wrapperStyle}>
          <WizardField
            wizardFieldType={WizardFieldType.Autocomplete}
            type={FieldName.JobSubType}
            field={this.props.jobSubTypeField}
            optionsData={this.props.jobSubTypes}
            parentVal={{ text: this.props.request.jobSubType }}
            valueChanged={option => this.props.handleJobSubTypeChange(option)}
            isDisabled={!this.props.jobTypes || (this.props.jobTypeField.shown && !this.props.request.jobType)}
            event={this.props.event}
            divShown={this.props.divShown}
            showOpt={this.state.typeListShown === FieldName.JobSubType}
            isValid={this.props.jobSubTypeValid}
            showTooltip={this.state.showTooltip === this.props.jobSubTypeField.id}
            showError={!this.props.jobSubTypeValid}
            onTooltipClick={this.onTooltipClick}
          />
        </div>
      </div>
    );
  }
}

JobTypeSection.propTypes = {
  jobTypeField: PropTypes.oneOfType([PropTypes.instanceOf(Field), PropTypes.instanceOf(EmptyField)]).isRequired,
  jobTypes: PropTypes.instanceOf(JobTypes),
  request: PropTypes.instanceOf(Request).isRequired,
  jobTypeValid: PropTypes.bool.isRequired,
  jobSubTypeField: PropTypes.oneOfType([PropTypes.instanceOf(Field), PropTypes.instanceOf(EmptyField)]).isRequired,
  handleJobTypeChange: PropTypes.func.isRequired,
  handleJobSubTypeChange: PropTypes.func.isRequired,
  jobSubTypeValid: PropTypes.bool.isRequired,
  divShown: PropTypes.func.isRequired,
  jobSubTypes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, text: PropTypes.string, value: PropTypes.string })),
  event: PropTypes.shape({
    on: PropTypes.func,
    emit: PropTypes.func,
    off: PropTypes.func,
  }),
};

JobTypeSection.defaultProps = {
  jobTypes: null,
  jobSubTypes: [],
  event: null,
};

export default JobTypeSection;
