import PropTypes from 'prop-types';
import React from 'react';

/**
 * @param {String} url for image source
 */

export const defaultImgStyle = {
  margin: '0 auto', objectFit: 'cover', minHeight: 0, maxWidth: '100%', maxHeight: '100%',
};

const Image = (props) => {
  let classes = '';

  /**
   *  optional class names
   */
  if (props.options.classes) {
    classes += props.options.classes;
  }

  return (
    props.uri && <img className={classes} style={{ ...defaultImgStyle, ...props.style.style }} alt="" src={props.uri} />
  );
};

Image.propTypes = {
  uri: PropTypes.string,
  options: PropTypes.shape({ classes: PropTypes.string }),
  style: PropTypes.shape({ style: PropTypes.string }),
};

Image.defaultProps = {
  uri: null,
  options: {},
  style: {},
};

export default Image;
