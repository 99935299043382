import React from 'react';
import PropTypes from 'prop-types';
import StyleToNumericConverter from '../StyleToNumericConverter';

const defaultStyle = {
  position: 'absolute',
  top: '0px',
  border: 'none',
  background: 'none',
  outline: 'none',
  height: '100%',
  padding: '0px',
  width: 'initial',
};

class ArrowButton extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperNode = null;
    this.state = {
      position: null,
    };
  }

  getArrowButtonStyle(styles) {
    let defaultStyles = styles;
    if (this.state.position && this.state.position > -1) {
      const propName = this.props.direction === 'left'
        ? 'left'
        : 'right';
      defaultStyles = { ...defaultStyles, [propName]: `${this.state.position}px` };
    }
    return defaultStyles;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.wrapperNode) {
      this.updateArrowPosition(nextProps.maxArrowSectionWidth);
    }
  }

  updateArrowPosition(maxArrowSectionWidth) {
    const wrapperWidth = StyleToNumericConverter(this.wrapperNode)('width');
    if (!Number.isNaN(wrapperWidth)) {
      const byTwo = 2; /* Hard to name this variable, this is for positioning the button element in centre */
      this.setState({
        position: (maxArrowSectionWidth - wrapperWidth) / byTwo,
      });
    }
  }

  render() {
    const styles = {
      ...defaultStyle,
      display: this.props.show
        ? 'block'
        : 'none',
      ...this.props.style,
    };
    return (
      <button
        ref={(node) => { this.wrapperNode = node; }}
        className="clickable"
        style={this.getArrowButtonStyle(styles)}
        onClick={this.props.OnClick}
        type="button"
      >
        {this.props.children}
      </button>
    );
  }
}

ArrowButton.propTypes = {
  children: PropTypes.node,
  // eslint isn't picking up progress being used in UNSAFE_componentWillReceiveProps.
  // eslint-disable-next-line react/no-unused-prop-types
  maxArrowSectionWidth: PropTypes.number,
  direction: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  show: PropTypes.bool,
  OnClick: PropTypes.func,
};

ArrowButton.defaultProps = {
  children: null,
  maxArrowSectionWidth: -1,
  OnClick: null,
  style: {},
  show: false,
};

export default ArrowButton;
