import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImageProvider } from '../../../modules/attachment/ImageProvider';
import Attachment from '../../../modules/attachment/model/attachment/Attachment';
import CommonFetch from '../../utils/commonFetch';
import { makeCancelable } from '../../../modules/common/api/fetchData';
import { PropertyCaseConverter } from '../../../modules/common/utils/PropertyCaseConverter';
import MissingThumbnail from '../../../images/MissingThumbnail.png';

class Thumbnail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachment: undefined,
    };
  }

  componentWillUnmount() {
    if (this.cancelableFetch) {
      this.cancelableFetch.cancel();
    }
  }

  UNSAFE_componentWillMount() {
    this.fetchThumbnail();
  }

  fetchThumbnail() {
    this.cancelableFetch = makeCancelable(CommonFetch.getThumbnail(this.props.id));
    this.cancelableFetch
      .promise
      .then((data) => {
        if (data) {
          this.setState({
            attachment: new Attachment(PropertyCaseConverter.pascalCaseJson([data])[0]),
          });
        }
      })
      .catch((err) => {
        if (!(err instanceof this.cancelableFetch.PromiseCancelError)) {
          console.warn('Error getting a thumbnail');
        }
      });
  }

  render() {
    return (
      <ImageProvider emptyImage={MissingThumbnail} attachment={this.state.attachment} />
    );
  }
}

Thumbnail.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Thumbnail;
