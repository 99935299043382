import _ from 'lodash';
import Uuid from 'uuid/v4';

import fetchData from '../../modules/common/api/fetchData';
import Auth from '../auth/pulseAuth';
import HttpCode from '../../modules/common/api/httpStatusCode';
import events from '../../modules/attachment/ImageSlider/events';
import NotFoundError from '../errors/notFoundError';
import MissingConfigError, { ConfigType } from '../errors/missingConfigError';

const ResolvePromiseArray = (promiseArray) => {
  const event = events();
  let resolved = 0;
  promiseArray.forEach((promise) => {
    promise.then((result) => {
      event.emit('Resolved', { result, resolved: ++resolved, IsLastPromise: promiseArray.length === resolved });
    }).catch((e) => { event.emit('Error', e); });
  });
  return {
    on(eventType, callback) {
      event.on(eventType, result => callback(result));
      return this;
    },
    off(eventType) {
      event.off(eventType);
    },
  };
};

/* WIP:: There would be a pbi that updates fetchData common component so that we are able to validate responses */

class CommonFetch {
  static headerConfig() {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Auth.token()}`,
      },
    };
    return config;
  }

  // For comparing using the text property of Option.
  static compareByText(a, b) {
    // Case insensitive compare.
    return a.text.localeCompare(b.text, 'en', { sensitivity: 'base' });
  }

  static get clientShortName() {
    const clientShortName = Auth.clientShortName();
    if (!clientShortName) {
      // Using Zuuse Identity means that users can log in that are not configured correctly, if
      // that happens throw an error so it can be handled further up the stack.
      throw new MissingConfigError(ConfigType.ClientShortName, 'Client short name not configured');
    }
    return clientShortName;
  }

  /*
  * To get options filter based on client short name
  */
  static async getFilters() {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/FiltersOptions`;
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    return response.json() || '';
  }

  static async getDepartmentsList() {
    const accountId = Auth.accountId();
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/departments/${accountId}`;

    const response = await fetchData({ URL: url, eventData: { loading: { minimumDuration: 1000 }, block: false } });
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    const departmentsList = await response.json();
    return departmentsList.filter(departmentText => departmentText).map(departmentText => ({ id: Uuid(), text: departmentText, value: '' }))
      .sort(CommonFetch.compareByText);
  }

  static async getFilterDepartments() {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/filterdepartments`;
    const response = await fetchData({ URL: url, spinnerOptions: { showSpinner: true, centered: true } });

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    const list = await response.json();
    list.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));
    return list;
  }

  static async getRequestorsList() {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/requestors`;
    const response = await fetchData({ URL: url, spinnerOptions: { showSpinner: true, centered: true } });

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    const list = await response.json();
    list.sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }));
    return list;
  }

  static getConversationList(requestIds) {
    const batch = requestIds.map(ids => fetchData({
      URL: `${ENV_ENDPOINTS.conversationEndpoint + Auth.clientShortName()}/conversations/summary?ids=${ids}`,
    }));
    return ResolvePromiseArray(batch);
  }

  /*
  * To get request list based on client shortname, department name and user name
  */
  static async getRequestList() {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/requests`;
    const response = await fetchData({ URL: url, spinnerOptions: { showSpinner: true, centered: true } });

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    return response.json();
  }

  /*
  * To get request list based on client shortname, department name and user name
  */
  static async getThumbnail(pulseRequestId) {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/requests/${pulseRequestId}/thumbnail`;
    const response = await fetchData({ URL: url });

    if (response.ok && response.status === HttpCode.OK) {
      return response.json();
    }
    return null;
  }

  static async getSearchResult(query) {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/requests/search/${query}`;
    const response = await fetchData({ URL: url });

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    return response.json();
  }

  static async getFieldsConfiguration() {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/config/requesttype`;
    const response = await fetchData({ URL: url, eventData: { loading: true, block: true } });

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    return response.json();
  }

  /*
  * To get domain options for dropdown based on client shortname
  */
  static async getDomainOptions() {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/locations/domains`;
    const response = await fetch(url, CommonFetch.headerConfig());

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    return response.json();
  }

  /*
  * To get domain options for dropdown based on client shortname and user
  */
  static async getUserDomainOptions() {
    const accountId = Auth.accountId();
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/domains/${accountId}`;
    const response = await fetchData({ URL: url, eventData: { loading: { minimumDuration: 1000 }, block: false } });

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    const arrayDomains = await response.json();
    return arrayDomains.map(domain => ({ text: domain.description, id: domain.code }))
      .sort(this.compareByText);
  }


  /*
  * To get site options for dropdown based on client shortname and selected domain
  */
  static async getSiteOptions(domain) {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/locations/sites?domain=${encodeURIComponent(domain)}`;
    const response = await fetchData({ URL: url, eventData: { loading: { minimumDuration: 1000 }, block: false } });

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    const arraySites = await response.json();
    return arraySites.filter(siteOption => siteOption.text !== null).sort(this.compareByText);
  }

  /*
  * To get building options for dropdown based on client shortname, selected domain and selected site
  */
  static async getBuildingOptions(domain, site) {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint
      + Auth.clientShortName()}/locations/buildings?domain=${encodeURIComponent(domain)}&site=${encodeURIComponent(site)}`;
    const response = await fetchData({ URL: url, eventData: { loading: { minimumDuration: 1000 }, block: false } });

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    const arrayBuildings = await response.json();
    return arrayBuildings.filter(buildingOption => buildingOption.text !== null).sort(this.compareByText);
  }

  /*
  * To get floor options for dropdown based on client shortname, selected domain, selected site and selected building
  */
  static async getFloorOptions(domain, site, building) {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint
      + Auth.clientShortName()}/locations/floors?`
      + `domain=${encodeURIComponent(domain)}&site=${encodeURIComponent(site)}&building=${encodeURIComponent(building)}`;
    const response = await fetchData({ URL: url, eventData: { loading: { minimumDuration: 1000 }, block: false } });

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    const arrayFloors = await response.json();
    return arrayFloors.filter(floorOption => floorOption.text !== null).sort(this.compareByText);
  }

  /*
  * To get room options for dropdown based on client shortname, selected domain, selected site, selected building and selected floor
  */
  static async getRoomOptions(domain, site, building, floor) {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint
      + Auth.clientShortName()}/locations/rooms?domain=${encodeURIComponent(domain)}&site=${encodeURIComponent(site)}`
      + `&building=${encodeURIComponent(building)}&floor=${encodeURIComponent(floor)}`;
    const response = await fetchData({ URL: url, eventData: { loading: { minimumDuration: 1000 }, block: false } });
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    const arrayRooms = await response.json();
    return arrayRooms.filter(roomOption => roomOption.text !== null).sort(this.compareByText);
  }

  /*
  * To get asset options for dropdown based on client shortname, selected domain, selected site, selected building, selected floor, and selected room
  */
  static async getAssetsOptions(domain, site, building, floor, room) {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint
      + Auth.clientShortName()}/locations/assets?domain=${encodeURIComponent(domain)}&site=${encodeURIComponent(site)}`
      + `&building=${encodeURIComponent(building)}&floor=${encodeURIComponent(floor)}&room=${encodeURIComponent(room)}`;
    const response = await fetchData({ URL: url, eventData: { loading: { minimumDuration: 1000 }, block: false } });
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    const arrayAssets = await response.json();
    return arrayAssets.filter(assetOption => assetOption.text !== null).sort(this.compareByText);
  }

  static async createRequest(request) {
    const response = await fetchData({
      method: 'POST',
      URL: `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/requests/${request.id}`,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
      },
      spinnerOptions: { showSpinner: true, centered: true },
    });
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    // This needs to be fixed when Bug 24458 is completed.
    const responseText = await response.text();
    const reqNumberTemp = await responseText.split('=');
    const reqNumber = reqNumberTemp[1].replace(/"/g, '');
    return reqNumber;
  }

  static async updateRequest(request) {
    const response = await fetchData({
      method: 'PUT',
      URL: `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/requests/${request.id}`,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
      },
      spinnerOptions: { showSpinner: true, centered: true },
    });
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
  }

  static async getRequestDetails(id) {
    const response = await fetchData({ URL: `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/requests/${id}` });
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    return response.json();
  }

  static async getJobTypes() {
    const response = await fetchData({ URL: `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/jobtypes/` });
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    return response.json();
  }

  static async getJobTasks(taskCategories) {
    const url = new URL(`${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/jobtasks`);
    _.forEach(taskCategories, (taskCategory, index) => {
      url.searchParams.append(`taskCategories[${index}]`, taskCategory);
    });

    const response = await fetchData({ URL: url.toString(), spinnerOptions: { showSpinner: true, centered: true } });
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    const jobTasks = await response.json();
    return jobTasks.map(job => ({ text: job.description, id: job.code, value: job.code }))
      .sort(this.compareByText);
  }

  static async getCostCentres() {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/costcenters`;
    const response = await fetchData({ URL: url, eventData: { loading: true, block: false } });
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    const data = await response.json();
    return data.map(cost => ({ text: cost.description, id: cost.code }))
      .sort(this.compareByText);
  }

  static async getExpenses(description) {
    const url = new URL(`${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/expenses`);
    url.search = new URLSearchParams({ description });
    const response = await fetchData({ URL: url, eventData: { loading: true, block: false } });
    if (!response.ok) {
      const message = `${response.status}: ${response.statusText}`;
      if (response.status === HttpCode.NotFound) {
        const error = new NotFoundError(message);
        throw error;
      }
      throw new Error(message);
    }
    const data = await response.json();
    return data;
  }

  /** Same as getExpense but will not error if the response status is NotFound */
  static async getExpensesOrDefault(description) {
    try {
      const result = await CommonFetch.getExpenses(description);
      return result;
    } catch (error) {
      if (error instanceof NotFoundError) {
        return null;
      }
      throw error;
    }
  }

  static async searchExpenses(terms) {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint + Auth.clientShortName()}/expenses/search/${encodeURIComponent(terms)}`;
    const response = await fetchData({ URL: url, spinnerOptions: { showSpinner: true, centered: true } });

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    const expenses = await response.json();
    return expenses.map(expense => ({ text: expense.description, id: expense.code }))
      .sort(this.compareByText);
  }

  /** Get users details */
  static async getUserDetails() {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint + CommonFetch.clientShortName}/userdetails`;
    const response = await fetchData({ URL: url, eventData: { loading: true, block: false } });

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    return response.json();
  }

  /** Get users defaults */
  static async getUserDefaults() {
    const url = `${ENV_ENDPOINTS.pulseRequestsEndpoint + CommonFetch.clientShortName}/userdefaults`;
    const response = await fetchData({ URL: url, eventData: { loading: true, block: false } });

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    return response.json();
  }
}

export default CommonFetch;
