import React from 'react';
import PropTypes from 'prop-types';

import NavigationButton from './navigationButton';

const WaitingButton = props => (
  props.waiting ? (
    <div style={{ right: '25px', position: 'relative' }}>
      <div className="wrapper">
        <div className="cssload-loader" />
      </div>
    </div>
  ) : (
    <NavigationButton
      destination={props.destination}
      icon={props.icon}
      label={props.label}
      iconOnLeft={props.iconOnLeft}
      visible={props.visible}
      enabled={props.enabled}
      onClick={props.onClick}
    />
  )
);

WaitingButton.propTypes = {
  waiting: PropTypes.bool,
  destination: PropTypes.string,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconOnLeft: PropTypes.bool,
  visible: PropTypes.bool,
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
};

WaitingButton.defaultProps = {
  destination: null,
  waiting: false,
  icon: '',
  iconOnLeft: true,
  enabled: true,
  visible: true,
  onClick: null,
};

export default WaitingButton;
