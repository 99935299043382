
// A set of constant values for roles names. This way if they every change we only have one place to manage, and will also reduce typos.
const Roles = Object.freeze({
  ZuuseAdministrator: 'ZuuseAdministrator',
  User: 'User',
  Administrator: 'Administrator',
  AssetAdministrator: 'AssetAdministrator',
  AssetWriter: 'AssetWriter',
  FinanceAdministrator: 'FinanceAdministrator',
  FinanceEditor: 'FinanceEditor',
  FinanceReader: 'FinanceReader',
  TemplateWriter: 'TemplateWriter',
  TemplateReader: 'TemplateReader',
  ConditionReader: 'ConditionReader',
  SurveyReader: 'SurveyReader',
  ConditionWriter: 'ConditionWriter',
  SurveyWriter: 'SurveyWriter',
  LifecycleManager: 'LifecycleManager',
  LifecycleSkyline: 'LifecycleSkyline',
  LifecyclePortfolio: 'LifecyclePortfolio',
  LifecycleEqualiser: 'LifecycleEqualiser',
  ScoutWebUser: 'ScoutWebUser',
  ScoutWebManager: 'ScoutWebManager',
  ScoutWebFormAdministrator: 'ScoutWebFormAdministrator',
  ScoutWebAdministrator: 'ScoutWebAdministrator',
  Agent: 'Agent',
});

export default Roles;
