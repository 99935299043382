import React from 'react';
import PropTypes from 'prop-types';
import FilterList from './filterList';
import FilterDropdownTitle from './filterDropdownTitle';
import FilterListItem from './filterListItem';

const FilterDropDown = props => (
  <div>
    <div className="clickable">
      <FilterDropdownTitle
        onClick={() => props.toggleFilterList(props.type)}
        icon={props.type}
        color={props.color}
        value={props.selectedValue}
      />
    </div>
    <FilterList
      wrapperStyle={props.filterListWrapperStyle}
      wrapperClassName={props.filterListWrapperClassName}
      listStyle={props.filterListStyle}
      show={props.showOpt}
    >
      {
          props.options.map(option => (
            // Use the option value rather then the option key, as if an older version (v.60 and older) of the bridge api is running
            // the requestors might not have an Id.
            <li key={option.value}>
              <button className="optionListBtn" type="button" onClick={() => props.onSelect(option)}>
                {option.value}
              </button>
            </li>
          ))
        }
    </FilterList>
  </div>
);


FilterDropDown.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  showOpt: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  toggleFilterList: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.instanceOf(FilterListItem)),
  filterListWrapperStyle: PropTypes.shape({}),
  filterListWrapperClassName: PropTypes.string,
  filterListStyle: PropTypes.shape({}),
  color: PropTypes.string,
  onSelect: PropTypes.func,
};

FilterDropDown.defaultProps = {
  filterListWrapperStyle: {},
  filterListWrapperClassName: '',
  filterListStyle: {},
  color: 'white',
  options: [],
  onSelect: () => {},
};

export default FilterDropDown;
