import React from 'react';
import PropTypes from 'prop-types';

import Field from '../../../../../modules/ScoutModels/models/field';
import EmptyField from '../../../../../modules/ScoutModels/models/emptyField';
import FormLabel from './formLabel';
import Autocomplete from '../../../components/autocomplete';
import AutocompleteTypeAhead from '../../../components/autocompleteTypeAhead';
import TooltipIcon from '../../../components/tooltipIcon';
import TooltipText from '../../../components/tooltipText';
import HiddenText from '../../../components/hiddenText';

export const WizardFieldType = Object.freeze({
  Input: 'Input',
  Autocomplete: 'Autocomplete',
  AutocompleteTypeAhead: 'AutocompleteTypeAhead',
});

const inputWidth = '360px';

const inputStyle = {
  width: '100%',
};

const inputFieldDivStyle = {
  width: inputWidth,
  position: 'relative',
  display: 'inline-block',
};

const inputDivStyle = {
  display: 'flex',
  width: '100%',
};

const inputAndErrorStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

const labelStyle = { width: '160px' };

function getErrorMessage(field, value) {
  if (field.required && !value) {
    return `${field.captionOrDefault} is required.`;
  }

  if (field.patternValidationFailedMessage) {
    return field.patternValidationFailedMessage;
  }

  return `Invalid ${field.captionOrDefault}`;
}

const WizardField = (props) => {
  const show = props.show && props.field && props.field.shown;
  if (!show) {
    return null;
  }

  let input;

  if (props.wizardFieldType === WizardFieldType.Input) {
    input = (
      <div style={inputFieldDivStyle}>
        <input
          onChange={event => props.valueChanged(event.target.value)}
          type="text"
          id={`${props.type}Input`}
          name={props.type}
          value={props.parentVal.text}
          pattern={props.field.pattern}
          required={props.field.required}
          className={props.className}
          maxLength={props.maxLength}
          style={inputStyle}
          disabled={props.isDisabled}
        />
      </div>
    );
  } else if (props.wizardFieldType === WizardFieldType.Autocomplete) {
    input = (
      <Autocomplete
        width={inputWidth}
        optionsData={props.optionsData}
        parentVal={props.parentVal}
        errorMessage={null}
        selectedValue={props.valueChanged}
        type={props.type}
        event={props.event}
        divShown={props.divShown}
        showOpt={props.showOpt}
        config={props.field}
        isDisabled={!props.optionsData || props.isDisabled}
        isValid={props.isValid}
      />
    );
  } else if (props.wizardFieldType === WizardFieldType.AutocompleteTypeAhead) {
    input = (
      <AutocompleteTypeAhead
        width={inputWidth}
        fetchOptions={props.fetchOptions}
        parentVal={props.parentVal}
        errorMessage={null}
        selectedValue={props.valueChanged}
        type={props.type}
        event={props.event}
        divShown={props.divShown}
        config={props.field}
        isDisabled={props.isDisabled}
        isValid={props.isValid}
      />
    );
  }

  return (
    <div>
      <div className="wizard-field-input">
        <FormLabel
          style={labelStyle}
          caption={props.field.captionOrDefault}
          required={props.field.required}
          htmlFor={props.type}
        />
        <div style={inputAndErrorStyle}>
          <div style={inputDivStyle}>
            {input}
            <TooltipIcon
              tooltip={props.field.tooltip}
              id={props.field.id}
              onTooltipClick={props.onTooltipClick}
            />
          </div>
          <HiddenText text={getErrorMessage(props.field, props.parentVal.text)} show={!props.isValid} className="error field-error" />
        </div>
      </div>
      <TooltipText
        show={props.showTooltip}
        id={props.field.id}
        tooltip={props.field.tooltip}
        onTooltipClick={props.onTooltipClick}
      />
    </div>
  );
};

WizardField.propTypes = {
  show: PropTypes.bool,
  wizardFieldType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  field: PropTypes.oneOfType([PropTypes.instanceOf(Field), PropTypes.instanceOf(EmptyField)]).isRequired,
  optionsData: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({ id: PropTypes.string, text: PropTypes.string }),
    PropTypes.shape({ id: PropTypes.string, text: PropTypes.string, value: PropTypes.string }),
  ])),
  fetchOptions: PropTypes.func,
  parentVal: PropTypes.shape({ text: PropTypes.string }).isRequired,
  valueChanged: PropTypes.func.isRequired,
  event: PropTypes.shape({}),
  divShown: PropTypes.func,
  showOpt: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  showTooltip: PropTypes.bool.isRequired,
  onTooltipClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  maxLength: PropTypes.number,
};

WizardField.defaultProps = {
  optionsData: null,
  fetchOptions: null,
  event: null,
  divShown: null,
  show: true,
  isDisabled: false,
  className: '',
  maxLength: 524288,
};

export default WizardField;
