import React from 'react';
import PropTypes from 'prop-types';

// Currently doing lookup against Font-Awesome font, however this could change in the future.
// Explicitly limit this to the number of icons that are required in the system.
const iconCodes = Object.freeze({
  // Buttons
  Back: '\uf359', // arrow-alt-circle-left
  Next: '\uf35a', // arrow-alt-circle-right
  Add: '\uf055', // plus-circle
  Open: '\uf518', // book-open
  Sort: '\uf15d', // sort-alpha-down
  List: '\uf03a', // list
  Down: '\uf078', // chevron-down
  Up: '\uf077', // chevron-up
  Edit: '\uf044', // edit
  // Statuses
  Waiting: '\uf252', // hourglass-half
  Outstanding: '\uf328', // clipboard
  InProgress: '\uf46d', // clipboard-list
  Complete: '\uf46c', // clipboard-check
  Rejected: '\uf071', // exclamation-triangle
  // Filters:
  Status: '\uf111', // circle
  Department: '\uf1ad', // building
  Requestor: '\uf007', // user
  Search: '\uf002', // search
  // Headers
  Location: '\uf3c5', // map-marker-alt
  Conversation: '\uf27a', // comment-alt
  Date: '\uf073', // calendar-alt
  Document: '\uf15c', // file-alt
  Calendar: '\uf073', // calendar-alt
  // Wizard
  Pencil: '\uf303', // pencil-alt
  // details
  SubmitConversation: '\uf075', // comment
  Problem: '\uf0b1', // briefcase
  // Attachments
  Upload: '\uf093', // upload
  Trash: '\uf1f8', // Trash
  // Login
  ArrowCircleRight: '\uf0a9', // fa-arrow-circle-right
  // Errors/Warnings
  Error: '\uf06a',
});

export const icons = Object.keys(iconCodes).reduce((iconKeys, key) => ({ ...iconKeys, [key]: key }), {});

// Represents a sprite or icon that can be used as decoration in the page.
// This abstraction allows the implementation to vary between a font, a set of images, embedded SVG's or a spritesheet
// without the remainder of the app having to change.
const Sprite = (props) => {
  const style = {
    ...{
      color: props.color,
      fontSize: props.fontSize,
    },
    ...props.style,
  };
  const code = iconCodes[props.icon];
  return (
    <div className="sprite" style={style}>
      {code}
    </div>
  );
};

Sprite.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
  fontSize: PropTypes.string,
  style: PropTypes.shape({}),
};

Sprite.defaultProps = {
  color: null,
  fontSize: '14px',
  style: {},
};

export default Sprite;
