

const PageVisibility = (function PageVisibilityWrapper() {
  function PageVisibilityConstructor() {
    this.onchange = this.onchange.bind(this);
    let hidden = 'hidden';

    // Standards:
    // eslint-disable-next-line no-cond-assign
    if (hidden in document) {
      document.addEventListener('visibilitychange', this.onchange);
      // eslint-disable-next-line no-cond-assign
    } else if ((hidden = 'mozHidden') in document) {
      document.addEventListener('mozvisibilitychange', this.onchange);
      // eslint-disable-next-line no-cond-assign
    } else if ((hidden = 'webkitHidden') in document) {
      document.addEventListener('webkitvisibilitychange', this.onchange);
      // eslint-disable-next-line no-cond-assign
    } else if ((hidden = 'msHidden') in document) {
      document.addEventListener('msvisibilitychange', this.onchange);
      // eslint-disable-next-line no-cond-assign
    } else if ('onfocusin' in document) {
      document.onfocusin = this.onchange;
      document.onfocusout = this.onchange;
    }
    // All others:
    window.onpageshow = this.onchange;
    window.onpagehide = this.onchange;
    window.onfocus = this.onchange;
    window.onblur = this.onchange;
    // set the initial state (but only if browser supports the Page Visibility API)
    if (document[hidden] !== undefined) { this.onchange({ type: document[hidden] ? 'blur' : 'focus' }); }
  }
  PageVisibilityConstructor.prototype.onchange = function onchange(evt) {
    if (!this.pageVisibilityChangedEvent) return;
    const v = true;
    const h = false;
    const evtMap = {
      focus: v, focusin: v, pageshow: v, blur: h, focusout: h, pagehide: h,
    };
    let result;
    const newEvt = evt || window.event;
    if (newEvt.type in evtMap) { result = evtMap[newEvt.type]; } else { result = document.hidden ? h : v; }
    if (this.isVisible === result) {
      return; // the state did not change, early out.
    }
    this.isVisible = result;

    if (this.pageVisibilityChangedEvent) {
      this.pageVisibilityChangedEvent(result);
    }
  };
  PageVisibilityConstructor.prototype.removePageVisibilityEvent = function removePageVisibilityEvent() {
    this.pageVisibilityChangedEvent = undefined;
  };
  PageVisibilityConstructor.prototype.isCurrentlyVisible = function isCurrentlyVisible() {
    return this.isVisible;
  };
  PageVisibilityConstructor.prototype.subscribeIsVisibleEvent = function subscribeIsVisibleEvent(callback) {
    this.pageVisibilityChangedEvent = callback;
    this.pageVisibilityChangedEvent(this.isVisible);
  };
  return PageVisibilityConstructor;
}());
exports.PageVisibility = PageVisibility;
