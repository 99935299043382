import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormLabel from './formLabel';
import Field from '../../../../../modules/ScoutModels/models/field';

import TooltipIcon from '../../../components/tooltipIcon';
import TooltipText from '../../../components/tooltipText';

const showLineBtn = { cursor: 'pointer', fontSize: '12px' };
const divDescriptionHide = {
  maxHeight: '0px',
  overflow: 'hidden',
  transition: 'all .5s ease',
};
const divDescriptionShow = {
  maxHeight: '1000px',
  overflow: 'hidden',
  transition: 'all 1.5s ease',
  animation: 'opac 1s',
  whiteSpace: 'pre-wrap',
};

class DescriptionField extends Component {
  constructor(props) {
    super(props);

    this.state = { showTooltip: false };

    this.onTooltipClick = this.onTooltipClick.bind(this);
  }

  onTooltipClick(event) {
    event.stopPropagation();
    this.setState(prevState => ({ showTooltip: !prevState.showTooltip }));
  }

  render() {
    if (this.props.descriptionField.shown) {
      const showDescription = DescriptionField.canShowDescription(
        this.props.descriptionField.required, this.props.showDescriptionClicked, this.props.showDescription, this.props.description,
      );
      return (
        <div>
          { this.props.descriptionField.required
            && <FormLabel caption={this.props.descriptionField.captionOrDefault} required={this.props.descriptionField.required} />
          }
          { !this.props.descriptionField.required && (
            <div style={showLineBtn} name="ShowExtraLine">
              <i onClick={this.props.onShowLineClick}>
                <u>{showDescription ? 'Hide Description' : 'Show Description'}</u>
              </i>
              {showDescription && (<TooltipIcon tooltip={this.props.descriptionField.tooltip} onTooltipClick={this.onTooltipClick} />)}
            </div>
          )}
          <div style={showDescription ? divDescriptionShow : divDescriptionHide}>
            <TooltipText show={this.state.showTooltip} tooltip={this.props.descriptionField.tooltip} onTooltipClick={this.onTooltipClick} />
            <TooltipText
              show={!this.props.descriptionValid && this.props.descriptionTouched}
              tooltip={this.props.descriptionField.patternValidationFailedMessage
                  || (this.props.descriptionField.required ? `*${this.props.descriptionField.captionOrDefault} is required.` : undefined)}
              customClass="tooltipWarning"
            />
            <textarea
              id="txtDescription"
              style={this.props.textAreaStyle}
              placeholder={`Enter ${this.props.descriptionField.captionOrDefault}...`}
              value={this.props.description}
              onChange={event => this.props.handleChange(event.target.value)}
              name="Description"
              required={this.props.descriptionField.required}
              title={this.props.descriptionField.required ? 'Please fill out this field.' : ''}
              className={this.props.descriptionValid || !this.props.descriptionTouched ? 'validInput' : 'invalidInput'}
            />
          </div>
        </div>
      );
    }
    return null;
  }
}

DescriptionField.canShowDescription = (required, showDescriptionClicked, showDescription, description) => {
  if (required) { return true; } // if it's required we're showing it.
  if (showDescriptionClicked) {
    return showDescription; // if the show description has been clicked then it take priority.
  }
  return !!description; // if we get this far we'll show it if there  is something to show.
};

DescriptionField.propTypes = {
  descriptionField: PropTypes.instanceOf(Field).isRequired,
  showDescriptionClicked: PropTypes.bool.isRequired,
  showDescription: PropTypes.bool.isRequired,
  onShowLineClick: PropTypes.func.isRequired,
  textAreaStyle: PropTypes.shape({}).isRequired,
  description: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  descriptionValid: PropTypes.bool.isRequired,
  descriptionTouched: PropTypes.bool.isRequired,
};

DescriptionField.defaultProps = {
  description: '',
};

export default DescriptionField;
