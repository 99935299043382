export const FileType = Object.freeze({
  Unknown: 'Unknown',
  All: 'All',
  Image: 'Image',
  Preview: 'Preview',
  Thumbnail: 'Thumbnail',
  Document: 'Document',
});

export const ValidImageTypes = ['image/png', 'image/gif', 'image/jpeg', 'image/bmp', 'image/webp'];

export const GetFileType = fileType => (FileType[fileType] ? FileType[fileType] : FileType.Unknown);

export default FileType;
