import React from 'react';
import PropTypes from 'prop-types';
import './contextMenu.scss';

const ContextMenu = props => (
  <div className="contextMenu" style={{ textAlign: 'left' }}>
    <div className="contextMenuIcon">
      {props.icon}
    </div>
    <div className="contextMenuContent">
      {props.children}
    </div>
  </div>
);

ContextMenu.propTypes = {
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default ContextMenu;
