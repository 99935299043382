import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RequestType from '../../../modules/ScoutModels/models/requestType';
import TooltipIcon from './tooltipIcon';
import TooltipText from './tooltipText';

const titleStyle = {
  cursor: 'pointer',
};

class RequestTypeTitle extends Component {
  constructor(props) {
    super(props);

    this.state = { showTooltip: false };

    this.onTooltipClick = this.onTooltipClick.bind(this);
  }

  onTooltipClick(event) {
    event.stopPropagation();
    this.setState(prevState => ({ showTooltip: !prevState.showTooltip }));
  }

  render() {
    const requestType = this.props.requestType;
    return (
      <div>
        <div>
          <span onClick={this.props.onTitleClick} style={titleStyle}>
            {requestType.title}
            {requestType.useRolesInTitle
              && <span>{requestType.DisplayRoles}</span>
            }
          </span>
          <TooltipIcon tooltip={requestType.tooltip} onTooltipClick={this.onTooltipClick} />
        </div>
        <TooltipText show={this.state.showTooltip} tooltip={requestType.tooltip} onTooltipClick={this.onTooltipClick} />
      </div>
    );
  }
}

RequestTypeTitle.propTypes = {
  onTitleClick: PropTypes.func,
  requestType: PropTypes.instanceOf(RequestType).isRequired,
};

RequestTypeTitle.defaultProps = {
  onTitleClick: null,
};

export default RequestTypeTitle;
