import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TooltipText extends Component {
  constructor(props) {
    super(props);

    this.getMainDivClass = this.getMainDivClass.bind(this);
  }

  getMainDivClass() {
    let mainDivClass = 'tooltipMinimize';
    if (this.props.show) {
      mainDivClass = 'tooltipMaximize';
      if (this.props.customClass) {
        mainDivClass = `${this.props.customClass} ${mainDivClass}`;
      }
    }
    return mainDivClass;
  }

  render() {
    const mainDivClass = this.getMainDivClass();
    return (
      this.props.tooltip && (
        <div
          data-value={this.props.id}
          onClick={this.props.onTooltipClick}
          className={mainDivClass}
        >
          <div className="padRight-6 tooltipText">
            {this.props.tooltip}
          </div>
          {
            this.props.onTooltipClick && (
            <div className="sprite tooltipChevron">
              {'\uf106'}
            </div>
            )}
        </div>
      )
    );
  }
}

TooltipText.propTypes = {
  id: PropTypes.string,
  show: PropTypes.bool.isRequired,
  tooltip: PropTypes.string,
  onTooltipClick: PropTypes.func,
  customClass: PropTypes.string,
};

TooltipText.defaultProps = {
  id: null,
  tooltip: null,
  onTooltipClick: null,
  customClass: null,
};

export default TooltipText;
