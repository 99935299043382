class Conversation {
  constructor(Id, VersionDate, IsLoading = true, HasNewContent = false) {
    this.Id = Id || null;
    this.VersionDate = VersionDate || null;
    this.IsLoading = IsLoading;
    this.HasNewContent = HasNewContent;
  }

  SetLastConversationTime(conversation = {}) {
    this.VersionDate = conversation ? conversation.VersionDate : null;
    this.IsLoading = false;
  }

  SetConversationLoadingStatus(value) {
    this.IsLoading = value;
  }

  Clone() {
    const clone = new Conversation();
    clone.Id = this.Id;
    clone.VersionDate = this.VersionDate;
    clone.IsLoading = this.IsLoading;
    return clone;
  }

  IsEqual(conversation) {
    return this.Id === conversation.Id
      && this.VersionDate === conversation.VersionDate
      && this.IsLoading === conversation.IsLoading;
  }

  toJSON() {
    return {
      conversationId: this.Id || undefined,
      lastUpdated: this.VersionDate || undefined,
      hasNewContent: this.HasNewContent,
    };
  }
}

export default Conversation;
