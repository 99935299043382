import React from 'react';
import PropTypes from 'prop-types';
import { PreviewAttachment as Attachment } from '../../../../modules/attachment/Attachment';
import Sprite from '../../../components/sprite';
import Request from '../../../../modules/ScoutModels/models/request';
import Field from '../../../../modules/ScoutModels/models/field';
import { SyncMode } from '../../../../modules/attachment/model/attachment/SyncMode';

const attachmentIconSize = '14px';
const attachmentSlideIconSize = '20px';
const allowedAttachments = 'image/png,image/gif,image/jpeg,image/bmp,image/webp,text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

const wrapperStyle = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
};

const descriptionStyle = {
  width: '50%',
  whiteSpace: 'pre-wrap',
  padding: '10px',
  marginRight: '10px',
};

const attachment = {
  width: '50%',
};

const RequestSummary = props => (
  <div className="panel-content" style={wrapperStyle}>
    <div style={descriptionStyle}>
      {props.requestData.title}
      <p>
        {props.requestData.description}
      </p>
    </div>
    {
      props.attachmentField
      && props.attachmentField.shown
      && (
        <div className="attachment-wrapper" style={attachment}>
          <Attachment
            minLength={props.attachmentField && props.attachmentField.required ? 1 : -1}
            tooltipOnReadOnlyTrashCan="At least one attachment is required."
            readOnly={!props.requestData.serviceType || props.requestData.ShouldAllowEditRequest}
            attachmentWrapperClass="editAttachmentDiv"
            accept={allowedAttachments}
            attachedItemReference={props.requestID}
            attachedItemType="ServiceRequest"
            trashIcon={<Sprite icon="Trash" fontSize={attachmentIconSize} />}
            nextIcon={<Sprite icon="Next" fontSize={attachmentSlideIconSize} />}
            previousIcon={<Sprite icon="Back" fontSize={attachmentSlideIconSize} />}
            uploadIcon={<Sprite icon="Upload" fontSize={attachmentIconSize} />}
            showFileName
            syncMode={SyncMode.AvailableOffline}
          />
        </div>
      )
    }

  </div>
);

RequestSummary.propTypes = {
  requestData: PropTypes.instanceOf(Request).isRequired,
  requestID: PropTypes.string.isRequired,
  attachmentField: PropTypes.instanceOf(Field),
};

RequestSummary.defaultProps = {
  attachmentField: null,
};

export default RequestSummary;
